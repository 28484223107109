import React from 'react';

const Projects: React.FC = () => {
  const projects = [
    { name: 'Retro Chat', description: 'A chat application with 90s aesthetics', year: '2024' },
    { name: 'Pixel Art Creator', description: 'Create pixel art like it\'s 1998', year: '2024' },
    { name: 'Virtual Pet', description: 'Take care of a digital pet', year: '2025' },
    { name: 'Text Adventure', description: 'A classic text-based adventure game', year: '2025' },
  ];

  return (
    <div>
      <div className="kos-fieldset">
        <div className="kos-fieldset-legend">Our Projects</div>
        <div style={{ padding: '0.5rem' }}>
          <h2 style={{ margin: '0 0 1rem' }}>Projects</h2>
          <p>
            Here are some of the projects we've been working on at KoolSkull:
          </p>
          
          <table className="kos-table">
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Description</th>
                <th>Year</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project, index) => (
                <tr key={index}>
                  <td>{project.name}</td>
                  <td>{project.description}</td>
                  <td>{project.year}</td>
                </tr>
              ))}
            </tbody>
          </table>
          
          <p style={{ marginTop: '1rem' }}>
            Click on any project to learn more about it.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Projects; 