// Auto-generated playlist data
export const discography = [
  {
    "id": "audiotrash-korgpainter",
    "title": "AUDIOTRASH KORGPAINTER",
    "artist": "KOOL SKULL",
    "year": "2021",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - AUDIOTRASH KORGPAINTER/KOOL SKULL - AUDIOTRASH KORGPAINTER - 01 BOOTYSWEAT PUKE KINK.mp3",
        "duration": 397.9515416666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "BOOTYSWEAT PUKE KINK",
          "album": "AUDIOTRASH KORGPAINTER"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - AUDIOTRASH KORGPAINTER/KOOL SKULL - AUDIOTRASH KORGPAINTER - 02 LUXURY VOMIT CHAMPAGNE.mp3",
        "duration": 444.5858333333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "LUXURY VOMIT CHAMPAGNE",
          "album": "AUDIOTRASH KORGPAINTER"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - AUDIOTRASH KORGPAINTER/KOOL SKULL - AUDIOTRASH KORGPAINTER - 03 EYECECREAM (feat. Shags).mp3",
        "duration": 265.9979583333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "EYECECREAM (feat. Shags)",
          "album": "AUDIOTRASH KORGPAINTER"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/audiotrash-korgpainter.jpg",
    "largeUrl": "/album-large/audiotrash-korgpainter.jpg",
    "fullscreenUrl": "/album-fullscreen/audiotrash-korgpainter.jpg",
    "coverUrl": "/mp3/KOOL SKULL - AUDIOTRASH KORGPAINTER/cover.jpg"
  },
  {
    "id": "b8kdbrkz",
    "title": "B8KDBRKZ",
    "artist": "KOOL SKULL",
    "year": "2023",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 01 Z.mp3",
        "duration": 80.41829166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Z",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 02 Y.mp3",
        "duration": 116.541125,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Y",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 03 X.mp3",
        "duration": 102.76275,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "X",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 04 W.mp3",
        "duration": 214.181375,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "W",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 05 V.mp3",
        "duration": 234.17620833333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "V",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 06 U.mp3",
        "duration": 134.7455,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "U",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 07 T.mp3",
        "duration": 192.498,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "T",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 08 S.mp3",
        "duration": 321.2105833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "S",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 09 R.mp3",
        "duration": 412.11079166666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "R",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 10 Q.mp3",
        "duration": 155.68620833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Q",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 11 P.mp3",
        "duration": 282.23608333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "P",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 12 O.mp3",
        "duration": 230.452875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "O",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 13 N.mp3",
        "duration": 85.80529166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "N",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 14 M.mp3",
        "duration": 291.55566666666664,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "M",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 15 L.mp3",
        "duration": 44.32908333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "L",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 16 K.mp3",
        "duration": 393.01841666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "K",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 17 J.mp3",
        "duration": 83.38616666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "J",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 18 I.mp3",
        "duration": 136.70891666666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "I",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 19 H.mp3",
        "duration": 322.260375,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "H",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 20 G.mp3",
        "duration": 184.65708333333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "G",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 21 F.mp3",
        "duration": 113.23704166666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "F",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 22 E.mp3",
        "duration": 225.22479166666668,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "E",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 23 D.mp3",
        "duration": 367.619,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "D",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 24 C.mp3",
        "duration": 154.91616666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "C",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 25 B.mp3",
        "duration": 318.91679166666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "B",
          "album": "B8KDBRKZ"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - B8KDBRKZ/KOOL SKULL - B8KDBRKZ - 26 A.mp3",
        "duration": 281.35766666666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "A",
          "album": "B8KDBRKZ"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/b8kdbrkz.jpg",
    "largeUrl": "/album-large/b8kdbrkz.jpg",
    "fullscreenUrl": "/album-fullscreen/b8kdbrkz.jpg",
    "coverUrl": "/mp3/KOOL SKULL - B8KDBRKZ/cover.jpg"
  },
  {
    "id": "bestowing-grace-through-creation",
    "title": "Bestowing Grace Through Creation",
    "artist": "KOOL SKULL",
    "year": "2021",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/KOOL SKULL - Bestowing Grace Through Creation - 01 Bestow Grace Through Creation.mp3",
        "duration": 461.22558333333336,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Bestow Grace Through Creation",
          "album": "Bestowing Grace Through Creation"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/KOOL SKULL - Bestowing Grace Through Creation - 02 Free 6ix9ine (feat. Kroyclub).mp3",
        "duration": 359.14675,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Free 6ix9ine (feat. Kroyclub)",
          "album": "Bestowing Grace Through Creation"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/KOOL SKULL - Bestowing Grace Through Creation - 03 Nevr Hesit8; Nevr Hesit8 2 Hesit8.mp3",
        "duration": 321.98475,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Nevr Hesit8; Nevr Hesit8 2 Hesit8",
          "album": "Bestowing Grace Through Creation"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/KOOL SKULL - Bestowing Grace Through Creation - 04 They From The Town (feat. terror!, 2pg, & EBT).mp3",
        "duration": 442.40004166666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "They From The Town (feat. terror!, 2pg, & EBT)",
          "album": "Bestowing Grace Through Creation"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/KOOL SKULL - Bestowing Grace Through Creation - 05 Nano2thrash.mp3",
        "duration": 315.96875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Nano2thrash",
          "album": "Bestowing Grace Through Creation"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/KOOL SKULL - Bestowing Grace Through Creation - 06 Stuck in the Game (feat. Spacey K, & Theo Rollin').mp3",
        "duration": 270.12483333333336,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Stuck in the Game (feat. Spacey K, & Theo Rollin')",
          "album": "Bestowing Grace Through Creation"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/KOOL SKULL - Bestowing Grace Through Creation - 07 Nano2residuepukevomit.mp3",
        "duration": 370.44575,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Nano2residuepukevomit",
          "album": "Bestowing Grace Through Creation"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/KOOL SKULL - Bestowing Grace Through Creation - 08 Smoov.mp3",
        "duration": 205.01116666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Smoov",
          "album": "Bestowing Grace Through Creation"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/KOOL SKULL - Bestowing Grace Through Creation - 09 Go Home, Say Sorry, Rebuild the Habitat and Mend Mental Dimensions.mp3",
        "duration": 339.37629166666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Go Home, Say Sorry, Rebuild the Habitat and Mend Mental Dimensions",
          "album": "Bestowing Grace Through Creation"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/KOOL SKULL - Bestowing Grace Through Creation - 10 Swimming in the Slime.mp3",
        "duration": 333.9250416666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Swimming in the Slime",
          "album": "Bestowing Grace Through Creation"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/KOOL SKULL - Bestowing Grace Through Creation - 11 Danger Comes (feat. Madison Sinclair, Roman Circus, & Jean Mors).mp3",
        "duration": 316.714125,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Danger Comes (feat. Madison Sinclair, Roman Circus, & Jean Mors)",
          "album": "Bestowing Grace Through Creation"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/KOOL SKULL - Bestowing Grace Through Creation - 12 Discernin Duh Waveforms (GZUZLUVZU).mp3",
        "duration": 321.985,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Discernin Duh Waveforms (GZUZLUVZU)",
          "album": "Bestowing Grace Through Creation"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/bestowing-grace-through-creation.jpg",
    "largeUrl": "/album-large/bestowing-grace-through-creation.jpg",
    "fullscreenUrl": "/album-fullscreen/bestowing-grace-through-creation.jpg",
    "coverUrl": "/mp3/KOOL SKULL - Bestowing Grace Through Creation/cover.jpg"
  },
  {
    "id": "big-booty-thrash",
    "title": "Big Booty Thrash",
    "artist": "KOOL SKULL",
    "year": "2019",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - Big Booty Thrash/KOOL SKULL - Big Booty Thrash - 01 Dreamlurker.mp3",
        "duration": 278.503375,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Dreamlurker",
          "album": "Big Booty Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Big Booty Thrash/KOOL SKULL - Big Booty Thrash - 02 Feels Like It.mp3",
        "duration": 367.45579166666664,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Feels Like It",
          "album": "Big Booty Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Big Booty Thrash/KOOL SKULL - Big Booty Thrash - 03 Icebreakdancing.mp3",
        "duration": 431.7071666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Icebreakdancing",
          "album": "Big Booty Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Big Booty Thrash/KOOL SKULL - Big Booty Thrash - 04 Needing To Love You.mp3",
        "duration": 260.48225,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Needing To Love You",
          "album": "Big Booty Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Big Booty Thrash/KOOL SKULL - Big Booty Thrash - 05 Masturb8r.mp3",
        "duration": 347.773,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Masturb8r",
          "album": "Big Booty Thrash"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/big-booty-thrash.jpg",
    "largeUrl": "/album-large/big-booty-thrash.jpg",
    "fullscreenUrl": "/album-fullscreen/big-booty-thrash.jpg",
    "coverUrl": "/mp3/KOOL SKULL - Big Booty Thrash/cover.jpg"
  },
  {
    "id": "big-daddy-kool-skull",
    "title": "BIG DADDY KOOL SKULL",
    "artist": "Kool Skull",
    "year": "2022",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 01 BIG DADDY KOOL SKULL.mp3",
        "duration": 377.41254166666664,
        "metaData": {
          "artist": "Kool Skull",
          "title": "BIG DADDY KOOL SKULL",
          "album": "BIG DADDY KOOL SKULL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 02 LIKE 2 GET 2 KNO U.mp3",
        "duration": 373.5613333333333,
        "metaData": {
          "artist": "Kool Skull",
          "title": "LIKE 2 GET 2 KNO U",
          "album": "BIG DADDY KOOL SKULL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 03 000666999 (feat. Kroyclub).mp3",
        "duration": 302.74741666666665,
        "metaData": {
          "artist": "Kool Skull",
          "title": "000666999 (feat. Kroyclub)",
          "album": "BIG DADDY KOOL SKULL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 04 300555.mp3",
        "duration": 389.611,
        "metaData": {
          "artist": "Kool Skull",
          "title": "300555",
          "album": "BIG DADDY KOOL SKULL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 05 TECHNOPUKE.mp3",
        "duration": 361.22229166666665,
        "metaData": {
          "artist": "Kool Skull",
          "title": "TECHNOPUKE",
          "album": "BIG DADDY KOOL SKULL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 06 ALL I WANT IS THRASH.mp3",
        "duration": 327.8317916666667,
        "metaData": {
          "artist": "Kool Skull",
          "title": "ALL I WANT IS THRASH",
          "album": "BIG DADDY KOOL SKULL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 07 BREAKFAST CAN W8.mp3",
        "duration": 326.6623333333333,
        "metaData": {
          "artist": "Kool Skull",
          "title": "BREAKFAST CAN W8",
          "album": "BIG DADDY KOOL SKULL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 08 RITMO DE LA BASURA (feat. Kintrala).mp3",
        "duration": 344.52975,
        "metaData": {
          "artist": "Kool Skull",
          "title": "RITMO DE LA BASURA (feat. Kintrala)",
          "album": "BIG DADDY KOOL SKULL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 09 DIRTY TEXTS (BOOTY ON MY MIND).mp3",
        "duration": 326.72220833333336,
        "metaData": {
          "artist": "Kool Skull",
          "title": "DIRTY TEXTS (BOOTY ON MY MIND)",
          "album": "BIG DADDY KOOL SKULL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 10 DIRTY BASS TEST 834.mp3",
        "duration": 308.42445833333335,
        "metaData": {
          "artist": "Kool Skull",
          "title": "DIRTY BASS TEST 834",
          "album": "BIG DADDY KOOL SKULL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 11 MEAN JORZ (feat. Jean Mors).mp3",
        "duration": 385.6855,
        "metaData": {
          "artist": "Kool Skull",
          "title": "MEAN JORZ (feat. Jean Mors)",
          "album": "BIG DADDY KOOL SKULL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 12 PARTY IN THE CRACKHEAD TENT.mp3",
        "duration": 287.2459166666667,
        "metaData": {
          "artist": "Kool Skull",
          "title": "PARTY IN THE CRACKHEAD TENT",
          "album": "BIG DADDY KOOL SKULL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/Kool Skull - BIG DADDY KOOL SKULL - 13 CR8ZMF -Extended- (feat. Ariel Pink).mp3",
        "duration": 654.7605416666667,
        "metaData": {
          "artist": "Kool Skull",
          "title": "CR8ZMF -Extended- (feat. Ariel Pink)",
          "album": "BIG DADDY KOOL SKULL"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/big-daddy-kool-skull.jpg",
    "largeUrl": "/album-large/big-daddy-kool-skull.jpg",
    "fullscreenUrl": "/album-fullscreen/big-daddy-kool-skull.jpg",
    "coverUrl": "/mp3/KOOL SKULL - BIG DADDY KOOL SKULL/cover.jpg"
  },
  {
    "id": "cybertemplar-audioweaponz-12212020",
    "title": "Cybertemplar Audioweaponz 12212020",
    "artist": "KOOL SKULL",
    "year": "2020",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 01 Tender Skin.mp3",
        "duration": 182.15275,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Tender Skin",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 02 Lunch in Purgatory (feat. Ariel Pink).mp3",
        "duration": 351.87945833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Lunch in Purgatory (feat. Ariel Pink)",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 03 Bruised Internal Cavity.mp3",
        "duration": 276.0002083333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Bruised Internal Cavity",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 04 Astral Lovemaking.mp3",
        "duration": 533.7170833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Astral Lovemaking",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 05 Fork Instagroom.mp3",
        "duration": 254.66383333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Fork Instagroom",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 06 Thrashers Delight.mp3",
        "duration": 326.858125,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Thrashers Delight",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 07 Spliff in the Wind.mp3",
        "duration": 170.9705,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Spliff in the Wind",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 08 Kraken Crew (feat. Sidney Powell).mp3",
        "duration": 479.05145833333336,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Kraken Crew (feat. Sidney Powell)",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 09 The One & Only Worshipful Master.mp3",
        "duration": 393.9614583333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "The One & Only Worshipful Master",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 10 Psychic Surgery.mp3",
        "duration": 208.393,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Psychic Surgery",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 11 Dinner in Heaven (feat. Ariel Pink).mp3",
        "duration": 347.9752916666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Dinner in Heaven (feat. Ariel Pink)",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 12 TRUMP IS MY NIGGA.mp3",
        "duration": 252.53416666666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "TRUMP IS MY NIGGA",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 13 The Door Will Appear (feat. Kenny Lion).mp3",
        "duration": 182.00529166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "The Door Will Appear (feat. Kenny Lion)",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 14 Toxick Maskulinity (feat. Ariel Pink).mp3",
        "duration": 225.79883333333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Toxick Maskulinity (feat. Ariel Pink)",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 15 Dream Documentation 000 (feat. Shags & Josh daCosta).mp3",
        "duration": 436.28320833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Dream Documentation 000 (feat. Shags & Josh daCosta)",
          "album": "Cybertemplar Audioweaponz 12212020"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/cybertemplar-audioweaponz-12212020.jpg",
    "largeUrl": "/album-large/cybertemplar-audioweaponz-12212020.jpg",
    "fullscreenUrl": "/album-fullscreen/cybertemplar-audioweaponz-12212020.jpg",
    "coverUrl": "/mp3/KOOL SKULL - Cybertemplar Audioweaponz 12212020/cover.jpg"
  },
  {
    "id": "halloween-special",
    "title": "HALLOWEEN SPECIAL",
    "artist": "KOOL SKULL",
    "year": "2020",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - HALLOWEEN SPECIAL/KOOL SKULL - HALLOWEEN SPECIAL - 01 Tr1ck-0r-Tr33T.mp3",
        "duration": 320.02425,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Tr1ck-0r-Tr33T",
          "album": "HALLOWEEN SPECIAL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HALLOWEEN SPECIAL/KOOL SKULL - HALLOWEEN SPECIAL - 02 Interdimensional Mutant Landscape.mp3",
        "duration": 309.31870833333335,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Interdimensional Mutant Landscape",
          "album": "HALLOWEEN SPECIAL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HALLOWEEN SPECIAL/KOOL SKULL - HALLOWEEN SPECIAL - 03 Sadchip Scythebreaks.mp3",
        "duration": 582.351875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Sadchip Scythebreaks",
          "album": "HALLOWEEN SPECIAL"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HALLOWEEN SPECIAL/KOOL SKULL - HALLOWEEN SPECIAL - 04 Purified Through Love and Thrash of Thy LORD!.mp3",
        "duration": 374.41108333333335,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Purified Through Love and Thrash of Thy LORD!",
          "album": "HALLOWEEN SPECIAL"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/halloween-special.jpg",
    "largeUrl": "/album-large/halloween-special.jpg",
    "fullscreenUrl": "/album-fullscreen/halloween-special.jpg",
    "coverUrl": "/mp3/KOOL SKULL - HALLOWEEN SPECIAL/cover.png"
  },
  {
    "id": "heavenly-thrash",
    "title": "Heavenly Thrash",
    "artist": "KOOL SKULL",
    "year": "2022",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 01 Zato Ichi Swordthrash.mp3",
        "duration": 262.2280416666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Zato Ichi Swordthrash",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 02 Stardrain Hotel.mp3",
        "duration": 340.58375,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Stardrain Hotel",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 03 Triangulation Jutsu.mp3",
        "duration": 151.65541666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Triangulation Jutsu",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 04 Seqbulb Juggler.mp3",
        "duration": 217.97675,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Seqbulb Juggler",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 05 Wownero Mutant.mp3",
        "duration": 154.25075,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Wownero Mutant",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 06 EYELOVEJESUS.mp3",
        "duration": 235.171625,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "EYELOVEJESUS",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 07 THRASH OF GODS WILL.mp3",
        "duration": 201.835,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "THRASH OF GODS WILL",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 08 Heavenly Thrash.mp3",
        "duration": 361.40091666666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Heavenly Thrash",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 09 711 BITCH (feat. milady).mp3",
        "duration": 236.83816666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "711 BITCH (feat. milady)",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 10 Stevie Found a Rotting Corpse.mp3",
        "duration": 311.79816666666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Stevie Found a Rotting Corpse",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 11 Pause Breaks.mp3",
        "duration": 218.38620833333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Pause Breaks",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 12 Water Baby.mp3",
        "duration": 218.53225,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Water Baby",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 13 Swordfight Soup.mp3",
        "duration": 226.14079166666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Swordfight Soup",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 14 Western Woundthrash.mp3",
        "duration": 395.02225,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Western Woundthrash",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 15 Thrash Thumb.mp3",
        "duration": 312.9678333333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Thrash Thumb",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 16 Thrash Lessons (feat. Kroyclub).mp3",
        "duration": 206.85375,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Thrash Lessons (feat. Kroyclub)",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 17 Base Mesh Distortion.mp3",
        "duration": 254.53341666666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Base Mesh Distortion",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 18 PiggyPuke Ritual 25.mp3",
        "duration": 254.31304166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "PiggyPuke Ritual 25",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 19 Syrup on my Pancake Hat.mp3",
        "duration": 402.344875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Syrup on my Pancake Hat",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 20 Kjasfhkjadhfkuadf.mp3",
        "duration": 241.31816666666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Kjasfhkjadhfkuadf",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 21 2KoolTV.mp3",
        "duration": 382.084625,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "2KoolTV",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 22 Polish Layover (feat. GataTECH).mp3",
        "duration": 202.79666666666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Polish Layover (feat. GataTECH)",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 23 Abremin Footwork.mp3",
        "duration": 274.6795833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Abremin Footwork",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 24 Blender Tut Binge.mp3",
        "duration": 212.01983333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Blender Tut Binge",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 25 Blackhole Blastbeats.mp3",
        "duration": 332.0534583333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Blackhole Blastbeats",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 26 Lawb you Forever.mp3",
        "duration": 283.385,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Lawb you Forever",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 27 Sacred Dumpster Fire.mp3",
        "duration": 389.02175,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Sacred Dumpster Fire",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 28 Vomit on her Clown Shoes.mp3",
        "duration": 278.5219583333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Vomit on her Clown Shoes",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 29 Multi-threaded Thrash.mp3",
        "duration": 488.4175416666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Multi-threaded Thrash",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 30 Next Iceage.mp3",
        "duration": 355.66679166666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Next Iceage",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 31 Starshine Glitchform.mp3",
        "duration": 253.191875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Starshine Glitchform",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 32 Kreepthrash 2738-zkR32X.mp3",
        "duration": 454.25304166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Kreepthrash 2738-zkR32X",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 33 Lovesong to Starshine.mp3",
        "duration": 350.03516666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Lovesong to Starshine",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 34 Maiden.mp3",
        "duration": 367.1607916666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Maiden",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 35 Lilou's Thrashbox.mp3",
        "duration": 223.93191666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Lilou's Thrashbox",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 36 Moonbase.mp3",
        "duration": 372.24058333333335,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Moonbase",
          "album": "Heavenly Thrash"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Heavenly Thrash/KOOL SKULL - Heavenly Thrash - 37 Audio Garden.mp3",
        "duration": 425.99791666666664,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Audio Garden",
          "album": "Heavenly Thrash"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/heavenly-thrash.jpg",
    "largeUrl": "/album-large/heavenly-thrash.jpg",
    "fullscreenUrl": "/album-fullscreen/heavenly-thrash.jpg",
    "coverUrl": "/mp3/KOOL SKULL - Heavenly Thrash/cover.png"
  },
  {
    "id": "hollywood-cowboy",
    "title": "HOLLYWOOD COWBOY",
    "artist": "KOOL SKULL",
    "year": "2021",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/KOOL SKULL - HOLLYWOOD COWBOY - 01 RATZ IN MY SOUL.mp3",
        "duration": 78.01808333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "RATZ IN MY SOUL",
          "album": "HOLLYWOOD COWBOY"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/KOOL SKULL - HOLLYWOOD COWBOY - 02 FINALLY FANCY.mp3",
        "duration": 252.33254166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "FINALLY FANCY",
          "album": "HOLLYWOOD COWBOY"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/KOOL SKULL - HOLLYWOOD COWBOY - 03 DIVINITY AND THE CREATURE FROM THE GREEN LATRINE.mp3",
        "duration": 326.03833333333336,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "DIVINITY AND THE CREATURE FROM THE GREEN LATRINE",
          "album": "HOLLYWOOD COWBOY"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/KOOL SKULL - HOLLYWOOD COWBOY - 04 ANGRIER THAN I THOUGHT I’D BE.mp3",
        "duration": 242.238,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "ANGRIER THAN I THOUGHT I’D BE",
          "album": "HOLLYWOOD COWBOY"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/KOOL SKULL - HOLLYWOOD COWBOY - 05 SLIME QUEEN.mp3",
        "duration": 319.6087083333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "SLIME QUEEN",
          "album": "HOLLYWOOD COWBOY"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/KOOL SKULL - HOLLYWOOD COWBOY - 06 PUSSY HACKERS.mp3",
        "duration": 413.54108333333335,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "PUSSY HACKERS",
          "album": "HOLLYWOOD COWBOY"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/KOOL SKULL - HOLLYWOOD COWBOY - 07 KONQUEZT.mp3",
        "duration": 390.6995416666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "KONQUEZT",
          "album": "HOLLYWOOD COWBOY"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/KOOL SKULL - HOLLYWOOD COWBOY - 08 URMYBBGRL.mp3",
        "duration": 403.7325416666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "URMYBBGRL",
          "album": "HOLLYWOOD COWBOY"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/KOOL SKULL - HOLLYWOOD COWBOY - 09 JUAN MORE BLUNT.mp3",
        "duration": 353.5635833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "JUAN MORE BLUNT",
          "album": "HOLLYWOOD COWBOY"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/KOOL SKULL - HOLLYWOOD COWBOY - 10 HYPNOTIZE.mp3",
        "duration": 215.99466666666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "HYPNOTIZE",
          "album": "HOLLYWOOD COWBOY"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/KOOL SKULL - HOLLYWOOD COWBOY - 11 GOBER AND THE HOMIES.mp3",
        "duration": 411.87125,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "GOBER AND THE HOMIES",
          "album": "HOLLYWOOD COWBOY"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/KOOL SKULL - HOLLYWOOD COWBOY - 12 DEEP IN SLIME HELL.mp3",
        "duration": 356.9411666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "DEEP IN SLIME HELL",
          "album": "HOLLYWOOD COWBOY"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/hollywood-cowboy.jpg",
    "largeUrl": "/album-large/hollywood-cowboy.jpg",
    "fullscreenUrl": "/album-fullscreen/hollywood-cowboy.jpg",
    "coverUrl": "/mp3/KOOL SKULL - HOLLYWOOD COWBOY/cover.png"
  },
  {
    "id": "island-of-ooze",
    "title": "Island of Ooze",
    "artist": "KOOL SKULL",
    "year": "2020",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 01 Green Pill.mp3",
        "duration": 179.0705,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Green Pill",
          "album": "Island of Ooze"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 02 Slime Queen Lullaby (feat. Jean Mors).mp3",
        "duration": 295.33691666666664,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Slime Queen Lullaby (feat. Jean Mors)",
          "album": "Island of Ooze"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 03 Fertilizer, Yum! (Feat. Jean Mors).mp3",
        "duration": 161.524125,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Fertilizer, Yum! (Feat. Jean Mors)",
          "album": "Island of Ooze"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 04 Mr. Handsome.mp3",
        "duration": 274.61358333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Mr. Handsome",
          "album": "Island of Ooze"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 05 Most Dillemas.mp3",
        "duration": 320.87333333333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Most Dillemas",
          "album": "Island of Ooze"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 06 Heartbreak Hell.mp3",
        "duration": 184.15295833333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Heartbreak Hell",
          "album": "Island of Ooze"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 07 Blood Makes Noise.mp3",
        "duration": 245.91841666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Blood Makes Noise",
          "album": "Island of Ooze"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 08 Girl at the Taco Stand (feat. Moda).mp3",
        "duration": 259.59583333333336,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Girl at the Taco Stand (feat. Moda)",
          "album": "Island of Ooze"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 09 Island of Ooze (feat. Jean Mors).mp3",
        "duration": 168.44666666666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Island of Ooze (feat. Jean Mors)",
          "album": "Island of Ooze"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 10 I Want to be your Husband.mp3",
        "duration": 258.85,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "I Want to be your Husband",
          "album": "Island of Ooze"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 11 Nice Guys Finish Last (feat. Ariel Pink & Jean Mors).mp3",
        "duration": 227.67833333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Nice Guys Finish Last (feat. Ariel Pink & Jean Mors)",
          "album": "Island of Ooze"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 12 Space Case (feat. Jean Mors).mp3",
        "duration": 453.992,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Space Case (feat. Jean Mors)",
          "album": "Island of Ooze"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Island of Ooze/KOOL SKULL - Island of Ooze - 13 Silent Prayer Guidance (feat. Jesse Lee Peterson).mp3",
        "duration": 66.27975,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Silent Prayer Guidance (feat. Jesse Lee Peterson)",
          "album": "Island of Ooze"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/island-of-ooze.jpg",
    "largeUrl": "/album-large/island-of-ooze.jpg",
    "fullscreenUrl": "/album-fullscreen/island-of-ooze.jpg",
    "coverUrl": "/mp3/KOOL SKULL - Island of Ooze/cover.jpg"
  },
  {
    "id": "jankler-jamz-vol--1",
    "title": "JANKLER JAMZ VOL. 1",
    "artist": "KOOL SKULL",
    "year": "2022",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - JANKLER JAMZ VOL. 1/KOOL SKULL - JANKLER JAMZ VOL. 1 - 01 BONKLER YOUR JANKLER (feat. MIYAGOD).mp3",
        "duration": 396.730625,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "BONKLER YOUR JANKLER (feat. MIYAGOD)",
          "album": "JANKLER JAMZ VOL. 1"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - JANKLER JAMZ VOL. 1/KOOL SKULL - JANKLER JAMZ VOL. 1 - 02 SHE BONKLERED ON MY JANKLER (feat. SorosBruv and Chadwick Astor).mp3",
        "duration": 220.033875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "SHE BONKLERED ON MY JANKLER (feat. SorosBruv and Chadwick Astor)",
          "album": "JANKLER JAMZ VOL. 1"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/jankler-jamz-vol--1.jpg",
    "largeUrl": "/album-large/jankler-jamz-vol--1.jpg",
    "fullscreenUrl": "/album-fullscreen/jankler-jamz-vol--1.jpg",
    "coverUrl": "/mp3/KOOL SKULL - JANKLER JAMZ VOL. 1/cover.jpg"
  },
  {
    "id": "just-another-thrash-reckord",
    "title": "JUST ANOTHER THRASH RECKORD",
    "artist": "KOOL SKULL",
    "year": "2023",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/KOOL SKULL - JUST ANOTHER THRASH RECKORD - 01 Intro.mp3",
        "duration": 141.28304166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Intro",
          "album": "JUST ANOTHER THRASH RECKORD"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/KOOL SKULL - JUST ANOTHER THRASH RECKORD - 02 Associates-.mp3",
        "duration": 264.11133333333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Associates-",
          "album": "JUST ANOTHER THRASH RECKORD"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/KOOL SKULL - JUST ANOTHER THRASH RECKORD - 03 Kool Skool.mp3",
        "duration": 402.79133333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Kool Skool",
          "album": "JUST ANOTHER THRASH RECKORD"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/KOOL SKULL - JUST ANOTHER THRASH RECKORD - 04 Chords N Blips.mp3",
        "duration": 224.02691666666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Chords N Blips",
          "album": "JUST ANOTHER THRASH RECKORD"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/KOOL SKULL - JUST ANOTHER THRASH RECKORD - 05 Funky Freakshow.mp3",
        "duration": 206.18991666666668,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Funky Freakshow",
          "album": "JUST ANOTHER THRASH RECKORD"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/KOOL SKULL - JUST ANOTHER THRASH RECKORD - 06 Glitchfunk Booty.mp3",
        "duration": 245.0175,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Glitchfunk Booty",
          "album": "JUST ANOTHER THRASH RECKORD"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/KOOL SKULL - JUST ANOTHER THRASH RECKORD - 07 Nightmare Poopeteer.mp3",
        "duration": 302.47433333333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Nightmare Poopeteer",
          "album": "JUST ANOTHER THRASH RECKORD"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/KOOL SKULL - JUST ANOTHER THRASH RECKORD - 08 Puking All Night Long.mp3",
        "duration": 335.43470833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Puking All Night Long",
          "album": "JUST ANOTHER THRASH RECKORD"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/KOOL SKULL - JUST ANOTHER THRASH RECKORD - 09 Slow Jam Puke Sauce.mp3",
        "duration": 273.7715,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Slow Jam Puke Sauce",
          "album": "JUST ANOTHER THRASH RECKORD"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/KOOL SKULL - JUST ANOTHER THRASH RECKORD - 10 Pindars Reign Has Ended.mp3",
        "duration": 283.38754166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Pindars Reign Has Ended",
          "album": "JUST ANOTHER THRASH RECKORD"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/KOOL SKULL - JUST ANOTHER THRASH RECKORD - 11 Thrash Machine.mp3",
        "duration": 323.78979166666664,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Thrash Machine",
          "album": "JUST ANOTHER THRASH RECKORD"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/KOOL SKULL - JUST ANOTHER THRASH RECKORD - 12 Audrey3000.mp3",
        "duration": 327.42758333333336,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Audrey3000",
          "album": "JUST ANOTHER THRASH RECKORD"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/just-another-thrash-reckord.jpg",
    "largeUrl": "/album-large/just-another-thrash-reckord.jpg",
    "fullscreenUrl": "/album-fullscreen/just-another-thrash-reckord.jpg",
    "coverUrl": "/mp3/KOOL SKULL - JUST ANOTHER THRASH RECKORD/cover.jpg"
  },
  {
    "id": "merry-g-----l--i---t----c--h---mas",
    "title": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS",
    "artist": "KOOL SKULL",
    "year": "2025",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 01 2K4D THRASH BREAK 002.mp3",
        "duration": 112.17875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "2K4D THRASH BREAK 002",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 02 555N.mp3",
        "duration": 809.8757916666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "555N",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 03 ADJUSTED PASSION.mp3",
        "duration": 246.67995833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "ADJUSTED PASSION",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 04 ALIEN BABE BOOTY THRASH.mp3",
        "duration": 468.86075,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "ALIEN BABE BOOTY THRASH",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 05 ATONAL HYMN OF PUNISHMENT.mp3",
        "duration": 374.091625,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "ATONAL HYMN OF PUNISHMENT",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 06 BREANNE FINDS CRYPTID FOSSILS.mp3",
        "duration": 80.33920833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "BREANNE FINDS CRYPTID FOSSILS",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 07 CRYPTOGENETIC FRUITS OF THE SPIRIT.mp3",
        "duration": 300.7221666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "CRYPTOGENETIC FRUITS OF THE SPIRIT",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 08 DISCO FOR MY WIFE TO DANCE TO.mp3",
        "duration": 254.52270833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "DISCO FOR MY WIFE TO DANCE TO",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 09 DRAINA ON THE MIDI GUITAR.mp3",
        "duration": 242.83145833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "DRAINA ON THE MIDI GUITAR",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 10 DROWNING IN SYNTH PISS.mp3",
        "duration": 390.24725,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "DROWNING IN SYNTH PISS",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 11 DRUMCOMPUTER (INSTANCE A).mp3",
        "duration": 187.59279166666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "DRUMCOMPUTER (INSTANCE A)",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 12 ELVEN GALACTIC PRINCESS.mp3",
        "duration": 341.7574583333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "ELVEN GALACTIC PRINCESS",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 13 FATBEET.mp3",
        "duration": 292.0975833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "FATBEET",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 14 GROUP INTIMACY UNDER THE INFLUENCE OF PSYCHEDELICS.mp3",
        "duration": 248.39245833333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "GROUP INTIMACY UNDER THE INFLUENCE OF PSYCHEDELICS",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 15 HEART SUTRA THRASH.mp3",
        "duration": 281.4635416666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "HEART SUTRA THRASH",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 16 HEAVYLOVING.mp3",
        "duration": 283.7638333333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "HEAVYLOVING",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 17 HOOCHIE MAMMA UNDERWEARS (feat. Jesse Lee Peterson).mp3",
        "duration": 240.34725,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "HOOCHIE MAMMA UNDERWEARS (feat. Jesse Lee Peterson)",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 18 HYMNS OF PHOTOSYNTHETIC PEACEFULNESS.mp3",
        "duration": 288.347625,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "HYMNS OF PHOTOSYNTHETIC PEACEFULNESS",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 19 INTERDIMENSIONAL DOWNLOADZ OF THY FATHER.mp3",
        "duration": 567.751375,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "INTERDIMENSIONAL DOWNLOADZ OF THY FATHER",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 20 OPALESCENT METAMESH.mp3",
        "duration": 420.65066666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "OPALESCENT METAMESH",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 21 QTPYE.mp3",
        "duration": 328.65229166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "QTPYE",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 22 REAL SECURITY.mp3",
        "duration": 285.98491666666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "REAL SECURITY",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 23 RICA (feat. KARANDI).mp3",
        "duration": 270.78820833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "RICA (feat. KARANDI)",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 24 ROIDRAGE RILAKKUMA.mp3",
        "duration": 350.59175,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "ROIDRAGE RILAKKUMA",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS - 25 SHORT ATTENTION SPAM.mp3",
        "duration": 270.484625,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "SHORT ATTENTION SPAM",
          "album": "MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/merry-g-----l--i---t----c--h---mas.jpg",
    "largeUrl": "/album-large/merry-g-----l--i---t----c--h---mas.jpg",
    "fullscreenUrl": "/album-fullscreen/merry-g-----l--i---t----c--h---mas.jpg",
    "coverUrl": "/mp3/KOOL SKULL - MERRY G̸̨̛͜͝L҉̡I̴̶̵T̸̶̢͠C̸҉H̵̕͡MAS/cover.jpg"
  },
  {
    "id": "ow2bkool-ii",
    "title": "Ow2bkool II",
    "artist": "KOOL SKULL",
    "year": "2023",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 01 Make U Smile.mp3",
        "duration": 305.08325,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Make U Smile",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 02 Cr8zmf (feat. Ariel Pink).mp3",
        "duration": 315.130125,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Cr8zmf (feat. Ariel Pink)",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 03 Don’t Kill the Fool.mp3",
        "duration": 220.386875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Don’t Kill the Fool",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 04 Eye H8 Musick.mp3",
        "duration": 282.96875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Eye H8 Musick",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 05 Xplosive.mp3",
        "duration": 169.53666666666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Xplosive",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 06 Marble Cake (feat. David Liebe Hart & Jónó mí Ló).mp3",
        "duration": 469.8378333333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Marble Cake (feat. David Liebe Hart & Jónó mí Ló)",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 07 Pretty Pretty.mp3",
        "duration": 306.562,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Pretty Pretty",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 08 Fake News Flash (feat. Milo Yiannopoulos).mp3",
        "duration": 280.94725,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Fake News Flash (feat. Milo Yiannopoulos)",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 09 Zordon’s Interlude (feat. David J. Fielding).mp3",
        "duration": 87.13870833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Zordon’s Interlude (feat. David J. Fielding)",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 10 Down in the Grave.mp3",
        "duration": 362.10558333333336,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Down in the Grave",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 11 Un Besito.mp3",
        "duration": 440.85604166666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Un Besito",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 12 Ow2bkool.mp3",
        "duration": 317.28116666666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Ow2bkool",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 13 Puke is the Smell of your Perfume.mp3",
        "duration": 303.61875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Puke is the Smell of your Perfume",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 14 Hollywood Cowboy.mp3",
        "duration": 153.78408333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Hollywood Cowboy",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 15 Say Whatever U Want.mp3",
        "duration": 118.969,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Say Whatever U Want",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 16 Famous Words (feat. The Crenshaw Cowboy).mp3",
        "duration": 63.733583333333335,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Famous Words (feat. The Crenshaw Cowboy)",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 17 Always Be Responsible.mp3",
        "duration": 288.81483333333335,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Always Be Responsible",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 18 Eye Miss U.mp3",
        "duration": 469.5869583333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Eye Miss U",
          "album": "Ow2bkool II"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Ow2bkool II/KOOL SKULL - Ow2bkool II - 19 Luv Iz Ow2bkool.mp3",
        "duration": 379.9725,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Luv Iz Ow2bkool",
          "album": "Ow2bkool II"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/ow2bkool-ii.jpg",
    "largeUrl": "/album-large/ow2bkool-ii.jpg",
    "fullscreenUrl": "/album-fullscreen/ow2bkool-ii.jpg",
    "coverUrl": "/mp3/KOOL SKULL - Ow2bkool II/cover.jpg"
  },
  {
    "id": "pinwheel",
    "title": "Pinwheel",
    "artist": "KOOL SKULL",
    "year": "2019",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - Pinwheel/KOOL SKULL - Pinwheel - 01 The Snake and the Mirror.mp3",
        "duration": 323.6825416666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "The Snake and the Mirror",
          "album": "Pinwheel"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Pinwheel/KOOL SKULL - Pinwheel - 02 Endless Love (feat. DreWel).mp3",
        "duration": 335.2235416666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Endless Love (feat. DreWel)",
          "album": "Pinwheel"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Pinwheel/KOOL SKULL - Pinwheel - 03 Daddy Loves U.mp3",
        "duration": 401.8495833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Daddy Loves U",
          "album": "Pinwheel"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Pinwheel/KOOL SKULL - Pinwheel - 04 Puke as you Walk.mp3",
        "duration": 282.19029166666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Puke as you Walk",
          "album": "Pinwheel"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Pinwheel/KOOL SKULL - Pinwheel - 05 Sh8k it like a Chupacabra.mp3",
        "duration": 273.66341666666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Sh8k it like a Chupacabra",
          "album": "Pinwheel"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Pinwheel/KOOL SKULL - Pinwheel - 06 Ready 4 Deaf.mp3",
        "duration": 226.784625,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Ready 4 Deaf",
          "album": "Pinwheel"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Pinwheel/KOOL SKULL - Pinwheel - 07 Don't Look.mp3",
        "duration": 181.36233333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Don't Look",
          "album": "Pinwheel"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Pinwheel/KOOL SKULL - Pinwheel - 08 Eatin' Chips.mp3",
        "duration": 295.818875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Eatin' Chips",
          "album": "Pinwheel"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Pinwheel/KOOL SKULL - Pinwheel - 09 'Nutha Weakend.mp3",
        "duration": 350.2205,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "'Nutha Weakend",
          "album": "Pinwheel"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/pinwheel.jpg",
    "largeUrl": "/album-large/pinwheel.jpg",
    "fullscreenUrl": "/album-fullscreen/pinwheel.jpg",
    "coverUrl": "/mp3/KOOL SKULL - Pinwheel/cover.jpg"
  },
  {
    "id": "puke-on-the-sidewalk",
    "title": "Puke on the Sidewalk",
    "artist": "KOOL SKULL",
    "year": "2018",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 01 Shark Mutant Hologram Phaser.mp3",
        "duration": 361.156625,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Shark Mutant Hologram Phaser",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 02 Chillin' Like an Ent.mp3",
        "duration": 329.4982083333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Chillin' Like an Ent",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 03 Antiseptic Generation.mp3",
        "duration": 287.788,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Antiseptic Generation",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 04 Purple Oozing Cysts.mp3",
        "duration": 467.257875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Purple Oozing Cysts",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 05 I Love My Weed (feat. Kenny Lion).mp3",
        "duration": 350.81466666666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "I Love My Weed (feat. Kenny Lion)",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 06 Let The Dog Die (feat. Madison Sinclair).mp3",
        "duration": 319.19145833333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Let The Dog Die (feat. Madison Sinclair)",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 07 The Fuck You Want (feat. Sheyez).mp3",
        "duration": 215.998,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "The Fuck You Want (feat. Sheyez)",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 08 Instructions A.mp3",
        "duration": 346.116375,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Instructions A",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 09 Prayers in Slime.mp3",
        "duration": 351.83433333333335,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Prayers in Slime",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 10 Idgi (feat. Maybe Favie).mp3",
        "duration": 433.89941666666664,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Idgi (feat. Maybe Favie)",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 11 F8k Ass (feat. Moda).mp3",
        "duration": 319.9957083333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "F8k Ass (feat. Moda)",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 12 Rust Scratcher.mp3",
        "duration": 350.58275,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Rust Scratcher",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 13 Grownt.mp3",
        "duration": 379.5515,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Grownt",
          "album": "Puke on the Sidewalk"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Puke on the Sidewalk/KOOL SKULL - Puke on the Sidewalk - 14 Terror Thrashers (feat. Terr9r).mp3",
        "duration": 100.73220833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Terror Thrashers (feat. Terr9r)",
          "album": "Puke on the Sidewalk"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/puke-on-the-sidewalk.jpg",
    "largeUrl": "/album-large/puke-on-the-sidewalk.jpg",
    "fullscreenUrl": "/album-fullscreen/puke-on-the-sidewalk.jpg",
    "coverUrl": "/mp3/KOOL SKULL - Puke on the Sidewalk/cover.jpg"
  },
  {
    "id": "stardrain---chapter-1--2k4d",
    "title": "Stardrain - Chapter 1- 2k4d",
    "artist": "KOOL SKULL",
    "year": "2022",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - Stardrain - Chapter 1- 2k4d/KOOL SKULL - Stardrain - Chapter 1- 2k4d - 01 Intro.mp3",
        "duration": 94.81866666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Intro",
          "album": "Stardrain - Chapter 1- 2k4d"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Stardrain - Chapter 1- 2k4d/KOOL SKULL - Stardrain - Chapter 1- 2k4d - 02 Gimme Dat Pussy (feat. Moda).mp3",
        "duration": 269.08225,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Gimme Dat Pussy (feat. Moda)",
          "album": "Stardrain - Chapter 1- 2k4d"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Stardrain - Chapter 1- 2k4d/KOOL SKULL - Stardrain - Chapter 1- 2k4d - 03 Be Nice and Do Something (feat. Ariel Pink).mp3",
        "duration": 239.692375,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Be Nice and Do Something (feat. Ariel Pink)",
          "album": "Stardrain - Chapter 1- 2k4d"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Stardrain - Chapter 1- 2k4d/KOOL SKULL - Stardrain - Chapter 1- 2k4d - 04 Walking Home.mp3",
        "duration": 218.9205,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Walking Home",
          "album": "Stardrain - Chapter 1- 2k4d"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Stardrain - Chapter 1- 2k4d/KOOL SKULL - Stardrain - Chapter 1- 2k4d - 05 Endless Normies.mp3",
        "duration": 187.72854166666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Endless Normies",
          "album": "Stardrain - Chapter 1- 2k4d"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Stardrain - Chapter 1- 2k4d/KOOL SKULL - Stardrain - Chapter 1- 2k4d - 06 Free Fault.mp3",
        "duration": 274.5274166666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Free Fault",
          "album": "Stardrain - Chapter 1- 2k4d"
        }
      }
    ],
    "coverUrl": "/mp3/KOOL SKULL - Stardrain - Chapter 1- 2k4d/cover.jpg"
  },
  {
    "id": "thrash-prophet",
    "title": "Thrash Prophet",
    "artist": "KOOL SKULL",
    "year": "2021",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 01 Brush Yo Teeth.mp3",
        "duration": 219.469125,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Brush Yo Teeth",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 02 2Tanit.mp3",
        "duration": 200.043625,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "2Tanit",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 03 Have 2 Agree (Feat. Tripp Vomit).mp3",
        "duration": 324.803875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Have 2 Agree (Feat. Tripp Vomit)",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 04 Puking Up Roses.mp3",
        "duration": 486.43558333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Puking Up Roses",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 05 See Thru Skintone.mp3",
        "duration": 315.2391666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "See Thru Skintone",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 06 2Legit (Feat. DVS Donyea Da Bombay).mp3",
        "duration": 167.81466666666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "2Legit (Feat. DVS Donyea Da Bombay)",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 07 Thoth's Revenge.mp3",
        "duration": 200.51854166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Thoth's Revenge",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 08 Penetrating Time.mp3",
        "duration": 236.752625,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Penetrating Time",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 09 Blueline Boomin'.mp3",
        "duration": 241.91920833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Blueline Boomin'",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 10 Pure Law.mp3",
        "duration": 253.98120833333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Pure Law",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 11 Dream Bb (Feat. Theo Rollin).mp3",
        "duration": 239.95504166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Dream Bb (Feat. Theo Rollin)",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 12 Tiny Tino Retrash.mp3",
        "duration": 263.75225,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Tiny Tino Retrash",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 13 Mayan Bineural Hypnosis.mp3",
        "duration": 307.7572916666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Mayan Bineural Hypnosis",
          "album": "Thrash Prophet"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Thrash Prophet/KOOL SKULL - Thrash Prophet - 14 Breakcoredancing.mp3",
        "duration": 283.7416666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Breakcoredancing",
          "album": "Thrash Prophet"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/thrash-prophet.jpg",
    "largeUrl": "/album-large/thrash-prophet.jpg",
    "fullscreenUrl": "/album-fullscreen/thrash-prophet.jpg",
    "coverUrl": "/mp3/KOOL SKULL - Thrash Prophet/cover.jpg"
  },
  {
    "id": "tiger-tooth-cavities",
    "title": "Tiger Tooth Cavities",
    "artist": "KOOL SKULL",
    "year": "2019",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - Tiger Tooth Cavities/KOOL SKULL - Tiger Tooth Cavities - 01 Grunge Boy.mp3",
        "duration": 259.216,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Grunge Boy",
          "album": "Tiger Tooth Cavities"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Tiger Tooth Cavities/KOOL SKULL - Tiger Tooth Cavities - 02 Mutant Fish Only.mp3",
        "duration": 220.84075,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Mutant Fish Only",
          "album": "Tiger Tooth Cavities"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Tiger Tooth Cavities/KOOL SKULL - Tiger Tooth Cavities - 03 Thrash Stash 3578.mp3",
        "duration": 187.72104166666668,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Thrash Stash 3578",
          "album": "Tiger Tooth Cavities"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Tiger Tooth Cavities/KOOL SKULL - Tiger Tooth Cavities - 04 Alien Temple Ruinz.mp3",
        "duration": 308.0926666666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Alien Temple Ruinz",
          "album": "Tiger Tooth Cavities"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Tiger Tooth Cavities/KOOL SKULL - Tiger Tooth Cavities - 05 Phatty Thrash Trap Swamp.mp3",
        "duration": 180.794375,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Phatty Thrash Trap Swamp",
          "album": "Tiger Tooth Cavities"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Tiger Tooth Cavities/KOOL SKULL - Tiger Tooth Cavities - 06 Booty Sweat Vile.mp3",
        "duration": 341.972875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Booty Sweat Vile",
          "album": "Tiger Tooth Cavities"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Tiger Tooth Cavities/KOOL SKULL - Tiger Tooth Cavities - 07 Thank God 4 Thrash.mp3",
        "duration": 251.39008333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Thank God 4 Thrash",
          "album": "Tiger Tooth Cavities"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Tiger Tooth Cavities/KOOL SKULL - Tiger Tooth Cavities - 08 Slime Crime Happens.mp3",
        "duration": 337.107125,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Slime Crime Happens",
          "album": "Tiger Tooth Cavities"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/tiger-tooth-cavities.jpg",
    "largeUrl": "/album-large/tiger-tooth-cavities.jpg",
    "fullscreenUrl": "/album-fullscreen/tiger-tooth-cavities.jpg",
    "coverUrl": "/mp3/KOOL SKULL - Tiger Tooth Cavities/cover.jpg"
  },
  {
    "id": "various-instrumentals",
    "title": "Various Instrumentals",
    "artist": "KOOL SKULL",
    "year": "2023",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - Various Instrumentals/KOOL SKULL - Various Instrumentals - 01 Shoogie 5000.mp3",
        "duration": 281.6460416666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Shoogie 5000",
          "album": "Various Instrumentals"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Various Instrumentals/KOOL SKULL - Various Instrumentals - 02 Arjun’s Banger.mp3",
        "duration": 410.0287916666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Arjun’s Banger",
          "album": "Various Instrumentals"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Various Instrumentals/KOOL SKULL - Various Instrumentals - 03 Lovers Holding Hands.mp3",
        "duration": 341.53904166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Lovers Holding Hands",
          "album": "Various Instrumentals"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Various Instrumentals/KOOL SKULL - Various Instrumentals - 04 Berry Veautiful.mp3",
        "duration": 309.12008333333335,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Berry Veautiful",
          "album": "Various Instrumentals"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Various Instrumentals/KOOL SKULL - Various Instrumentals - 05 Rythym and Booze.mp3",
        "duration": 434.10254166666664,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Rythym and Booze",
          "album": "Various Instrumentals"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Various Instrumentals/KOOL SKULL - Various Instrumentals - 06 Rooting 4 U.mp3",
        "duration": 336.78875,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Rooting 4 U",
          "album": "Various Instrumentals"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Various Instrumentals/KOOL SKULL - Various Instrumentals - 07 Waveriders.mp3",
        "duration": 261.40658333333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Waveriders",
          "album": "Various Instrumentals"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Various Instrumentals/KOOL SKULL - Various Instrumentals - 08 Linear Timespace.mp3",
        "duration": 312.1105416666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Linear Timespace",
          "album": "Various Instrumentals"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Various Instrumentals/KOOL SKULL - Various Instrumentals - 09 Love Your Friends.mp3",
        "duration": 333.543375,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "Love Your Friends",
          "album": "Various Instrumentals"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - Various Instrumentals/KOOL SKULL - Various Instrumentals - 10 RAP BATTLE BEET.mp3",
        "duration": 265.49991666666665,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "RAP BATTLE BEET",
          "album": "Various Instrumentals"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/various-instrumentals.jpg",
    "largeUrl": "/album-large/various-instrumentals.jpg",
    "fullscreenUrl": "/album-fullscreen/various-instrumentals.jpg",
    "coverUrl": "/mp3/KOOL SKULL - Various Instrumentals/cover.jpg"
  },
  {
    "id": "yum",
    "title": "YUM",
    "artist": "KOOL SKULL",
    "year": "2023",
    "tracks": [
      {
        "url": "/mp3/KOOL SKULL - YUM/KOOL SKULL - YUM - 01 BEATLEJOOZ.mp3",
        "duration": 178.84283333333335,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "BEATLEJOOZ",
          "album": "YUM"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - YUM/KOOL SKULL - YUM - 02 BLDYMOUF.mp3",
        "duration": 214.24529166666667,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "BLDYMOUF",
          "album": "YUM"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - YUM/KOOL SKULL - YUM - 03 CHEWCYCLE.mp3",
        "duration": 329.7830833333333,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "CHEWCYCLE",
          "album": "YUM"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - YUM/KOOL SKULL - YUM - 04 KORTAL WOMBAT.mp3",
        "duration": 258.48379166666666,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "KORTAL WOMBAT",
          "album": "YUM"
        }
      },
      {
        "url": "/mp3/KOOL SKULL - YUM/KOOL SKULL - YUM - 05 LUVMEPLZ.mp3",
        "duration": 224.52695833333334,
        "metaData": {
          "artist": "KOOL SKULL",
          "title": "LUVMEPLZ",
          "album": "YUM"
        }
      }
    ],
    "thumbUrl": "/album-thumbs/yum.jpg",
    "largeUrl": "/album-large/yum.jpg",
    "fullscreenUrl": "/album-fullscreen/yum.jpg",
    "coverUrl": "/mp3/KOOL SKULL - YUM/cover.jpg"
  }
];

export default discography;
