import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/no-autocomplete.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// More robust filter for Chrome extension errors
(function() {
  // Create a more comprehensive filter
  const originalConsoleError = console.error;
  console.error = function(...args: any[]) {
    // Convert all arguments to string and join
    const errorText = args.map(arg => 
      typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
    ).join(' ');
    
    // Check for the specific extension error in various forms
    if ((errorText.includes('Invalid property descriptor') || 
         errorText.includes('Cannot both specify accessors')) && 
        (errorText.includes('fiikommddbeccaoicoejoniammnalkfa') || 
         errorText.includes('chrome-extension'))) {
      return; // Suppress the error
    }
    
    // Pass other errors through to original handler
    originalConsoleError.apply(console, args);
  };
  
  // Also try to catch the error at window.onerror level
  const originalOnError = window.onerror;
  window.onerror = function(message, source, lineno, colno, error) {
    if (source && typeof source === 'string' && 
        source.includes('fiikommddbeccaoicoejoniammnalkfa')) {
      return true; // Prevent the error from bubbling up
    }
    
    // Call the original handler if it exists
    if (originalOnError) {
      return originalOnError.apply(this, [message, source, lineno, colno, error]);
    }
    return false;
  };
})();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
