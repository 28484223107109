import React, { useState, useRef, useEffect, useCallback } from 'react';
import './ArtViewer.css';
import { ArtImage, ArtCategory } from './ArtPortfolio';
import { useArtInfo } from '../contexts/ArtInfoContext';

// New helper function to preload images
const preloadImage = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = () => reject();
    img.src = src;
  });
};

interface ArtViewerProps {
  selectedImage: ArtImage | null;
  isMaximized?: boolean;
  allCategories?: ArtCategory[];
}

interface Transform {
  scale: number;
  translateX: number;
  translateY: number;
}

const MIN_SCALE = 0.1;
const MAX_SCALE = 5;
const SCROLL_SENSITIVITY = 0.0005;
const DOUBLE_TAP_TIME = 300; // milliseconds between taps to register as double-tap

interface TouchState {
  touchCount: number;
  startDistance?: number;
  startScale?: number;
  startX?: number;
  startY?: number;
  lastTap?: number;
  tapX?: number;
  tapY?: number;
}

const ArtViewer: React.FC<ArtViewerProps> = ({ 
  selectedImage, 
  isMaximized,
  allCategories = []
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [transform, setTransform] = useState<Transform>({ scale: 1, translateX: 0, translateY: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [touchState, setTouchState] = useState<TouchState>({ touchCount: 0 });
  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const currentImageSrc = useRef<string | null>(null);
  
  // Get art info visibility state
  const { showArtInfo } = useArtInfo();

  // Update image src without resetting position
  useEffect(() => {
    if (!selectedImage) {
      return;
    }
    
    setIsLoading(true);
    setHasError(false);
    
    // Reset transform when changing images
    resetTransform();
    
    // Create a new Image object to preload the image
    const img = new Image();
    
    img.onload = () => {
      if (imageRef.current) {
        imageRef.current.src = selectedImage.fullImage;
        currentImageSrc.current = selectedImage.fullImage;
      }
      setIsLoading(false);
    };
    
    img.onerror = () => {
      // Try to load the thumbnail as a fallback
      const thumbImg = new Image();
      
      thumbImg.onload = () => {
        if (imageRef.current) {
          imageRef.current.src = selectedImage.thumbnail;
          currentImageSrc.current = selectedImage.thumbnail;
        }
        setIsLoading(false);
      };
      
      thumbImg.onerror = () => {
        setIsLoading(false);
        setHasError(true);
      };
      
      thumbImg.src = selectedImage.thumbnail;
    };
    
    // Start loading the image
    img.src = selectedImage.fullImage;
    
  }, [selectedImage]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setHasError(true);
    console.error("Image failed to load:", selectedImage?.fullImage);
  };

  const retryLoadImage = () => {
    if (selectedImage) {
      // Reset error state and set loading state
      setIsLoading(true);
      setHasError(false);
      
      // Always try the full image first, and only use thumbnail as fallback if it fails again
      const primarySrc = selectedImage.fullImage;
      const fallbackSrc = selectedImage.thumbnail;
      
      // Force a fresh load by creating a new Image
      const img = new Image();
      
      img.onload = () => {
        // Successfully loaded, update the displayed image
        if (imageRef.current) {
          imageRef.current.src = primarySrc;
          currentImageSrc.current = primarySrc;
        }
        setIsLoading(false);
        setHasError(false);
      };
      
      img.onerror = () => {
        console.error("Failed to load full image, trying thumbnail:", fallbackSrc);
        
        // Try the thumbnail as fallback
        const thumbImg = new Image();
        
        thumbImg.onload = () => {
          if (imageRef.current) {
            imageRef.current.src = fallbackSrc;
            currentImageSrc.current = fallbackSrc;
          }
          setIsLoading(false);
          setHasError(false);
        };
        
        thumbImg.onerror = () => {
          console.error("Both full image and thumbnail failed to load");
          setIsLoading(false);
          setHasError(true);
        };
        
        thumbImg.src = fallbackSrc;
      };
      
      // Start loading the primary source
      img.src = primarySrc;
    }
  };

  const resetTransform = () => {
    setTransform({ scale: 1, translateX: 0, translateY: 0 });
  };

  if (!selectedImage) {
    return (
      <div className="art-viewer kos-window">
        <div className="art-viewer-header kos-window-header">
          <span>No Image Selected</span>
        </div>
        <div className="art-viewer-content">
          <div className="image-error">
            <div className="image-error-icon">ℹ</div>
            <p>Please select an image from the portfolio to view</p>
          </div>
        </div>
      </div>
    );
  }

  const parts = selectedImage.fullImage.split('_');
  const metadata = {
    title: selectedImage.title || parts[0]?.split('/').pop() || '',
    year: selectedImage.year || parts[1] || '',
    medium: selectedImage.medium || parts[2] || '',
    dimensions: selectedImage.dimensions || parts[3] || '',
    description: selectedImage.description || parts[4]?.split('.')[0] || ''
  };

  return (
    <div className={`art-viewer kos-window ${isMaximized ? 'maximized' : ''}`}>
      <div className="art-viewer-header kos-window-header">
        <span>{showArtInfo ? metadata.title : ''}</span>
      </div>
      <div className="art-viewer-content">
        <div className="art-image">
          {hasError ? (
            <div className="image-error">
              <div className="image-error-icon">✖</div>
              <p>Failed to load image</p>
              <button className="kos-button kos-border" onClick={retryLoadImage}>
                Retry
              </button>
            </div>
          ) : (
            <div 
              ref={containerRef}
              className="image-container"
              style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
            >
              <img
                ref={imageRef}
                alt={metadata.title}
                style={{
                  transform: `translate(${transform.translateX}px, ${transform.translateY}px) scale(${transform.scale})`,
                  transition: isDragging ? 'none' : 'transform 0.1s ease-out',
                  opacity: isLoading ? 0 : 1
                }}
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
              {isLoading && (
                <div className="art-viewer-loading">
                  <div className="art-viewer-loading-indicator"></div>
                  <p>Loading...</p>
                </div>
              )}
            </div>
          )}
        </div>
        
        {showArtInfo && (
          <div className="art-metadata">
            <h2>{metadata.title}</h2>
            {metadata.year && <p><strong>Year:</strong> {metadata.year}</p>}
            {metadata.medium && <p><strong>Medium:</strong> {metadata.medium}</p>}
            {metadata.dimensions && <p><strong>Dimensions:</strong> {metadata.dimensions}</p>}
            {metadata.description && <p><strong>Description:</strong> {metadata.description}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtViewer;