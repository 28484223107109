import React, { useState, useEffect } from 'react';

const LAClock: React.FC = () => {
  const [time, setTime] = useState<string>('');

  useEffect(() => {
    // Function to update the time
    const updateTime = () => {
      const options: Intl.DateTimeFormatOptions = {
        timeZone: 'America/Los_Angeles',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      };
      
      const laTime = new Date().toLocaleTimeString('en-US', options);
      setTime(laTime);
    };

    // Update time immediately
    updateTime();
    
    // Set up interval to update time every second
    const intervalId = setInterval(updateTime, 1000);
    
    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="la-clock kos-border">
      <span className="clock-label">LA:</span> {time}
    </div>
  );
};

export default LAClock; 