import React, { useState, useEffect } from 'react';
import './ArtPortfolio.css';
import { artPortfolioData } from '../data/artPortfolioData';
import { useArtInfo } from '../contexts/ArtInfoContext';

// Define types for our art portfolio
export interface ArtImage {
  id: string;
  title: string;
  thumbnail: string;
  fullImage: string;
  category?: string;
  year?: string;
  medium?: string;
  dimensions?: string;
  description?: string;
}

export interface ArtCategory {
  id: string;
  name: string;
  images: ArtImage[];
}

interface ArtPortfolioProps {
  onImageSelect: (image: ArtImage) => void;
}

const ArtPortfolio: React.FC<ArtPortfolioProps> = ({ onImageSelect }) => {
  // Track a single active category
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  
  // Use the dynamically generated data or a fallback to hardcoded data
  const [categories, setCategories] = useState<ArtCategory[]>([]);
  
  // Add search filter state
  const [searchTerm, setSearchTerm] = useState('');
  
  // Add view type state (grid or list)
  const [viewType, setViewType] = useState<'grid' | 'list'>('grid');
  
  // Get art info display state from context
  const { showArtInfo } = useArtInfo();
  
  // Add class to parent window-content element to fix scrollbar issue
  useEffect(() => {
    // Find the parent window-content element
    const portfolioElement = document.querySelector('.art-portfolio');
    const windowContent = portfolioElement?.closest('.window-content');
    
    if (windowContent) {
      windowContent.classList.add('portfolio-window-content');
    }
    
    // Cleanup function to remove the class when component unmounts
    return () => {
      if (windowContent) {
        windowContent.classList.remove('portfolio-window-content');
      }
    };
  }, []);
  
  // Load the art data on component mount
  useEffect(() => {
    try {
      // If artPortfolioData exists and has categories, use it
      if (artPortfolioData && artPortfolioData.length > 0) {
        setCategories(artPortfolioData);
        // Auto-open the first category if only one exists
        if (artPortfolioData.length === 1) {
          setActiveCategory(artPortfolioData[0].id);
        }
      } else {
        console.warn('No art portfolio data found. Using default categories.');
        // Use the default hardcoded categories as fallback
        setCategories([
          {
            id: 'paintings',
            name: 'Paintings',
            images: [
              {
                id: 'painting1',
                title: 'Abstract Landscape',
                thumbnail: '/art/thumbnails/paintings/Abstract Landscape_2023_Oil on canvas_24x36_An abstract landscape inspired by desert formations.jpg',
                fullImage: '/art/fullsize/paintings/Abstract Landscape_2023_Oil on canvas_24x36_An abstract landscape inspired by desert formations.jpg',
                category: 'paintings',
                year: '2023',
                medium: 'Oil on canvas',
                dimensions: '24" x 36"',
                description: 'An abstract landscape inspired by desert formations'
              }
            ]
          }
        ]);
      }
    } catch (error) {
      console.error('Error loading art portfolio data:', error);
    }
  }, []);

  // Toggle category open/closed state
  const toggleCategory = (categoryId: string) => {
    // If the clicked category is already active, close it
    if (activeCategory === categoryId) {
      setActiveCategory(null);
    } else {
      // Otherwise, set it as the active category (which closes any other open category)
      setActiveCategory(categoryId);
    }
  };

  // Handle clicking on a thumbnail image
  const handleImageClick = (image: ArtImage) => {
    onImageSelect(image);
  };
  
  // Filter images based on search term
  const getFilteredCategories = () => {
    if (!searchTerm.trim()) {
      return categories;
    }
    
    const term = searchTerm.toLowerCase();
    
    return categories
      .map(category => {
        // Filter images in the category
        const filteredImages = category.images.filter(image => 
          image.title.toLowerCase().includes(term) ||
          (image.description && image.description.toLowerCase().includes(term)) ||
          (image.medium && image.medium.toLowerCase().includes(term)) ||
          (image.year && image.year.toLowerCase().includes(term))
        );
        
        // Only return categories with matching images
        return {
          ...category,
          images: filteredImages
        };
      })
      .filter(category => category.images.length > 0);
  };
  
  // Count total artwork after filtering
  const filteredCategories = getFilteredCategories();
  const totalArtworks = filteredCategories.reduce(
    (sum, category) => sum + category.images.length, 
    0
  );
  
  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    
    // Open all categories when searching
    if (e.target.value.trim()) {
      // If there's only one category with results, auto-select it
      const filtered = getFilteredCategories();
      if (filtered.length === 1) {
        setActiveCategory(filtered[0].id);
      }
    }
  };

  // Function to select a random image from all categories
  const selectRandomImage = () => {
    try {
      // Flatten all images from all categories
      const allImages: ArtImage[] = [];
      categories.forEach(category => {
        // Only add images that are likely to be valid
        category.images.forEach(image => {
          if (image && image.id && image.fullImage && image.thumbnail) {
            allImages.push(image);
          }
        });
      });
      
      if (allImages.length > 0) {
        // Select a random image
        const randomIndex = Math.floor(Math.random() * allImages.length);
        const randomImage = allImages[randomIndex];
        
        // Create an array of images to try in sequence if loading fails
        const imagesToTry = [
          randomImage,
          ...allImages.filter(img => img.id !== randomImage.id)
        ].slice(0, 5); // Limit to 5 attempts
        
        // Try to load the first image
        tryLoadingImage(imagesToTry, 0);
      }
    } catch (error) {
      console.error("Error selecting random image:", error);
    }
  };
  
  // Helper function to try loading images in sequence
  const tryLoadingImage = (images: ArtImage[], index: number) => {
    if (index >= images.length) {
      console.error("All random image attempts failed");
      return;
    }
    
    const image = images[index];
    const img = new Image();
    
    img.onload = () => {
      // Image loaded successfully, call the onImageSelect callback
      onImageSelect(image);
    };
    
    img.onerror = () => {
      console.error(`Failed to load random image attempt ${index + 1}/${images.length}:`, image.thumbnail);
      // Try the next image
      tryLoadingImage(images, index + 1);
    };
    
    // Start with thumbnail as it's smaller and quicker to validate
    img.src = image.thumbnail;
  };

  // Display loading state if no categories are loaded yet
  if (categories.length === 0) {
    return (
      <div className="art-portfolio kos-border-inset">
        <div className="portfolio-header kos-border">
          <h2>Loading Art Portfolio...</h2>
        </div>
        <div className="portfolio-content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="image-loading">
            <div className="loading-indicator"></div>
            <div>Loading artwork catalog...</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="art-portfolio kos-border-inset">
      <div className="portfolio-header kos-border">
        <h2>Portfolio</h2>
        <div className="portfolio-controls">
        <button 
              className="random-button kos-button kos-border"
              onClick={selectRandomImage}
              title="View random artwork"
            >
              ???
            </button>
          
          
          <div className="view-controls">
            
            <button 
              className={`view-button kos-button kos-border ${viewType === 'grid' ? 'active' : ''}`}
              onClick={() => setViewType('grid')}
              title="Grid view"
            >
              ▦
            </button>
            <button 
              className={`view-button kos-button kos-border ${viewType === 'list' ? 'active' : ''}`}
              onClick={() => setViewType('list')}
              title="List view"
            >
              ≡
            </button>
          </div>
        </div>
      </div>
      
      <div className="portfolio-content">
        {filteredCategories.length === 0 ? (
          <div className="no-results">
            <p>No artwork matches your search: "{searchTerm}"</p>
            <button 
              className="kos-button kos-border"
              onClick={() => setSearchTerm('')}
            >
              Clear Search
            </button>
          </div>
        ) : (
          filteredCategories.map(category => (
            <div key={category.id} className="art-category">
              <div 
                className={`category-header kos-border ${activeCategory === category.id ? 'open' : ''}`}
                onClick={() => toggleCategory(category.id)}
              >
                <div className="category-name">{category.name}</div>
                <div className="toggle-indicator">{activeCategory === category.id ? '▼' : '►'}</div>
              </div>
              
              {activeCategory === category.id && (
                <div className="category-images kos-border-inset">
                  <div className={`image-slider ${viewType === 'list' ? 'list-view' : ''}`}>
                    {category.images.map(image => (
                      <div 
                        key={image.id} 
                        className={`thumbnail-container kos-border ${viewType === 'list' ? 'list-item' : ''}`}
                        onClick={() => handleImageClick(image)}
                        title={showArtInfo ? image.title : ''}
                      >
                        <div className="image-thumbnail">
                          <img 
                            src={image.thumbnail} 
                            alt={showArtInfo ? image.title : ''}
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = '/placeholder-art.png';
                            }}
                          />
                        </div>
                        {showArtInfo && <div className="thumbnail-title">{image.title}</div>}
                        {viewType === 'list' && showArtInfo && (
                          <div className="list-item-details">
                            {image.year && <span className="list-detail">{image.year}</span>}
                            {image.medium && <span className="list-detail">{image.medium}</span>}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
      
      <div className="portfolio-footer kos-border">
        <span className="art-count">
          {searchTerm ? `${totalArtworks} matching artworks` : `Total Artworks: ${totalArtworks}`}
        </span>
        {searchTerm && (
          <span className="search-info">
            Searching: "{searchTerm}"
          </span>
        )}
      </div>
    </div>
  );
};

export default ArtPortfolio; 