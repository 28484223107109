import React, { useState, useEffect } from 'react';
import ArtPortfolio, { ArtImage, ArtCategory } from './ArtPortfolio';
import ArtViewer from './ArtViewer';
import './CombinedArtViewer.css';
import { artPortfolioData } from '../data/artPortfolioData';

interface CombinedArtViewerProps {
  isMaximized?: boolean;
}

const CombinedArtViewer: React.FC<CombinedArtViewerProps> = ({ isMaximized }) => {
  const [selectedImage, setSelectedImage] = useState<ArtImage | null>(null);

  // Select a random image when component mounts
  useEffect(() => {
    if (artPortfolioData && artPortfolioData.length > 0) {
      // Collect all images from all categories
      const allImages: ArtImage[] = [];
      artPortfolioData.forEach(category => {
        category.images.forEach(image => {
          if (image && image.id && image.fullImage && image.thumbnail) {
            allImages.push(image);
          }
        });
      });
      
      if (allImages.length > 0) {
        // Select a random image
        const randomIndex = Math.floor(Math.random() * allImages.length);
        setSelectedImage(allImages[randomIndex]);
      } else {
        // Fallback to first image if no valid ones found
        if (artPortfolioData[0].images.length > 0) {
          setSelectedImage(artPortfolioData[0].images[0]);
        }
      }
    }
  }, []);

  const handleImageSelect = (image: ArtImage) => {
    console.log('Image selected in CombinedArtViewer:', image);
    // Directly set the selected image
    setSelectedImage(image);
  };

  return (
    <div className="combined-art-viewer">
      <div className="portfolio-menu">
        <ArtPortfolio onImageSelect={handleImageSelect} />
      </div>
      <div className="artwork-display">
        <ArtViewer 
          selectedImage={selectedImage} 
          isMaximized={isMaximized}
          allCategories={artPortfolioData}
        />
      </div>
    </div>
  );
};

export default CombinedArtViewer; 