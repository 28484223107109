import React, { useState, useEffect, useRef, DragEvent } from 'react';
import './AdminPanel.css';

interface SourceImage {
  path: string;
  filename: string;
  category: string;
  previewUrl: string;
}

interface ImageMetadata {
  title: string;
  year: string;
  medium: string;
  dimensions: string;
  description: string;
}

interface PanelSizes {
  left: number;
  center: number;
  right: number;
}

const AdminPanel: React.FC = () => {
  // State for all source images
  const [sourceImages, setSourceImages] = useState<SourceImage[]>([]);
  
  // State for currently selected image
  const [selectedImage, setSelectedImage] = useState<SourceImage | null>(null);
  
  // State for metadata form
  const [metadata, setMetadata] = useState<ImageMetadata>({
    title: '',
    year: '',
    medium: '',
    dimensions: '',
    description: ''
  });
  
  // State for loading status
  const [loading, setLoading] = useState<boolean>(true);
  
  // State for messages
  const [message, setMessage] = useState<{text: string, type: 'success' | 'error' | 'info'} | null>(null);
  
  // State for panel resizing
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [activeResizer, setActiveResizer] = useState<'left' | 'right' | null>(null);
  const [panelSizes, setPanelSizes] = useState<PanelSizes>({
    left: 30, // percentage
    center: 40,
    right: 30
  });
  
  // State for drag and drop
  const [draggedImage, setDraggedImage] = useState<SourceImage | null>(null);
  const [dragOverCategory, setDragOverCategory] = useState<string | null>(null);
  
  // Refs
  const adminContentRef = useRef<HTMLDivElement>(null);
  const leftPanelRef = useRef<HTMLDivElement>(null);
  const centerPanelRef = useRef<HTMLDivElement>(null);
  const rightPanelRef = useRef<HTMLDivElement>(null);
  
  // Fetch source images on component mount
  useEffect(() => {
    fetchSourceImages();
  }, []);
  
  // Set up event listeners for resizing
  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
    
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isResizing, activeResizer]);
  
  // Add/remove resizing class to body
  useEffect(() => {
    if (isResizing) {
      document.body.classList.add('resizing-active');
    } else {
      document.body.classList.remove('resizing-active');
    }
  }, [isResizing]);
  
  // Fetch all source images from the server
  const fetchSourceImages = async () => {
    setLoading(true);
    setMessage({ text: 'Loading source images...', type: 'info' });
    
    try {
      // Try to fetch real images from the public directory
      await fetchRealSourceImages();
    } catch (error) {
      setMessage({ text: 'Error loading source images, using mock data', type: 'error' });
      // Fall back to mock data if real images can't be loaded
      await simulateFetchSourceImages();
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch real images from the public/art/source directory
  const fetchRealSourceImages = async () => {
    // Use fetch to get a directory listing via a simple approach
    try {
      // Try to load a manifest file that lists available images
      // This is a simplification - in a real app, you'd have a proper API endpoint
      const manifestResponse = await fetch('/art/source-manifest.json');
      
      // If we can't find the manifest, throw an error to fall back to mock data
      if (!manifestResponse.ok) {
        throw new Error('Could not load source manifest');
      }
      
      const manifest = await manifestResponse.json();
      const sourceImages: SourceImage[] = [];
      
      // Process each category and image from the manifest
      for (const category of Object.keys(manifest)) {
        const images = manifest[category];
        
        for (const imageName of images) {
          sourceImages.push({
            path: `/art/source/${category}/${imageName}`,
            filename: imageName,
            category,
            previewUrl: `/art/source/${category}/${imageName}`
          });
        }
      }
      
      if (sourceImages.length === 0) {
        throw new Error('No images found in source directory');
      }
      
      setSourceImages(sourceImages);
      if (sourceImages.length > 0) {
        setSelectedImage(sourceImages[0]);
        parseFilenameToMetadata(sourceImages[0].filename);
      }
      
      setMessage({ text: `Loaded ${sourceImages.length} images from source directory`, type: 'success' });
    } catch (error) {
      console.error('Error loading real source images:', error);
      
      // Try direct directory scan
      // This is a fallback for development where we can try to load images directly
      try {
        const categories = ['paintings', 'digital', 'sketches'];
        const realImages: SourceImage[] = [];
        
        // For each category, try to load a few test images and see if they exist
        for (const category of categories) {
          // Try to list all files in the category directory by testing common names
          for (let i = 1; i <= 30; i++) { // Increased from 10 to 30
            // Try common image extensions
            for (const ext of ['jpg', 'jpeg', 'png', 'gif', 'webp']) {
              const imageName = `image${i}.${ext}`;
              const imagePath = `/art/source/${category}/${imageName}`;
              
              // Check if this file exists by trying to load it
              try {
                const response = await fetch(imagePath, { method: 'HEAD' });
                if (response.ok) {
                  realImages.push({
                    path: imagePath,
                    filename: imageName, // This will be the actual filename
                    category,
                    previewUrl: imagePath
                  });
                }
              } catch {
                // Ignore errors for individual files
                continue;
              }
            }
            
            // Also try formatted filenames in the format Title_Year_Medium.ext
            const testNames = [
              `Artwork${i}_2023_MixedMedia.jpg`,
              `Painting${i}_2023_Oil.jpg`,
              `Drawing${i}_2023_Pencil.jpg`
            ];
            
            for (const testName of testNames) {
              const imagePath = `/art/source/${category}/${testName}`;
              
              try {
                const response = await fetch(imagePath, { method: 'HEAD' });
                if (response.ok) {
                  realImages.push({
                    path: imagePath,
                    filename: testName,
                    category,
                    previewUrl: imagePath
                  });
                }
              } catch {
                // Ignore errors
                continue;
              }
            }
          }
        }
        
        if (realImages.length > 0) {
          setSourceImages(realImages);
          setSelectedImage(realImages[0]);
          parseFilenameToMetadata(realImages[0].filename);
          setMessage({ text: `Found ${realImages.length} images in source folders`, type: 'success' });
          return;
        } else {
          throw new Error('No images found using direct scan');
        }
      } catch (scanError) {
        console.error('Error scanning for images:', scanError);
        throw error; // Re-throw to trigger mock data fallback
      }
    }
  };
  
  // Simulate fetching source images (this would be replaced with a real API call)
  const simulateFetchSourceImages = async () => {
    // Simulate network delay
    await new Promise(resolve => setTimeout(resolve, 500));
    
    // These would come from the server
    const categories = ['paintings', 'digital', 'sketches'];
    const mockImages: SourceImage[] = [];
    
    // Generate some mock images for each category
    categories.forEach(category => {
      for (let i = 1; i <= 5; i++) {
        mockImages.push({
          path: `/art/source/${category}/image${i}.jpg`,
          filename: `image${i}.jpg`,
          category,
          previewUrl: `/placeholder-art.png`
        });
      }
    });
    
    setSourceImages(mockImages);
    if (mockImages.length > 0) {
      setSelectedImage(mockImages[0]);
      parseFilenameToMetadata(mockImages[0].filename);
    }
  };
  
  // Parse filename to extract metadata based on our naming convention
  const parseFilenameToMetadata = (filename: string) => {
    const nameWithoutExt = filename.replace(/\.\w+$/, '');
    
    if (nameWithoutExt.includes('_')) {
      const parts = nameWithoutExt.split('_');
      
      setMetadata({
        title: parts[0] || '',
        year: parts.length > 1 ? parts[1] : '',
        medium: parts.length > 2 ? parts[2] : '',
        dimensions: parts.length > 3 ? parts[3] : '',
        description: parts.length > 4 ? parts.slice(4).join('_') : ''
      });
    } else {
      // If the filename doesn't follow the convention, just use it as the title
      setMetadata({
        title: nameWithoutExt,
        year: '',
        medium: '',
        dimensions: '',
        description: ''
      });
    }
  };
  
  // Generate filename from metadata
  const generateFilename = () => {
    if (!metadata.title) return '';
    
    const parts = [
      metadata.title,
      metadata.year,
      metadata.medium,
      metadata.dimensions,
      metadata.description
    ].filter(Boolean);
    
    return parts.join('_') + '.jpg';
  };
  
  // Handle image selection
  const handleSelectImage = (image: SourceImage) => {
    setSelectedImage(image);
    parseFilenameToMetadata(image.filename);
  };
  
  // Handle form input changes
  const handleInputChange = (field: keyof ImageMetadata, value: string) => {
    setMetadata(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  // Handle applying metadata to the selected image
  const handleApplyMetadata = async () => {
    if (!selectedImage) return;
    
    const newFilename = generateFilename();
    if (!newFilename) {
      setMessage({ text: 'Title is required', type: 'error' });
      return;
    }
    
    setMessage({ text: 'Updating metadata...', type: 'info' });
    
    try {
      // In a real implementation, this would be an API call to the server
      // For now, we'll simulate a response
      await simulateApplyMetadata(selectedImage, newFilename);
      
      setMessage({ text: 'Metadata updated successfully', type: 'success' });
      
      // Update the local state to reflect the change
      const updatedSourceImages = sourceImages.map(img => 
        img === selectedImage 
          ? { ...img, filename: newFilename } 
          : img
      );
      
      setSourceImages(updatedSourceImages);
      setSelectedImage({ ...selectedImage, filename: newFilename });
    } catch (error) {
      setMessage({ text: 'Error updating metadata', type: 'error' });
    }
  };
  
  // Simulate applying metadata (this would be replaced with a real API call)
  const simulateApplyMetadata = async (image: SourceImage, newFilename: string) => {
    // Simulate network delay
    await new Promise(resolve => setTimeout(resolve, 500));
    
    // In a real implementation, this would rename the file and update the database
    console.log(`Renamed ${image.path} to ${newFilename}`);
    
    // Return success
    return true;
  };
  
  // Handle processing the selected image
  const handleProcessImage = async () => {
    if (!selectedImage) return;
    
    setMessage({ text: 'Processing image...', type: 'info' });
    
    try {
      // In a real implementation, this would be an API call to the server
      // For now, we'll simulate a response
      await simulateProcessImage(selectedImage);
      
      setMessage({ text: 'Image processed successfully', type: 'success' });
    } catch (error) {
      setMessage({ text: 'Error processing image', type: 'error' });
    }
  };
  
  // Simulate processing an image (this would be replaced with a real API call)
  const simulateProcessImage = async (image: SourceImage) => {
    // Simulate network delay
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // In a real implementation, this would process the image with Sharp
    console.log(`Processed ${image.path}`);
    
    // Return success
    return true;
  };
  
  // Handle start of resizing
  const handleResizerMouseDown = (resizer: 'left' | 'right') => (e: React.MouseEvent) => {
    e.preventDefault();
    setIsResizing(true);
    setActiveResizer(resizer);
  };
  
  // Handle mouse move during resize
  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizing || !adminContentRef.current) return;
    
    const container = adminContentRef.current.getBoundingClientRect();
    const containerWidth = container.width;
    const mouseX = e.clientX - container.left;
    
    // Calculate percentage position
    const positionPercentage = (mouseX / containerWidth) * 100;
    
    updatePanelSizes(positionPercentage);
  };
  
  // Handle touch move during resize
  const handleTouchMove = (e: TouchEvent) => {
    if (!isResizing || !adminContentRef.current || !e.touches[0]) return;
    
    const container = adminContentRef.current.getBoundingClientRect();
    const containerWidth = container.width;
    const touchX = e.touches[0].clientX - container.left;
    
    // Calculate percentage position
    const positionPercentage = (touchX / containerWidth) * 100;
    
    updatePanelSizes(positionPercentage);
  };
  
  // Update panel sizes based on resizer position
  const updatePanelSizes = (positionPercentage: number) => {
    // Ensure position is within reasonable bounds (5-95%)
    const boundedPosition = Math.max(5, Math.min(95, positionPercentage));
    
    if (activeResizer === 'left') {
      // Left resizer affects left and center panels
      // Ensure minimum size for center panel (15%)
      const maxLeftSize = 85;
      const leftSize = Math.min(boundedPosition, maxLeftSize);
      
      // Calculate remaining space and allocate proportionally
      const remaining = 100 - leftSize;
      const rightProportion = panelSizes.right / (panelSizes.center + panelSizes.right);
      const centerSize = remaining * (1 - rightProportion);
      const rightSize = remaining * rightProportion;
      
      setPanelSizes({
        left: leftSize,
        center: centerSize,
        right: rightSize
      });
    } else if (activeResizer === 'right') {
      // Right resizer position is relative to left panel + center panel
      // Calculate where the right panel should start
      const rightPanelStart = boundedPosition;
      
      // Ensure minimum size for center panel (15%)
      const minCenterSize = 15;
      const maxRightPanelStart = 100 - minCenterSize;
      
      const adjustedRightPanelStart = Math.min(rightPanelStart, maxRightPanelStart);
      
      // Adjust the center panel size proportionally
      const leftProportion = panelSizes.left / (panelSizes.left + panelSizes.center);
      const totalLeftCenter = adjustedRightPanelStart;
      const leftSize = totalLeftCenter * leftProportion;
      const centerSize = totalLeftCenter * (1 - leftProportion);
      const rightSize = 100 - adjustedRightPanelStart;
      
      setPanelSizes({
        left: leftSize,
        center: centerSize,
        right: rightSize
      });
    }
  };
  
  // Handle end of resizing
  const handleMouseUp = () => {
    if (isResizing) {
      setIsResizing(false);
      setActiveResizer(null);
    }
  };
  
  // Handle touch end
  const handleTouchEnd = () => {
    if (isResizing) {
      setIsResizing(false);
      setActiveResizer(null);
    }
  };
  
  // Handle drag start
  const handleDragStart = (e: DragEvent<HTMLDivElement>, image: SourceImage) => {
    setDraggedImage(image);
    e.dataTransfer.setData('text/plain', image.path);
    e.dataTransfer.effectAllowed = 'move';
  };
  
  // Handle drag over category
  const handleDragOver = (e: DragEvent<HTMLDivElement>, category: string) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    setDragOverCategory(category);
  };
  
  // Handle drag leave
  const handleDragLeave = () => {
    setDragOverCategory(null);
  };
  
  // Handle drop on category
  const handleDrop = async (e: DragEvent<HTMLDivElement>, targetCategory: string) => {
    e.preventDefault();
    setDragOverCategory(null);

    if (!draggedImage || draggedImage.category === targetCategory) return;

    setMessage({ text: 'Moving file...', type: 'info' });

    try {
      // Call the server endpoint to move the file
      const response = await fetch('/api/admin/move-file', {  // Updated endpoint path
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sourceCategory: draggedImage.category,
          targetCategory: targetCategory,
          filename: draggedImage.filename
        }),
      });

      let data;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
      } else {
        throw new Error('Server returned non-JSON response');
      }

      if (!response.ok) {
        throw new Error(data.message || data.error || 'Failed to move file');
      }

      // Update local state
      const newPath = `/art/source/${targetCategory}/${draggedImage.filename}`;
      const updatedSourceImages = sourceImages.map(img =>
        img === draggedImage
          ? { ...img, category: targetCategory, path: newPath, previewUrl: newPath }
          : img
      );

      setSourceImages(updatedSourceImages);
      if (selectedImage === draggedImage) {
        setSelectedImage({ ...draggedImage, category: targetCategory, path: newPath, previewUrl: newPath });
      }

      setMessage({ text: 'File moved successfully', type: 'success' });
    } catch (error) {
      console.error('Error moving file:', error);
      setMessage({ 
        text: error instanceof Error ? error.message : 'Error moving file', 
        type: 'error' 
      });
    }

    setDraggedImage(null);
  };
  
  // Render the component
  return (
    <div className="admin-panel kos-border-inset">
      <div className="admin-header kos-border">
        <h2>KOOLSKULL Admin Panel</h2>
        {message && (
          <div className={`admin-message ${message.type}`}>
            {message.text}
          </div>
        )}
      </div>
      
      <div className="admin-content" ref={adminContentRef}>
        {/* Left sidebar - Source images */}
        <div 
          className="source-images-panel kos-border-inset" 
          ref={leftPanelRef}
          style={{ flex: `${panelSizes.left} 0 0%` }}
        >
          <div className="panel-header kos-border">
            <h3>Source Images</h3>
            <button 
              className="refresh-button kos-button kos-border"
              onClick={fetchSourceImages}
              disabled={loading}
            >
              Refresh
            </button>
          </div>
          
          <div className="image-categories">
            {Array.from(new Set(sourceImages.map(img => img.category))).map(category => (
              <div 
                key={category} 
                className={`image-category ${dragOverCategory === category ? 'drag-over' : ''}`}
                onDragOver={(e) => handleDragOver(e, category)}
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, category)}
              >
                <h4 className="category-header kos-border">{category}</h4>
                <div className="image-grid">
                  {sourceImages
                    .filter(img => img.category === category)
                    .map(image => (
                      <div 
                        key={image.path}
                        className={`image-thumbnail kos-border ${selectedImage === image ? 'selected' : ''}`}
                        onClick={() => handleSelectImage(image)}
                        draggable
                        onDragStart={(e) => handleDragStart(e, image)}
                        title={image.filename}
                      >
                        <img 
                          src={image.previewUrl} 
                          alt={image.filename}
                          onError={(e) => {
                            (e.target as HTMLImageElement).src = '/placeholder-art.png';
                          }}
                        />
                        <div className="image-name">{image.filename}</div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))}
            
            {sourceImages.length === 0 && !loading && (
              <div className="no-images kos-border-inset">
                <p>No source images found.</p>
                <p>Please add images to the source folders:</p>
                <ul>
                  <li>/public/art/source/paintings/</li>
                  <li>/public/art/source/digital/</li>
                  <li>/public/art/source/sketches/</li>
                </ul>
                <button 
                  className="kos-button kos-border"
                  onClick={() => {
                    // Run the scan script
                    setMessage({ text: 'Generating source manifest...', type: 'info' });
                    // In a real implementation you'd call an API endpoint
                    setTimeout(() => {
                      fetchSourceImages();
                    }, 1000);
                  }}
                >
                  Generate Manifest
                </button>
              </div>
            )}
            
            {loading && (
              <div className="loading-images">
                <div className="loading-spinner"></div>
                <p>Loading images...</p>
              </div>
            )}
          </div>
        </div>
        
        {/* Left resizer */}
        <div 
          className={`panel-resizer ${activeResizer === 'left' ? 'resizing' : ''}`}
          onMouseDown={handleResizerMouseDown('left')}
          onTouchStart={() => {
            setIsResizing(true);
            setActiveResizer('left');
          }}
        />
        
        {/* Center panel - Selected image preview */}
        <div 
          className="image-preview-panel kos-border-inset"
          ref={centerPanelRef}
          style={{ flex: `${panelSizes.center} 0 0%` }}
        >
          <div className="panel-header kos-border">
            <h3>Image Preview</h3>
          </div>
          
          <div className="image-preview-container">
            {selectedImage ? (
              <>
                <div className="image-preview kos-border-inset">
                  <img src={selectedImage.previewUrl} alt={selectedImage.filename} />
                </div>
                <div className="image-info">
                  <div><strong>Category:</strong> {selectedImage.category}</div>
                  <div><strong>Path:</strong> {selectedImage.path}</div>
                  <div><strong>Current Filename:</strong> {selectedImage.filename}</div>
                </div>
              </>
            ) : (
              <div className="no-image-selected">
                <p>No image selected</p>
                <p>Please select an image from the left panel</p>
              </div>
            )}
          </div>
        </div>
        
        {/* Right resizer */}
        <div 
          className={`panel-resizer ${activeResizer === 'right' ? 'resizing' : ''}`}
          onMouseDown={handleResizerMouseDown('right')}
          onTouchStart={() => {
            setIsResizing(true);
            setActiveResizer('right');
          }}
        />
        
        {/* Right panel - Metadata form */}
        <div 
          className="metadata-form-panel kos-border-inset"
          ref={rightPanelRef}
          style={{ flex: `${panelSizes.right} 0 0%` }}
        >
          <div className="panel-header kos-border">
            <h3>Image Metadata</h3>
          </div>
          
          <div className="metadata-form">
            {selectedImage ? (
              <>
                <div className="form-field">
                  <label htmlFor="title">Title:</label>
                  <input
                    id="title"
                    type="text"
                    className="kos-border-inset"
                    value={metadata.title}
                    onChange={(e) => handleInputChange('title', e.target.value)}
                    placeholder="e.g. Abstract Landscape"
                  />
                </div>
                
                <div className="form-field">
                  <label htmlFor="year">Year:</label>
                  <input
                    id="year"
                    type="text"
                    className="kos-border-inset"
                    value={metadata.year}
                    onChange={(e) => handleInputChange('year', e.target.value)}
                    placeholder="e.g. 2023"
                  />
                </div>
                
                <div className="form-field">
                  <label htmlFor="medium">Medium:</label>
                  <input
                    id="medium"
                    type="text"
                    className="kos-border-inset"
                    value={metadata.medium}
                    onChange={(e) => handleInputChange('medium', e.target.value)}
                    placeholder="e.g. Oil on Canvas"
                  />
                </div>
                
                <div className="form-field">
                  <label htmlFor="dimensions">Dimensions:</label>
                  <input
                    id="dimensions"
                    type="text"
                    className="kos-border-inset"
                    value={metadata.dimensions}
                    onChange={(e) => handleInputChange('dimensions', e.target.value)}
                    placeholder="e.g. 24x36"
                  />
                </div>
                
                <div className="form-field">
                  <label htmlFor="description">Description:</label>
                  <textarea
                    id="description"
                    className="kos-border-inset"
                    value={metadata.description}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                    placeholder="e.g. An abstract landscape inspired by desert formations"
                    rows={3}
                  />
                </div>
                
                <div className="form-preview">
                  <strong>Generated Filename:</strong>
                  <div className="filename-preview kos-border-inset">
                    {generateFilename() || '[Title required]'}
                  </div>
                </div>
                
                <div className="form-actions">
                  <button 
                    className="apply-button kos-button kos-border"
                    onClick={handleApplyMetadata}
                    disabled={!metadata.title}
                  >
                    Apply Metadata
                  </button>
                  
                  <button 
                    className="process-button kos-button kos-border"
                    onClick={handleProcessImage}
                  >
                    Process Image
                  </button>
                </div>
              </>
            ) : (
              <div className="no-image-selected">
                <p>No image selected</p>
                <p>Please select an image from the left panel</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel; 