// Auto-generated by update-art-portfolio.js
// Last updated: 2025-03-20T09:11:40.832Z

import { ArtCategory } from '../components/ArtPortfolio';

export const artPortfolioData: ArtCategory[] = [
  {
    "id": "3d-stills",
    "name": "3D Stills",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/3D Stills/IMG_0871.jpg",
        "fullImage": "/art/fullsize/3D Stills/IMG_0871.jpg",
        "year": "0871",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/3D Stills/IMG_0873.jpg",
        "fullImage": "/art/fullsize/3D Stills/IMG_0873.jpg",
        "year": "0873",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/3D Stills/IMG_1855.jpg",
        "fullImage": "/art/fullsize/3D Stills/IMG_1855.jpg",
        "year": "1855",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/3D Stills/IMG_1858.jpg",
        "fullImage": "/art/fullsize/3D Stills/IMG_1858.jpg",
        "year": "1858",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/3D Stills/IMG_1860.jpg",
        "fullImage": "/art/fullsize/3D Stills/IMG_1860.jpg",
        "year": "1860",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/3D Stills/IMG_1864.jpg",
        "fullImage": "/art/fullsize/3D Stills/IMG_1864.jpg",
        "year": "1864",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/3D Stills/IMG_4202.jpg",
        "fullImage": "/art/fullsize/3D Stills/IMG_4202.jpg",
        "year": "4202",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "screen-shot-2022-09-02-at-2-13-33-pm",
        "title": "Screen Shot 2022-09-02 at 2.13.33 PM",
        "thumbnail": "/art/thumbnails/3D Stills/Screen Shot 2022-09-02 at 2.13.33 PM.jpg",
        "fullImage": "/art/fullsize/3D Stills/Screen Shot 2022-09-02 at 2.13.33 PM.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "album-art",
    "name": "Album Art",
    "images": [
      {
        "id": "3757868b-8355-442e-af6e-accb8d6309d2",
        "title": "3757868B-8355-442E-AF6E-ACCB8D6309D2",
        "thumbnail": "/art/thumbnails/Album Art/3757868B-8355-442E-AF6E-ACCB8D6309D2.jpg",
        "fullImage": "/art/fullsize/Album Art/3757868B-8355-442E-AF6E-ACCB8D6309D2.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "82411f77-45c7-434b-b36f-bb3ca5378fbf",
        "title": "82411F77-45C7-434B-B36F-BB3CA5378FBF",
        "thumbnail": "/art/thumbnails/Album Art/82411F77-45C7-434B-B36F-BB3CA5378FBF.jpg",
        "fullImage": "/art/fullsize/Album Art/82411F77-45C7-434B-B36F-BB3CA5378FBF.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "89d85af6-25e4-4d92-ab4f-9459ae9af1d2",
        "title": "89D85AF6-25E4-4D92-AB4F-9459AE9AF1D2",
        "thumbnail": "/art/thumbnails/Album Art/89D85AF6-25E4-4D92-AB4F-9459AE9AF1D2.jpg",
        "fullImage": "/art/fullsize/Album Art/89D85AF6-25E4-4D92-AB4F-9459AE9AF1D2.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_0174.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_0174.jpg",
        "year": "0174",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_0338.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_0338.jpg",
        "year": "0338",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_0635.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_0635.jpg",
        "year": "0635",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_0654.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_0654.jpg",
        "year": "0654",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_0868 (1).jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_0868 (1).jpg",
        "year": "0868 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_0957.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_0957.jpg",
        "year": "0957",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_1330.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_1330.jpg",
        "year": "1330",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_1368.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_1368.jpg",
        "year": "1368",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_1399.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_1399.jpg",
        "year": "1399",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_1799.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_1799.jpg",
        "year": "1799",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_1850.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_1850.jpg",
        "year": "1850",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_1886.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_1886.jpg",
        "year": "1886",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_2112.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_2112.jpg",
        "year": "2112",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_2145.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_2145.jpg",
        "year": "2145",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_2522.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_2522.jpg",
        "year": "2522",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_2800.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_2800.jpg",
        "year": "2800",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_3197.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_3197.jpg",
        "year": "3197",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_3241.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_3241.jpg",
        "year": "3241",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_3835.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_3835.jpg",
        "year": "3835",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_3836 (2).jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_3836 (2).jpg",
        "year": "3836 (2)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_4213.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_4213.jpg",
        "year": "4213",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_4681.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_4681.jpg",
        "year": "4681",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_4800.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_4800.jpg",
        "year": "4800",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_5113.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_5113.jpg",
        "year": "5113",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_5741.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_5741.jpg",
        "year": "5741",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_6076.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_6076.jpg",
        "year": "6076",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_7506.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_7506.jpg",
        "year": "7506",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_7574.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_7574.jpg",
        "year": "7574",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_7737.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_7737.jpg",
        "year": "7737",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Album Art/IMG_8780.jpg",
        "fullImage": "/art/fullsize/Album Art/IMG_8780.jpg",
        "year": "8780",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "josie-cotton",
        "title": "josie cotton",
        "thumbnail": "/art/thumbnails/Album Art/josie cotton.jpg",
        "fullImage": "/art/fullsize/Album Art/josie cotton.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "left-overs-food-plate",
        "title": "Left Overs Food Plate",
        "thumbnail": "/art/thumbnails/Album Art/Left Overs Food Plate_2017_Procreate_drawn for the band the Leftovers.jpg",
        "fullImage": "/art/fullsize/Album Art/Left Overs Food Plate_2017_Procreate_drawn for the band the Leftovers.jpg",
        "year": "2017",
        "medium": "Procreate",
        "dimensions": "drawn for the band the Leftovers",
        "description": ""
      },
      {
        "id": "mialien-backgroundzzzz",
        "title": "mialien backgroundzzzz",
        "thumbnail": "/art/thumbnails/Album Art/mialien backgroundzzzz.jpg",
        "fullImage": "/art/fullsize/Album Art/mialien backgroundzzzz.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "shitbird",
        "title": "Shitbird",
        "thumbnail": "/art/thumbnails/Album Art/Shitbird_2015_2400x2400px_Digital Painting_Drawn for Shitbird album art that never came out.jpg",
        "fullImage": "/art/fullsize/Album Art/Shitbird_2015_2400x2400px_Digital Painting_Drawn for Shitbird album art that never came out.jpg",
        "year": "2015",
        "medium": "2400x2400px",
        "dimensions": "Digital Painting",
        "description": "Drawn for Shitbird album art that never came out"
      }
    ]
  },
  {
    "id": "baby-shower-designs",
    "name": "Baby Shower Designs",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Baby Shower Designs/IMG_4235.jpg",
        "fullImage": "/art/fullsize/Baby Shower Designs/IMG_4235.jpg",
        "year": "4235",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Baby Shower Designs/IMG_4237.jpg",
        "fullImage": "/art/fullsize/Baby Shower Designs/IMG_4237.jpg",
        "year": "4237",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Baby Shower Designs/IMG_4520.jpg",
        "fullImage": "/art/fullsize/Baby Shower Designs/IMG_4520.jpg",
        "year": "4520",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Baby Shower Designs/IMG_4544.jpg",
        "fullImage": "/art/fullsize/Baby Shower Designs/IMG_4544.jpg",
        "year": "4544",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Baby Shower Designs/IMG_4546.jpg",
        "fullImage": "/art/fullsize/Baby Shower Designs/IMG_4546.jpg",
        "year": "4546",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Baby Shower Designs/IMG_4573.jpg",
        "fullImage": "/art/fullsize/Baby Shower Designs/IMG_4573.jpg",
        "year": "4573",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Baby Shower Designs/IMG_9019.jpg",
        "fullImage": "/art/fullsize/Baby Shower Designs/IMG_9019.jpg",
        "year": "9019",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "background-painting",
    "name": "Background Painting",
    "images": [
      {
        "id": "image",
        "title": "image",
        "thumbnail": "/art/thumbnails/Background Painting/image.jpg",
        "fullImage": "/art/fullsize/Background Painting/image.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0145.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0145.jpg",
        "year": "0145",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0189.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0189.jpg",
        "year": "0189",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0272.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0272.jpg",
        "year": "0272",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0334.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0334.jpg",
        "year": "0334",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0395.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0395.jpg",
        "year": "0395",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0426.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0426.jpg",
        "year": "0426",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0456.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0456.jpg",
        "year": "0456",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0476.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0476.jpg",
        "year": "0476",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0523.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0523.jpg",
        "year": "0523",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0590.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0590.jpg",
        "year": "0590",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0598.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0598.jpg",
        "year": "0598",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0617.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0617.jpg",
        "year": "0617",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0712.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0712.jpg",
        "year": "0712",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0758.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0758.jpg",
        "year": "0758",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0791.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0791.jpg",
        "year": "0791",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0836.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0836.jpg",
        "year": "0836",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0906.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0906.jpg",
        "year": "0906",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0941.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0941.jpg",
        "year": "0941",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_0945.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_0945.jpg",
        "year": "0945",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1026.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1026.jpg",
        "year": "1026",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1077.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1077.jpg",
        "year": "1077",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1080.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1080.jpg",
        "year": "1080",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1084.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1084.jpg",
        "year": "1084",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1086.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1086.jpg",
        "year": "1086",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1088.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1088.jpg",
        "year": "1088",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1112.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1112.jpg",
        "year": "1112",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1145.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1145.jpg",
        "year": "1145",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1176.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1176.jpg",
        "year": "1176",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1208.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1208.jpg",
        "year": "1208",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1210.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1210.jpg",
        "year": "1210",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1349.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1349.jpg",
        "year": "1349",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1638.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1638.jpg",
        "year": "1638",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_1781.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_1781.jpg",
        "year": "1781",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_2412.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_2412.jpg",
        "year": "2412",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_2413.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_2413.jpg",
        "year": "2413",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_2579.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_2579.jpg",
        "year": "2579",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_2721.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_2721.jpg",
        "year": "2721",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3119.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3119.jpg",
        "year": "3119",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3128.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3128.jpg",
        "year": "3128",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3162.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3162.jpg",
        "year": "3162",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3192.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3192.jpg",
        "year": "3192",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3363.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3363.jpg",
        "year": "3363",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3364.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3364.jpg",
        "year": "3364",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3365.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3365.jpg",
        "year": "3365",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3366.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3366.jpg",
        "year": "3366",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3368.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3368.jpg",
        "year": "3368",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3370.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3370.jpg",
        "year": "3370",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3384.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3384.jpg",
        "year": "3384",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3399.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3399.jpg",
        "year": "3399",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3419.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3419.jpg",
        "year": "3419",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3422.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3422.jpg",
        "year": "3422",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3434.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3434.jpg",
        "year": "3434",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3477.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3477.jpg",
        "year": "3477",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3499.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3499.jpg",
        "year": "3499",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3837.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3837.jpg",
        "year": "3837",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3850.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3850.jpg",
        "year": "3850",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3931.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3931.jpg",
        "year": "3931",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_3968.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_3968.jpg",
        "year": "3968",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4017.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4017.jpg",
        "year": "4017",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4106.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4106.jpg",
        "year": "4106",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4430.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4430.jpg",
        "year": "4430",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4431.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4431.jpg",
        "year": "4431",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4438.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4438.jpg",
        "year": "4438",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4439.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4439.jpg",
        "year": "4439",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4491.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4491.jpg",
        "year": "4491",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4492.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4492.jpg",
        "year": "4492",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4500.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4500.jpg",
        "year": "4500",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4508.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4508.jpg",
        "year": "4508",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4510.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4510.jpg",
        "year": "4510",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4511.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4511.jpg",
        "year": "4511",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4557.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4557.jpg",
        "year": "4557",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4558.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4558.jpg",
        "year": "4558",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4589.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4589.jpg",
        "year": "4589",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4590.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4590.jpg",
        "year": "4590",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4595.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4595.jpg",
        "year": "4595",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4597.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4597.jpg",
        "year": "4597",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4637.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4637.jpg",
        "year": "4637",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4638.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4638.jpg",
        "year": "4638",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4646.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4646.jpg",
        "year": "4646",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4685.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4685.jpg",
        "year": "4685",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4793.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4793.jpg",
        "year": "4793",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4814.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4814.jpg",
        "year": "4814",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4831.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4831.jpg",
        "year": "4831",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4841.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4841.jpg",
        "year": "4841",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_4916.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_4916.jpg",
        "year": "4916",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5003.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5003.jpg",
        "year": "5003",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5192.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5192.jpg",
        "year": "5192",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5193.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5193.jpg",
        "year": "5193",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5199.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5199.jpg",
        "year": "5199",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5206.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5206.jpg",
        "year": "5206",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5495.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5495.jpg",
        "year": "5495",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5536.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5536.jpg",
        "year": "5536",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5549.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5549.jpg",
        "year": "5549",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5550.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5550.jpg",
        "year": "5550",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5556.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5556.jpg",
        "year": "5556",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5601.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5601.jpg",
        "year": "5601",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5611.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5611.jpg",
        "year": "5611",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5620.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5620.jpg",
        "year": "5620",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5658.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5658.jpg",
        "year": "5658",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5666.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5666.jpg",
        "year": "5666",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5677.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5677.jpg",
        "year": "5677",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5756.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5756.jpg",
        "year": "5756",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5780.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5780.jpg",
        "year": "5780",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5787.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5787.jpg",
        "year": "5787",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5789.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5789.jpg",
        "year": "5789",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5794.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5794.jpg",
        "year": "5794",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5819.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5819.jpg",
        "year": "5819",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_5827.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_5827.jpg",
        "year": "5827",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6019.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6019.jpg",
        "year": "6019",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6034.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6034.jpg",
        "year": "6034",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6243.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6243.jpg",
        "year": "6243",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6272.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6272.jpg",
        "year": "6272",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6367.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6367.jpg",
        "year": "6367",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6377.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6377.jpg",
        "year": "6377",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6395.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6395.jpg",
        "year": "6395",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6417.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6417.jpg",
        "year": "6417",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6420.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6420.jpg",
        "year": "6420",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6624.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6624.jpg",
        "year": "6624",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6764.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6764.jpg",
        "year": "6764",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6766.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6766.jpg",
        "year": "6766",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6767.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6767.jpg",
        "year": "6767",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6768.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6768.jpg",
        "year": "6768",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6815.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6815.jpg",
        "year": "6815",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6818.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6818.jpg",
        "year": "6818",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6823.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6823.jpg",
        "year": "6823",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6922.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6922.jpg",
        "year": "6922",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_6923.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_6923.jpg",
        "year": "6923",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_7060.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_7060.jpg",
        "year": "7060",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_7097.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_7097.jpg",
        "year": "7097",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_7109.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_7109.jpg",
        "year": "7109",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_7112.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_7112.jpg",
        "year": "7112",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_7123.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_7123.jpg",
        "year": "7123",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_7178.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_7178.jpg",
        "year": "7178",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_7246.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_7246.jpg",
        "year": "7246",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_7360.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_7360.jpg",
        "year": "7360",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_7363.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_7363.jpg",
        "year": "7363",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_7405.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_7405.jpg",
        "year": "7405",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_7614.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_7614.jpg",
        "year": "7614",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_8265.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_8265.jpg",
        "year": "8265",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_9297.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_9297.jpg",
        "year": "9297",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_9633.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_9633.jpg",
        "year": "9633",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Background Painting/IMG_9634.jpg",
        "fullImage": "/art/fullsize/Background Painting/IMG_9634.jpg",
        "year": "9634",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "portable-network-graphics-image-b7e1edb51838-1",
        "title": "Portable Network Graphics image-B7E1EDB51838-1",
        "thumbnail": "/art/thumbnails/Background Painting/Portable Network Graphics image-B7E1EDB51838-1.jpg",
        "fullImage": "/art/fullsize/Background Painting/Portable Network Graphics image-B7E1EDB51838-1.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "portable-network-graphics-image-be2c0b7c20a0-1",
        "title": "Portable Network Graphics image-BE2C0B7C20A0-1",
        "thumbnail": "/art/thumbnails/Background Painting/Portable Network Graphics image-BE2C0B7C20A0-1.jpg",
        "fullImage": "/art/fullsize/Background Painting/Portable Network Graphics image-BE2C0B7C20A0-1.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "tales-from-the-tourbus-1",
        "title": "Tales from the Tourbus 1",
        "thumbnail": "/art/thumbnails/Background Painting/Tales from the Tourbus 1_2017_Adobe Fresco_Drawn for Mike Judge.jpg",
        "fullImage": "/art/fullsize/Background Painting/Tales from the Tourbus 1_2017_Adobe Fresco_Drawn for Mike Judge.jpg",
        "year": "2017",
        "medium": "Adobe Fresco",
        "dimensions": "Drawn for Mike Judge",
        "description": ""
      }
    ]
  },
  {
    "id": "character-design",
    "name": "Character Design",
    "images": [
      {
        "id": "animation18",
        "title": "Animation18",
        "thumbnail": "/art/thumbnails/Character Design/Animation18.jpg",
        "fullImage": "/art/fullsize/Character Design/Animation18.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "animation21-1",
        "title": "Animation21 (1)",
        "thumbnail": "/art/thumbnails/Character Design/Animation21 (1).jpg",
        "fullImage": "/art/fullsize/Character Design/Animation21 (1).jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "animation21",
        "title": "Animation21",
        "thumbnail": "/art/thumbnails/Character Design/Animation21.jpg",
        "fullImage": "/art/fullsize/Character Design/Animation21.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "c3adef0a-0a53-4d65-ad39-f2b6578c8c8e",
        "title": "C3ADEF0A-0A53-4D65-AD39-F2B6578C8C8E",
        "thumbnail": "/art/thumbnails/Character Design/C3ADEF0A-0A53-4D65-AD39-F2B6578C8C8E.jpg",
        "fullImage": "/art/fullsize/Character Design/C3ADEF0A-0A53-4D65-AD39-F2B6578C8C8E.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "donkey-design",
        "title": "donkey design",
        "thumbnail": "/art/thumbnails/Character Design/donkey design.jpg",
        "fullImage": "/art/fullsize/Character Design/donkey design.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_0036 (1).jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_0036 (1).jpg",
        "year": "0036 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_0037.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_0037.jpg",
        "year": "0037",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_0224.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_0224.jpg",
        "year": "0224",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_0447.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_0447.jpg",
        "year": "0447",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_0648.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_0648.jpg",
        "year": "0648",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_0831.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_0831.jpg",
        "year": "0831",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_0850.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_0850.jpg",
        "year": "0850",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_0851.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_0851.jpg",
        "year": "0851",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_0961.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_0961.jpg",
        "year": "0961",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_1064.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_1064.jpg",
        "year": "1064",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_1143.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_1143.jpg",
        "year": "1143",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_1177.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_1177.jpg",
        "year": "1177",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_1215.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_1215.jpg",
        "year": "1215",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_1245.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_1245.jpg",
        "year": "1245",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_1246.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_1246.jpg",
        "year": "1246",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_1537.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_1537.jpg",
        "year": "1537",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_1603.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_1603.jpg",
        "year": "1603",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_1717.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_1717.jpg",
        "year": "1717",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_1751.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_1751.jpg",
        "year": "1751",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_2392.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_2392.jpg",
        "year": "2392",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_2393.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_2393.jpg",
        "year": "2393",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_2409.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_2409.jpg",
        "year": "2409",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_2498.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_2498.jpg",
        "year": "2498",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_2499.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_2499.jpg",
        "year": "2499",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_2530 (1).jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_2530 (1).jpg",
        "year": "2530 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_2667.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_2667.jpg",
        "year": "2667",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_2740.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_2740.jpg",
        "year": "2740",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_2820.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_2820.jpg",
        "year": "2820",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3213.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3213.jpg",
        "year": "3213",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3475.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3475.jpg",
        "year": "3475",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3504.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3504.jpg",
        "year": "3504",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3534.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3534.jpg",
        "year": "3534",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3535.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3535.jpg",
        "year": "3535",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3536.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3536.jpg",
        "year": "3536",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3542.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3542.jpg",
        "year": "3542",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3543.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3543.jpg",
        "year": "3543",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3544.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3544.jpg",
        "year": "3544",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3564.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3564.jpg",
        "year": "3564",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3620.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3620.jpg",
        "year": "3620",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3663.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3663.jpg",
        "year": "3663",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3667.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3667.jpg",
        "year": "3667",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3830.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3830.jpg",
        "year": "3830",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3897.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3897.jpg",
        "year": "3897",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3900.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3900.jpg",
        "year": "3900",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_3929.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_3929.jpg",
        "year": "3929",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_4147.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_4147.jpg",
        "year": "4147",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_4871.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_4871.jpg",
        "year": "4871",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_5678.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_5678.jpg",
        "year": "5678",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_5760.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_5760.jpg",
        "year": "5760",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_5766.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_5766.jpg",
        "year": "5766",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_6426.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_6426.jpg",
        "year": "6426",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_6952.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_6952.jpg",
        "year": "6952",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_6953.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_6953.jpg",
        "year": "6953",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8223.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8223.jpg",
        "year": "8223",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8261.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8261.jpg",
        "year": "8261",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8262.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8262.jpg",
        "year": "8262",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8329.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8329.jpg",
        "year": "8329",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8564.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8564.jpg",
        "year": "8564",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8658.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8658.jpg",
        "year": "8658",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8660.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8660.jpg",
        "year": "8660",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8661.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8661.jpg",
        "year": "8661",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8662.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8662.jpg",
        "year": "8662",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8663.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8663.jpg",
        "year": "8663",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8665.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8665.jpg",
        "year": "8665",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8668.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8668.jpg",
        "year": "8668",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8669.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8669.jpg",
        "year": "8669",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8670.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8670.jpg",
        "year": "8670",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8678.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8678.jpg",
        "year": "8678",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8680.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8680.jpg",
        "year": "8680",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_8832.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_8832.jpg",
        "year": "8832",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Character Design/IMG_9591.jpg",
        "fullImage": "/art/fullsize/Character Design/IMG_9591.jpg",
        "year": "9591",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "mecha-angel-42-small",
        "title": "mecha angel 42 small",
        "thumbnail": "/art/thumbnails/Character Design/mecha angel 42 small.jpg",
        "fullImage": "/art/fullsize/Character Design/mecha angel 42 small.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "custom-clothing",
    "name": "Custom Clothing",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Custom Clothing/IMG_4740.jpg",
        "fullImage": "/art/fullsize/Custom Clothing/IMG_4740.jpg",
        "year": "4740",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Custom Clothing/IMG_6812.jpg",
        "fullImage": "/art/fullsize/Custom Clothing/IMG_6812.jpg",
        "year": "6812",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "jpeg",
        "title": "JPEG",
        "thumbnail": "/art/thumbnails/Custom Clothing/JPEG_20200624_074625.jpg",
        "fullImage": "/art/fullsize/Custom Clothing/JPEG_20200624_074625.jpg",
        "year": "20200624",
        "medium": "074625",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "digital-illustration",
    "name": "Digital Illustration",
    "images": [
      {
        "id": "0af2fc68-1979-488b-8780-4a6290f45b60-1756-0000039dcfb876c0",
        "title": "0AF2FC68-1979-488B-8780-4A6290F45B60-1756-0000039DCFB876C0",
        "thumbnail": "/art/thumbnails/Digital Illustration/0AF2FC68-1979-488B-8780-4A6290F45B60-1756-0000039DCFB876C0.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/0AF2FC68-1979-488B-8780-4A6290F45B60-1756-0000039DCFB876C0.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "abca8f5e-b939-4792-a761-605ab9be5f0d",
        "title": "ABCA8F5E-B939-4792-A761-605AB9BE5F0D",
        "thumbnail": "/art/thumbnails/Digital Illustration/ABCA8F5E-B939-4792-A761-605AB9BE5F0D.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/ABCA8F5E-B939-4792-A761-605AB9BE5F0D.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "blasterbeasts-layers-and-scans",
        "title": "blasterbeasts layers and scans",
        "thumbnail": "/art/thumbnails/Digital Illustration/blasterbeasts layers and scans.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/blasterbeasts layers and scans.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_0287.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_0287.jpg",
        "year": "0287",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_0737.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_0737.jpg",
        "year": "0737",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_0738.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_0738.jpg",
        "year": "0738",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_0748.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_0748.jpg",
        "year": "0748",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_0806.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_0806.jpg",
        "year": "0806",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_0863.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_0863.jpg",
        "year": "0863",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_1017.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_1017.jpg",
        "year": "1017",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_1235.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_1235.jpg",
        "year": "1235",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_1534.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_1534.jpg",
        "year": "1534",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_1541.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_1541.jpg",
        "year": "1541",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_1549.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_1549.jpg",
        "year": "1549",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_1550.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_1550.jpg",
        "year": "1550",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_1595.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_1595.jpg",
        "year": "1595",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_2230.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_2230.jpg",
        "year": "2230",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_2266.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_2266.jpg",
        "year": "2266",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_2323.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_2323.jpg",
        "year": "2323",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_2524.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_2524.jpg",
        "year": "2524",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_2815.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_2815.jpg",
        "year": "2815",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_2823.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_2823.jpg",
        "year": "2823",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_2879.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_2879.jpg",
        "year": "2879",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_3100.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_3100.jpg",
        "year": "3100",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_3502.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_3502.jpg",
        "year": "3502",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_3554.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_3554.jpg",
        "year": "3554",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_3617.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_3617.jpg",
        "year": "3617",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_3619.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_3619.jpg",
        "year": "3619",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_3831.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_3831.jpg",
        "year": "3831",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_3849.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_3849.jpg",
        "year": "3849",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_4151.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_4151.jpg",
        "year": "4151",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_4606.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_4606.jpg",
        "year": "4606",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_4730.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_4730.jpg",
        "year": "4730",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_4831.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_4831.jpg",
        "year": "4831",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_5091.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_5091.jpg",
        "year": "5091",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_6161.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_6161.jpg",
        "year": "6161",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_7523.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_7523.jpg",
        "year": "7523",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_7681.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_7681.jpg",
        "year": "7681",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_7688.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_7688.jpg",
        "year": "7688",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_8689.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_8689.jpg",
        "year": "8689",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_8785.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_8785.jpg",
        "year": "8785",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Digital Illustration/IMG_9840.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/IMG_9840.jpg",
        "year": "9840",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "oddballs3rdanniversary",
        "title": "Oddballs3rdAnniversary",
        "thumbnail": "/art/thumbnails/Digital Illustration/Oddballs3rdAnniversary.jpg",
        "fullImage": "/art/fullsize/Digital Illustration/Oddballs3rdAnniversary.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "event-flyers",
    "name": "Event FLyers",
    "images": [
      {
        "id": "008f79f9-8a3a-47d4-a494-b58c3ecab4f9",
        "title": "008F79F9-8A3A-47D4-A494-B58C3ECAB4F9",
        "thumbnail": "/art/thumbnails/Event FLyers/008F79F9-8A3A-47D4-A494-B58C3ECAB4F9.jpg",
        "fullImage": "/art/fullsize/Event FLyers/008F79F9-8A3A-47D4-A494-B58C3ECAB4F9.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "24dd3b79-6713-440b-a737-7fe635ab4724",
        "title": "24DD3B79-6713-440B-A737-7FE635AB4724",
        "thumbnail": "/art/thumbnails/Event FLyers/24DD3B79-6713-440B-A737-7FE635AB4724.jpg",
        "fullImage": "/art/fullsize/Event FLyers/24DD3B79-6713-440B-A737-7FE635AB4724.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Event FLyers/IMG_0088.jpg",
        "fullImage": "/art/fullsize/Event FLyers/IMG_0088.jpg",
        "year": "0088",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Event FLyers/IMG_0406.jpg",
        "fullImage": "/art/fullsize/Event FLyers/IMG_0406.jpg",
        "year": "0406",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Event FLyers/IMG_0578.jpg",
        "fullImage": "/art/fullsize/Event FLyers/IMG_0578.jpg",
        "year": "0578",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Event FLyers/IMG_1084.jpg",
        "fullImage": "/art/fullsize/Event FLyers/IMG_1084.jpg",
        "year": "1084",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Event FLyers/IMG_1278.jpg",
        "fullImage": "/art/fullsize/Event FLyers/IMG_1278.jpg",
        "year": "1278",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Event FLyers/IMG_1801.jpg",
        "fullImage": "/art/fullsize/Event FLyers/IMG_1801.jpg",
        "year": "1801",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Event FLyers/IMG_4571.jpg",
        "fullImage": "/art/fullsize/Event FLyers/IMG_4571.jpg",
        "year": "4571",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Event FLyers/IMG_5648.jpg",
        "fullImage": "/art/fullsize/Event FLyers/IMG_5648.jpg",
        "year": "5648",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "fashion-prototyping-and-graphics",
    "name": "Fashion Prototyping And Graphics",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Fashion Prototyping and Graphics/IMG_0636.jpg",
        "fullImage": "/art/fullsize/Fashion Prototyping and Graphics/IMG_0636.jpg",
        "year": "0636",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Fashion Prototyping and Graphics/IMG_0637.jpg",
        "fullImage": "/art/fullsize/Fashion Prototyping and Graphics/IMG_0637.jpg",
        "year": "0637",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Fashion Prototyping and Graphics/IMG_0654 (1).jpg",
        "fullImage": "/art/fullsize/Fashion Prototyping and Graphics/IMG_0654 (1).jpg",
        "year": "0654 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Fashion Prototyping and Graphics/IMG_1085.jpg",
        "fullImage": "/art/fullsize/Fashion Prototyping and Graphics/IMG_1085.jpg",
        "year": "1085",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Fashion Prototyping and Graphics/IMG_1089.jpg",
        "fullImage": "/art/fullsize/Fashion Prototyping and Graphics/IMG_1089.jpg",
        "year": "1089",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Fashion Prototyping and Graphics/IMG_1094.jpg",
        "fullImage": "/art/fullsize/Fashion Prototyping and Graphics/IMG_1094.jpg",
        "year": "1094",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Fashion Prototyping and Graphics/IMG_1098.jpg",
        "fullImage": "/art/fullsize/Fashion Prototyping and Graphics/IMG_1098.jpg",
        "year": "1098",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Fashion Prototyping and Graphics/IMG_1104.jpg",
        "fullImage": "/art/fullsize/Fashion Prototyping and Graphics/IMG_1104.jpg",
        "year": "1104",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Fashion Prototyping and Graphics/IMG_1106.jpg",
        "fullImage": "/art/fullsize/Fashion Prototyping and Graphics/IMG_1106.jpg",
        "year": "1106",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Fashion Prototyping and Graphics/IMG_1109.jpg",
        "fullImage": "/art/fullsize/Fashion Prototyping and Graphics/IMG_1109.jpg",
        "year": "1109",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Fashion Prototyping and Graphics/IMG_4580.jpg",
        "fullImage": "/art/fullsize/Fashion Prototyping and Graphics/IMG_4580.jpg",
        "year": "4580",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Fashion Prototyping and Graphics/IMG_4960.jpg",
        "fullImage": "/art/fullsize/Fashion Prototyping and Graphics/IMG_4960.jpg",
        "year": "4960",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "glitch-art",
    "name": "Glitch Art",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Glitch Art/IMG_2081.jpg",
        "fullImage": "/art/fullsize/Glitch Art/IMG_2081.jpg",
        "year": "2081",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Glitch Art/IMG_5571.jpg",
        "fullImage": "/art/fullsize/Glitch Art/IMG_5571.jpg",
        "year": "5571",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Glitch Art/IMG_6353.jpg",
        "fullImage": "/art/fullsize/Glitch Art/IMG_6353.jpg",
        "year": "6353",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Glitch Art/IMG_6354.jpg",
        "fullImage": "/art/fullsize/Glitch Art/IMG_6354.jpg",
        "year": "6354",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Glitch Art/IMG_6696.jpg",
        "fullImage": "/art/fullsize/Glitch Art/IMG_6696.jpg",
        "year": "6696",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Glitch Art/IMG_6697.jpg",
        "fullImage": "/art/fullsize/Glitch Art/IMG_6697.jpg",
        "year": "6697",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "graffiti",
    "name": "Graffiti",
    "images": [
      {
        "id": "266c338c-8449-4122-a4e8-19ae795a0e78",
        "title": "266C338C-8449-4122-A4E8-19AE795A0E78",
        "thumbnail": "/art/thumbnails/Graffiti/266C338C-8449-4122-A4E8-19AE795A0E78.jpg",
        "fullImage": "/art/fullsize/Graffiti/266C338C-8449-4122-A4E8-19AE795A0E78.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "dd797d7e-a100-4d0a-a1f3-a94f1e75a0d8",
        "title": "DD797D7E-A100-4D0A-A1F3-A94F1E75A0D8",
        "thumbnail": "/art/thumbnails/Graffiti/DD797D7E-A100-4D0A-A1F3-A94F1E75A0D8.jpg",
        "fullImage": "/art/fullsize/Graffiti/DD797D7E-A100-4D0A-A1F3-A94F1E75A0D8.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "e3298602-4079-4301-bc52-1e95290fa9d3",
        "title": "E3298602-4079-4301-BC52-1E95290FA9D3",
        "thumbnail": "/art/thumbnails/Graffiti/E3298602-4079-4301-BC52-1E95290FA9D3.jpg",
        "fullImage": "/art/fullsize/Graffiti/E3298602-4079-4301-BC52-1E95290FA9D3.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_0564.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_0564.jpg",
        "year": "0564",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_0589.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_0589.jpg",
        "year": "0589",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_0622.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_0622.jpg",
        "year": "0622",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_0625.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_0625.jpg",
        "year": "0625",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_0628.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_0628.jpg",
        "year": "0628",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_0633.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_0633.jpg",
        "year": "0633",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_0684.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_0684.jpg",
        "year": "0684",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_1207.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_1207.jpg",
        "year": "1207",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_1409.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_1409.jpg",
        "year": "1409",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_2351.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_2351.jpg",
        "year": "2351",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_2748.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_2748.jpg",
        "year": "2748",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_6226.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_6226.jpg",
        "year": "6226",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_6229.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_6229.jpg",
        "year": "6229",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_6230.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_6230.jpg",
        "year": "6230",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_6232.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_6232.jpg",
        "year": "6232",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_6233.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_6233.jpg",
        "year": "6233",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_6234.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_6234.jpg",
        "year": "6234",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Graffiti/IMG_6238.jpg",
        "fullImage": "/art/fullsize/Graffiti/IMG_6238.jpg",
        "year": "6238",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "hand-painted-objects",
    "name": "Hand Painted Objects",
    "images": [
      {
        "id": "58245019610",
        "title": "58245019610",
        "thumbnail": "/art/thumbnails/Hand-Painted Objects/58245019610__7F9A6D04-6281-42AB-A8CA-77BBE4F9948C.jpg",
        "fullImage": "/art/fullsize/Hand-Painted Objects/58245019610__7F9A6D04-6281-42AB-A8CA-77BBE4F9948C.jpg",
        "year": "",
        "medium": "7F9A6D04-6281-42AB-A8CA-77BBE4F9948C",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "58245020499",
        "title": "58245020499",
        "thumbnail": "/art/thumbnails/Hand-Painted Objects/58245020499__85DB9E53-6AED-4008-B134-6415C49CFCCB.jpg",
        "fullImage": "/art/fullsize/Hand-Painted Objects/58245020499__85DB9E53-6AED-4008-B134-6415C49CFCCB.jpg",
        "year": "",
        "medium": "85DB9E53-6AED-4008-B134-6415C49CFCCB",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Hand-Painted Objects/IMG_0361.jpg",
        "fullImage": "/art/fullsize/Hand-Painted Objects/IMG_0361.jpg",
        "year": "0361",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Hand-Painted Objects/IMG_0475.jpg",
        "fullImage": "/art/fullsize/Hand-Painted Objects/IMG_0475.jpg",
        "year": "0475",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Hand-Painted Objects/IMG_3985.jpg",
        "fullImage": "/art/fullsize/Hand-Painted Objects/IMG_3985.jpg",
        "year": "3985",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Hand-Painted Objects/IMG_4535.jpg",
        "fullImage": "/art/fullsize/Hand-Painted Objects/IMG_4535.jpg",
        "year": "4535",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "kool-skull-logos",
    "name": "KOOL SKULL LOGOS",
    "images": [
      {
        "id": "57743816-4995-4c9d-9bfb-7149c10d7e1f",
        "title": "57743816-4995-4C9D-9BFB-7149C10D7E1F",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/57743816-4995-4C9D-9BFB-7149C10D7E1F.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/57743816-4995-4C9D-9BFB-7149C10D7E1F.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_0141.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_0141.jpg",
        "year": "0141",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_0801.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_0801.jpg",
        "year": "0801",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_1242.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_1242.jpg",
        "year": "1242",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_1253.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_1253.jpg",
        "year": "1253",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_1723.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_1723.jpg",
        "year": "1723",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_3498.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_3498.jpg",
        "year": "3498",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_3697.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_3697.jpg",
        "year": "3697",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_4187.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_4187.jpg",
        "year": "4187",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_4203.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_4203.jpg",
        "year": "4203",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_4607.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_4607.jpg",
        "year": "4607",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_4820.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_4820.jpg",
        "year": "4820",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_5216.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_5216.jpg",
        "year": "5216",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_5950.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_5950.jpg",
        "year": "5950",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_6856.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_6856.jpg",
        "year": "6856",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/KOOL SKULL LOGOS/IMG_8706.jpg",
        "fullImage": "/art/fullsize/KOOL SKULL LOGOS/IMG_8706.jpg",
        "year": "8706",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "live-event-visual-graphics",
    "name": "Live Event Visual Graphics",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Live Event Visual Graphics/IMG_4770.jpg",
        "fullImage": "/art/fullsize/Live Event Visual Graphics/IMG_4770.jpg",
        "year": "4770",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Live Event Visual Graphics/IMG_4780.jpg",
        "fullImage": "/art/fullsize/Live Event Visual Graphics/IMG_4780.jpg",
        "year": "4780",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Live Event Visual Graphics/IMG_4842.jpg",
        "fullImage": "/art/fullsize/Live Event Visual Graphics/IMG_4842.jpg",
        "year": "4842",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Live Event Visual Graphics/IMG_4961.jpg",
        "fullImage": "/art/fullsize/Live Event Visual Graphics/IMG_4961.jpg",
        "year": "4961",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Live Event Visual Graphics/IMG_4962.jpg",
        "fullImage": "/art/fullsize/Live Event Visual Graphics/IMG_4962.jpg",
        "year": "4962",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Live Event Visual Graphics/IMG_4967.jpg",
        "fullImage": "/art/fullsize/Live Event Visual Graphics/IMG_4967.jpg",
        "year": "4967",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Live Event Visual Graphics/IMG_4968.jpg",
        "fullImage": "/art/fullsize/Live Event Visual Graphics/IMG_4968.jpg",
        "year": "4968",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Live Event Visual Graphics/IMG_5007.jpg",
        "fullImage": "/art/fullsize/Live Event Visual Graphics/IMG_5007.jpg",
        "year": "5007",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Live Event Visual Graphics/IMG_5074.jpg",
        "fullImage": "/art/fullsize/Live Event Visual Graphics/IMG_5074.jpg",
        "year": "5074",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Live Event Visual Graphics/IMG_5087.jpg",
        "fullImage": "/art/fullsize/Live Event Visual Graphics/IMG_5087.jpg",
        "year": "5087",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "logo-design",
    "name": "Logo Design",
    "images": [
      {
        "id": "a27d6bfe-27a2-4456-816d-c85959db889e",
        "title": "A27D6BFE-27A2-4456-816D-C85959DB889E",
        "thumbnail": "/art/thumbnails/Logo Design/A27D6BFE-27A2-4456-816D-C85959DB889E.jpg",
        "fullImage": "/art/fullsize/Logo Design/A27D6BFE-27A2-4456-816D-C85959DB889E.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "f2d130e5-79e9-4e7a-b4b3-57f787c3e383",
        "title": "F2D130E5-79E9-4E7A-B4B3-57F787C3E383",
        "thumbnail": "/art/thumbnails/Logo Design/F2D130E5-79E9-4E7A-B4B3-57F787C3E383.jpg",
        "fullImage": "/art/fullsize/Logo Design/F2D130E5-79E9-4E7A-B4B3-57F787C3E383.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_0274.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_0274.jpg",
        "year": "0274",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_0351.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_0351.jpg",
        "year": "0351",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_0405.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_0405.jpg",
        "year": "0405",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_0546.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_0546.jpg",
        "year": "0546",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_0643.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_0643.jpg",
        "year": "0643",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_0648.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_0648.jpg",
        "year": "0648",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_1258.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_1258.jpg",
        "year": "1258",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_1259.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_1259.jpg",
        "year": "1259",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_1685.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_1685.jpg",
        "year": "1685",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_1706.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_1706.jpg",
        "year": "1706",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_1953.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_1953.jpg",
        "year": "1953",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_2073.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_2073.jpg",
        "year": "2073",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_2176.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_2176.jpg",
        "year": "2176",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_2306.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_2306.jpg",
        "year": "2306",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_2793.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_2793.jpg",
        "year": "2793",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_2796.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_2796.jpg",
        "year": "2796",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_3046.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_3046.jpg",
        "year": "3046",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_3713.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_3713.jpg",
        "year": "3713",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_3730.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_3730.jpg",
        "year": "3730",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_3751.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_3751.jpg",
        "year": "3751",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_3963.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_3963.jpg",
        "year": "3963",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_4174.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_4174.jpg",
        "year": "4174",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_4351.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_4351.jpg",
        "year": "4351",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_4509.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_4509.jpg",
        "year": "4509",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_4617.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_4617.jpg",
        "year": "4617",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_4867.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_4867.jpg",
        "year": "4867",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_5038.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_5038.jpg",
        "year": "5038",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_5098.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_5098.jpg",
        "year": "5098",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_5246.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_5246.jpg",
        "year": "5246",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_5276.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_5276.jpg",
        "year": "5276",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_5473.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_5473.jpg",
        "year": "5473",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_5724.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_5724.jpg",
        "year": "5724",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_6771.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_6771.jpg",
        "year": "6771",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_7135.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_7135.jpg",
        "year": "7135",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_8327.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_8327.jpg",
        "year": "8327",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_8358.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_8358.jpg",
        "year": "8358",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_8375.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_8375.jpg",
        "year": "8375",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_9187.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_9187.jpg",
        "year": "9187",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Logo Design/IMG_9841.jpg",
        "fullImage": "/art/fullsize/Logo Design/IMG_9841.jpg",
        "year": "9841",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "night-channels-detailed-logo-unused",
        "title": "Night Channels Detailed Logo Unused",
        "thumbnail": "/art/thumbnails/Logo Design/Night Channels Detailed Logo Unused_2015_Pen and Ink_drawn for nightchannels dot com.jpg",
        "fullImage": "/art/fullsize/Logo Design/Night Channels Detailed Logo Unused_2015_Pen and Ink_drawn for nightchannels dot com.jpg",
        "year": "2015",
        "medium": "Pen and Ink",
        "dimensions": "drawn for nightchannels dot com",
        "description": ""
      },
      {
        "id": "shrubbs-logo",
        "title": "shrubbs logo",
        "thumbnail": "/art/thumbnails/Logo Design/shrubbs logo.jpg",
        "fullImage": "/art/fullsize/Logo Design/shrubbs logo.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "misc",
    "name": "Misc",
    "images": [
      {
        "id": "721f0579-184f-400f-a7bf-b980163ea349",
        "title": "721F0579-184F-400F-A7BF-B980163EA349",
        "thumbnail": "/art/thumbnails/Misc/721F0579-184F-400F-A7BF-B980163EA349.jpg",
        "fullImage": "/art/fullsize/Misc/721F0579-184F-400F-A7BF-B980163EA349.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "88a9112e-fc9a-423a-bf0d-44f20f6883dc",
        "title": "88A9112E-FC9A-423A-BF0D-44F20F6883DC",
        "thumbnail": "/art/thumbnails/Misc/88A9112E-FC9A-423A-BF0D-44F20F6883DC.jpg",
        "fullImage": "/art/fullsize/Misc/88A9112E-FC9A-423A-BF0D-44F20F6883DC.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "aa12c351-fd50-4bb6-9a8b-7a72a6fae62b",
        "title": "AA12C351-FD50-4BB6-9A8B-7A72A6FAE62B",
        "thumbnail": "/art/thumbnails/Misc/AA12C351-FD50-4BB6-9A8B-7A72A6FAE62B.jpg",
        "fullImage": "/art/fullsize/Misc/AA12C351-FD50-4BB6-9A8B-7A72A6FAE62B.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Misc/IMG_0268.jpg",
        "fullImage": "/art/fullsize/Misc/IMG_0268.jpg",
        "year": "0268",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Misc/IMG_0477.jpg",
        "fullImage": "/art/fullsize/Misc/IMG_0477.jpg",
        "year": "0477",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Misc/IMG_0667.jpg",
        "fullImage": "/art/fullsize/Misc/IMG_0667.jpg",
        "year": "0667",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Misc/IMG_0993.jpg",
        "fullImage": "/art/fullsize/Misc/IMG_0993.jpg",
        "year": "0993",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Misc/IMG_1944.jpg",
        "fullImage": "/art/fullsize/Misc/IMG_1944.jpg",
        "year": "1944",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Misc/IMG_3385.jpg",
        "fullImage": "/art/fullsize/Misc/IMG_3385.jpg",
        "year": "3385",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Misc/IMG_3386.jpg",
        "fullImage": "/art/fullsize/Misc/IMG_3386.jpg",
        "year": "3386",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Misc/IMG_3387.jpg",
        "fullImage": "/art/fullsize/Misc/IMG_3387.jpg",
        "year": "3387",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Misc/IMG_8362.jpg",
        "fullImage": "/art/fullsize/Misc/IMG_8362.jpg",
        "year": "8362",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "murals",
    "name": "Murals",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Murals/IMG_0288.jpg",
        "fullImage": "/art/fullsize/Murals/IMG_0288.jpg",
        "year": "0288",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Murals/IMG_0290.jpg",
        "fullImage": "/art/fullsize/Murals/IMG_0290.jpg",
        "year": "0290",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Murals/IMG_4028.jpg",
        "fullImage": "/art/fullsize/Murals/IMG_4028.jpg",
        "year": "4028",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Murals/IMG_4042.jpg",
        "fullImage": "/art/fullsize/Murals/IMG_4042.jpg",
        "year": "4042",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Murals/IMG_5577.jpg",
        "fullImage": "/art/fullsize/Murals/IMG_5577.jpg",
        "year": "5577",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "music-video-stills",
    "name": "Music Video Stills",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Music Video Stills/IMG_0950.jpg",
        "fullImage": "/art/fullsize/Music Video Stills/IMG_0950.jpg",
        "year": "0950",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "nft-design",
    "name": "NFT Design",
    "images": [
      {
        "id": "3397",
        "title": "3397",
        "thumbnail": "/art/thumbnails/NFT Design/3397.jpg",
        "fullImage": "/art/fullsize/NFT Design/3397.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "illustration5-1",
        "title": "Illustration5 (1)",
        "thumbnail": "/art/thumbnails/NFT Design/Illustration5 (1).jpg",
        "fullImage": "/art/fullsize/NFT Design/Illustration5 (1).jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "illustration5-2",
        "title": "Illustration5 (2)",
        "thumbnail": "/art/thumbnails/NFT Design/Illustration5 (2).jpg",
        "fullImage": "/art/fullsize/NFT Design/Illustration5 (2).jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "illustration5-3",
        "title": "Illustration5 (3)",
        "thumbnail": "/art/thumbnails/NFT Design/Illustration5 (3).jpg",
        "fullImage": "/art/fullsize/NFT Design/Illustration5 (3).jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "illustration5",
        "title": "Illustration5",
        "thumbnail": "/art/thumbnails/NFT Design/Illustration5.jpg",
        "fullImage": "/art/fullsize/NFT Design/Illustration5.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/NFT Design/IMG_0020.jpg",
        "fullImage": "/art/fullsize/NFT Design/IMG_0020.jpg",
        "year": "0020",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/NFT Design/IMG_2343.jpg",
        "fullImage": "/art/fullsize/NFT Design/IMG_2343.jpg",
        "year": "2343",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/NFT Design/IMG_3004.jpg",
        "fullImage": "/art/fullsize/NFT Design/IMG_3004.jpg",
        "year": "3004",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/NFT Design/IMG_3051.jpg",
        "fullImage": "/art/fullsize/NFT Design/IMG_3051.jpg",
        "year": "3051",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/NFT Design/IMG_3682.jpg",
        "fullImage": "/art/fullsize/NFT Design/IMG_3682.jpg",
        "year": "3682",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/NFT Design/IMG_7136.jpg",
        "fullImage": "/art/fullsize/NFT Design/IMG_7136.jpg",
        "year": "7136",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/NFT Design/IMG_8994.jpg",
        "fullImage": "/art/fullsize/NFT Design/IMG_8994.jpg",
        "year": "8994",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/NFT Design/IMG_9357.jpg",
        "fullImage": "/art/fullsize/NFT Design/IMG_9357.jpg",
        "year": "9357",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "paintings",
    "name": "Paintings",
    "images": [
      {
        "id": "4743fa60-a643-474e-af12-718c5f162fe8",
        "title": "4743FA60-A643-474E-AF12-718C5F162FE8",
        "thumbnail": "/art/thumbnails/Paintings/4743FA60-A643-474E-AF12-718C5F162FE8.jpg",
        "fullImage": "/art/fullsize/Paintings/4743FA60-A643-474E-AF12-718C5F162FE8.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "4ef51d77-ca02-417d-85a4-eac48161d8b3",
        "title": "4EF51D77-CA02-417D-85A4-EAC48161D8B3",
        "thumbnail": "/art/thumbnails/Paintings/4EF51D77-CA02-417D-85A4-EAC48161D8B3.jpg",
        "fullImage": "/art/fullsize/Paintings/4EF51D77-CA02-417D-85A4-EAC48161D8B3.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "59674365119",
        "title": "59674365119",
        "thumbnail": "/art/thumbnails/Paintings/59674365119__7760EFFC-284A-4D1C-AE1B-1FE85216E905.jpg",
        "fullImage": "/art/fullsize/Paintings/59674365119__7760EFFC-284A-4D1C-AE1B-1FE85216E905.jpg",
        "year": "",
        "medium": "7760EFFC-284A-4D1C-AE1B-1FE85216E905",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "dsc",
        "title": "DSC",
        "thumbnail": "/art/thumbnails/Paintings/DSC_0417.jpg",
        "fullImage": "/art/fullsize/Paintings/DSC_0417.jpg",
        "year": "0417",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Paintings/IMG_0035.jpg",
        "fullImage": "/art/fullsize/Paintings/IMG_0035.jpg",
        "year": "0035",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Paintings/IMG_0061.jpg",
        "fullImage": "/art/fullsize/Paintings/IMG_0061.jpg",
        "year": "0061",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Paintings/IMG_1669.jpg",
        "fullImage": "/art/fullsize/Paintings/IMG_1669.jpg",
        "year": "1669",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Paintings/IMG_1672.jpg",
        "fullImage": "/art/fullsize/Paintings/IMG_1672.jpg",
        "year": "1672",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Paintings/IMG_1673.jpg",
        "fullImage": "/art/fullsize/Paintings/IMG_1673.jpg",
        "year": "1673",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Paintings/IMG_1674.jpg",
        "fullImage": "/art/fullsize/Paintings/IMG_1674.jpg",
        "year": "1674",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Paintings/IMG_4701.jpg",
        "fullImage": "/art/fullsize/Paintings/IMG_4701.jpg",
        "year": "4701",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Paintings/IMG_4742.jpg",
        "fullImage": "/art/fullsize/Paintings/IMG_4742.jpg",
        "year": "4742",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Paintings/IMG_7779.jpg",
        "fullImage": "/art/fullsize/Paintings/IMG_7779.jpg",
        "year": "7779",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "screenshot",
        "title": "Screenshot",
        "thumbnail": "/art/thumbnails/Paintings/Screenshot_2024-10-02_at_1.19.03_AM.jpg",
        "fullImage": "/art/fullsize/Paintings/Screenshot_2024-10-02_at_1.19.03_AM.jpg",
        "year": "2024-10-02",
        "medium": "at",
        "dimensions": "1.19.03",
        "description": "AM"
      },
      {
        "id": "screenshot",
        "title": "Screenshot",
        "thumbnail": "/art/thumbnails/Paintings/Screenshot_2024-10-02_at_1.20.19_AM.jpg",
        "fullImage": "/art/fullsize/Paintings/Screenshot_2024-10-02_at_1.20.19_AM.jpg",
        "year": "2024-10-02",
        "medium": "at",
        "dimensions": "1.20.19",
        "description": "AM"
      },
      {
        "id": "screenshot",
        "title": "Screenshot",
        "thumbnail": "/art/thumbnails/Paintings/Screenshot_2024-10-02_at_1.20.53_AM.jpg",
        "fullImage": "/art/fullsize/Paintings/Screenshot_2024-10-02_at_1.20.53_AM.jpg",
        "year": "2024-10-02",
        "medium": "at",
        "dimensions": "1.20.53",
        "description": "AM"
      },
      {
        "id": "screenshot",
        "title": "Screenshot",
        "thumbnail": "/art/thumbnails/Paintings/Screenshot_2024-10-02_at_1.21.10_AM.jpg",
        "fullImage": "/art/fullsize/Paintings/Screenshot_2024-10-02_at_1.21.10_AM.jpg",
        "year": "2024-10-02",
        "medium": "at",
        "dimensions": "1.21.10",
        "description": "AM"
      }
    ]
  },
  {
    "id": "pen-and-ink",
    "name": "Pen And Ink",
    "images": [
      {
        "id": "4c8dbe97-463b-4690-bcb1-f9f8ab62d168",
        "title": "4C8DBE97-463B-4690-BCB1-F9F8AB62D168",
        "thumbnail": "/art/thumbnails/Pen and Ink/4C8DBE97-463B-4690-BCB1-F9F8AB62D168.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/4C8DBE97-463B-4690-BCB1-F9F8AB62D168.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "583c32bf-9e2c-49de-902a-005e84e665f2",
        "title": "583C32BF-9E2C-49DE-902A-005E84E665F2",
        "thumbnail": "/art/thumbnails/Pen and Ink/583C32BF-9E2C-49DE-902A-005E84E665F2.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/583C32BF-9E2C-49DE-902A-005E84E665F2.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "68860340882",
        "title": "68860340882",
        "thumbnail": "/art/thumbnails/Pen and Ink/68860340882__6A8AE095-C94C-46A2-AA75-693CDAD0CD98.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/68860340882__6A8AE095-C94C-46A2-AA75-693CDAD0CD98.jpg",
        "year": "",
        "medium": "6A8AE095-C94C-46A2-AA75-693CDAD0CD98",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "68879703299",
        "title": "68879703299",
        "thumbnail": "/art/thumbnails/Pen and Ink/68879703299__5FA3F0C1-98B8-4FA2-9A15-5AFE804F29E0.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/68879703299__5FA3F0C1-98B8-4FA2-9A15-5AFE804F29E0.jpg",
        "year": "",
        "medium": "5FA3F0C1-98B8-4FA2-9A15-5AFE804F29E0",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "6e422e51-f711-4a6f-beb9-46a798ff258d",
        "title": "6E422E51-F711-4A6F-BEB9-46A798FF258D",
        "thumbnail": "/art/thumbnails/Pen and Ink/6E422E51-F711-4A6F-BEB9-46A798FF258D.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/6E422E51-F711-4A6F-BEB9-46A798FF258D.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "6f1096b9-dce4-47a4-a750-ca4d3a9e236f",
        "title": "6F1096B9-DCE4-47A4-A750-CA4D3A9E236F",
        "thumbnail": "/art/thumbnails/Pen and Ink/6F1096B9-DCE4-47A4-A750-CA4D3A9E236F.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/6F1096B9-DCE4-47A4-A750-CA4D3A9E236F.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "aa6082d4-aa4b-4fce-9b8e-b02b9c65ede7",
        "title": "AA6082D4-AA4B-4FCE-9B8E-B02B9C65EDE7",
        "thumbnail": "/art/thumbnails/Pen and Ink/AA6082D4-AA4B-4FCE-9B8E-B02B9C65EDE7.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/AA6082D4-AA4B-4FCE-9B8E-B02B9C65EDE7.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "ba8f9260-dc68-4772-9dfd-3dac7aec6c31",
        "title": "BA8F9260-DC68-4772-9DFD-3DAC7AEC6C31",
        "thumbnail": "/art/thumbnails/Pen and Ink/BA8F9260-DC68-4772-9DFD-3DAC7AEC6C31.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/BA8F9260-DC68-4772-9DFD-3DAC7AEC6C31.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "c9e8aec3-104a-411f-b2a4-963a23b2e7e4",
        "title": "C9E8AEC3-104A-411F-B2A4-963A23B2E7E4",
        "thumbnail": "/art/thumbnails/Pen and Ink/C9E8AEC3-104A-411F-B2A4-963A23B2E7E4.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/C9E8AEC3-104A-411F-B2A4-963A23B2E7E4.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "e3c07039-11b8-436b-96db-cb5f8ae8f3ef",
        "title": "E3C07039-11B8-436B-96DB-CB5F8AE8F3EF",
        "thumbnail": "/art/thumbnails/Pen and Ink/E3C07039-11B8-436B-96DB-CB5F8AE8F3EF.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/E3C07039-11B8-436B-96DB-CB5F8AE8F3EF.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "fast-food-monster",
        "title": "Fast Food Monster",
        "thumbnail": "/art/thumbnails/Pen and Ink/Fast Food Monster_2015_Pen and Ink_Drawn for nightchannels dot com.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/Fast Food Monster_2015_Pen and Ink_Drawn for nightchannels dot com.jpg",
        "year": "2015",
        "medium": "Pen and Ink",
        "dimensions": "Drawn for nightchannels dot com",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_0015.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_0015.jpg",
        "year": "0015",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_0104.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_0104.jpg",
        "year": "0104",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_0140.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_0140.jpg",
        "year": "0140",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_1270.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_1270.jpg",
        "year": "1270",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_1605.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_1605.jpg",
        "year": "1605",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_1675.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_1675.jpg",
        "year": "1675",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_1887.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_1887.jpg",
        "year": "1887",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_1888.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_1888.jpg",
        "year": "1888",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_1889.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_1889.jpg",
        "year": "1889",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_1937.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_1937.jpg",
        "year": "1937",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_1938 (1).jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_1938 (1).jpg",
        "year": "1938 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_2005.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_2005.jpg",
        "year": "2005",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_2180 (1).jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_2180 (1).jpg",
        "year": "2180 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_2978.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_2978.jpg",
        "year": "2978",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_3098.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_3098.jpg",
        "year": "3098",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_3471.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_3471.jpg",
        "year": "3471",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_3634.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_3634.jpg",
        "year": "3634",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_3655.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_3655.jpg",
        "year": "3655",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_3657.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_3657.jpg",
        "year": "3657",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_3824.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_3824.jpg",
        "year": "3824",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_3984.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_3984.jpg",
        "year": "3984",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_4810.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_4810.jpg",
        "year": "4810",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_4851.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_4851.jpg",
        "year": "4851",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_4865.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_4865.jpg",
        "year": "4865",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_6922.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_6922.jpg",
        "year": "6922",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_7055.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_7055.jpg",
        "year": "7055",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_7652.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_7652.jpg",
        "year": "7652",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Pen and Ink/IMG_8013.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/IMG_8013.jpg",
        "year": "8013",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "manx-poster-artwork",
        "title": "Manx Poster Artwork",
        "thumbnail": "/art/thumbnails/Pen and Ink/Manx Poster Artwork_2017_Pen and Ink_Drawn for the band the Manx.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/Manx Poster Artwork_2017_Pen and Ink_Drawn for the band the Manx.jpg",
        "year": "2017",
        "medium": "Pen and Ink",
        "dimensions": "Drawn for the band the Manx",
        "description": ""
      },
      {
        "id": "shitbird",
        "title": "Shitbird",
        "thumbnail": "/art/thumbnails/Pen and Ink/Shitbird_2015_18x24'_Pen and Ink_Drawn for Shitbird album art that never came out.jpg",
        "fullImage": "/art/fullsize/Pen and Ink/Shitbird_2015_18x24'_Pen and Ink_Drawn for Shitbird album art that never came out.jpg",
        "year": "2015",
        "medium": "18x24'",
        "dimensions": "Pen and Ink",
        "description": "Drawn for Shitbird album art that never came out"
      }
    ]
  },
  {
    "id": "portraits",
    "name": "Portraits",
    "images": [
      {
        "id": "0eabcc3c-0798-459b-9936-4a2e919dd7e8",
        "title": "0EABCC3C-0798-459B-9936-4A2E919DD7E8",
        "thumbnail": "/art/thumbnails/Portraits/0EABCC3C-0798-459B-9936-4A2E919DD7E8.jpg",
        "fullImage": "/art/fullsize/Portraits/0EABCC3C-0798-459B-9936-4A2E919DD7E8.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "1b48c83f-ed3a-4769-91ab-9ce007ad1465",
        "title": "1B48C83F-ED3A-4769-91AB-9CE007AD1465",
        "thumbnail": "/art/thumbnails/Portraits/1B48C83F-ED3A-4769-91AB-9CE007AD1465.jpg",
        "fullImage": "/art/fullsize/Portraits/1B48C83F-ED3A-4769-91AB-9CE007AD1465.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "2295e638-fc0a-457e-aa57-9a04275322bc",
        "title": "2295E638-FC0A-457E-AA57-9A04275322BC",
        "thumbnail": "/art/thumbnails/Portraits/2295E638-FC0A-457E-AA57-9A04275322BC.jpg",
        "fullImage": "/art/fullsize/Portraits/2295E638-FC0A-457E-AA57-9A04275322BC.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "287e3384-e7c1-46e3-be5f-d884d06402f3",
        "title": "287E3384-E7C1-46E3-BE5F-D884D06402F3",
        "thumbnail": "/art/thumbnails/Portraits/287E3384-E7C1-46E3-BE5F-D884D06402F3.jpg",
        "fullImage": "/art/fullsize/Portraits/287E3384-E7C1-46E3-BE5F-D884D06402F3.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "36685090-b532-4d03-95d5-3cd2f21e15bb",
        "title": "36685090-B532-4D03-95D5-3CD2F21E15BB",
        "thumbnail": "/art/thumbnails/Portraits/36685090-B532-4D03-95D5-3CD2F21E15BB.jpg",
        "fullImage": "/art/fullsize/Portraits/36685090-B532-4D03-95D5-3CD2F21E15BB.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "3e2cde3e-0607-430e-a27d-bb0e17bb9d4a",
        "title": "3E2CDE3E-0607-430E-A27D-BB0E17BB9D4A",
        "thumbnail": "/art/thumbnails/Portraits/3E2CDE3E-0607-430E-A27D-BB0E17BB9D4A.jpg",
        "fullImage": "/art/fullsize/Portraits/3E2CDE3E-0607-430E-A27D-BB0E17BB9D4A.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "785a790f-4271-43af-8d4e-57ee1d0bdc9e",
        "title": "785A790F-4271-43AF-8D4E-57EE1D0BDC9E",
        "thumbnail": "/art/thumbnails/Portraits/785A790F-4271-43AF-8D4E-57EE1D0BDC9E.jpg",
        "fullImage": "/art/fullsize/Portraits/785A790F-4271-43AF-8D4E-57EE1D0BDC9E.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "aa0ba586-9311-41b6-bb82-2921e62864f1",
        "title": "AA0BA586-9311-41B6-BB82-2921E62864F1",
        "thumbnail": "/art/thumbnails/Portraits/AA0BA586-9311-41B6-BB82-2921E62864F1.jpg",
        "fullImage": "/art/fullsize/Portraits/AA0BA586-9311-41B6-BB82-2921E62864F1.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "b30a3ed8-867e-44d4-8e9e-41e447ff5046",
        "title": "B30A3ED8-867E-44D4-8E9E-41E447FF5046",
        "thumbnail": "/art/thumbnails/Portraits/B30A3ED8-867E-44D4-8E9E-41E447FF5046.jpg",
        "fullImage": "/art/fullsize/Portraits/B30A3ED8-867E-44D4-8E9E-41E447FF5046.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "breanne",
        "title": "Breanne",
        "thumbnail": "/art/thumbnails/Portraits/Breanne.jpg",
        "fullImage": "/art/fullsize/Portraits/Breanne.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "d6bf0cfa-ce51-4d0f-8ee9-96853c04085e",
        "title": "D6BF0CFA-CE51-4D0F-8EE9-96853C04085E",
        "thumbnail": "/art/thumbnails/Portraits/D6BF0CFA-CE51-4D0F-8EE9-96853C04085E.jpg",
        "fullImage": "/art/fullsize/Portraits/D6BF0CFA-CE51-4D0F-8EE9-96853C04085E.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "f8bbf289-2fe4-4c01-9849-5dd082e76702",
        "title": "F8BBF289-2FE4-4C01-9849-5DD082E76702",
        "thumbnail": "/art/thumbnails/Portraits/F8BBF289-2FE4-4C01-9849-5DD082E76702.jpg",
        "fullImage": "/art/fullsize/Portraits/F8BBF289-2FE4-4C01-9849-5DD082E76702.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_0022.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_0022.jpg",
        "year": "0022",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_0036 (2).jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_0036 (2).jpg",
        "year": "0036 (2)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_0346.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_0346.jpg",
        "year": "0346",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_0347.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_0347.jpg",
        "year": "0347",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_0370.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_0370.jpg",
        "year": "0370",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_0386.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_0386.jpg",
        "year": "0386",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_0604.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_0604.jpg",
        "year": "0604",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_0649.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_0649.jpg",
        "year": "0649",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_1191.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_1191.jpg",
        "year": "1191",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_1198.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_1198.jpg",
        "year": "1198",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_1221.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_1221.jpg",
        "year": "1221",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_1222.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_1222.jpg",
        "year": "1222",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_1225.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_1225.jpg",
        "year": "1225",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_1227.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_1227.jpg",
        "year": "1227",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_1387.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_1387.jpg",
        "year": "1387",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_1828.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_1828.jpg",
        "year": "1828",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_1870.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_1870.jpg",
        "year": "1870",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2180.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2180.jpg",
        "year": "2180",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2339.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2339.jpg",
        "year": "2339",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2341.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2341.jpg",
        "year": "2341",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2344.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2344.jpg",
        "year": "2344",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2347.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2347.jpg",
        "year": "2347",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2355.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2355.jpg",
        "year": "2355",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2376.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2376.jpg",
        "year": "2376",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2377 (1).jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2377 (1).jpg",
        "year": "2377 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2378.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2378.jpg",
        "year": "2378",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2379.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2379.jpg",
        "year": "2379",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2380.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2380.jpg",
        "year": "2380",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2381.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2381.jpg",
        "year": "2381",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2382.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2382.jpg",
        "year": "2382",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2383.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2383.jpg",
        "year": "2383",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2384.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2384.jpg",
        "year": "2384",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2385.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2385.jpg",
        "year": "2385",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2386.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2386.jpg",
        "year": "2386",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2577.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2577.jpg",
        "year": "2577",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2613.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2613.jpg",
        "year": "2613",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2629.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2629.jpg",
        "year": "2629",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2655.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2655.jpg",
        "year": "2655",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2665.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2665.jpg",
        "year": "2665",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2719.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2719.jpg",
        "year": "2719",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2744.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2744.jpg",
        "year": "2744",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2745.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2745.jpg",
        "year": "2745",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_2746.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_2746.jpg",
        "year": "2746",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_3003.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_3003.jpg",
        "year": "3003",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_3487.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_3487.jpg",
        "year": "3487",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_3786.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_3786.jpg",
        "year": "3786",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_3889.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_3889.jpg",
        "year": "3889",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_4519.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_4519.jpg",
        "year": "4519",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_4651.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_4651.jpg",
        "year": "4651",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_4752.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_4752.jpg",
        "year": "4752",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_4758.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_4758.jpg",
        "year": "4758",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_5194.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_5194.jpg",
        "year": "5194",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_5202.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_5202.jpg",
        "year": "5202",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_5203.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_5203.jpg",
        "year": "5203",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_5210.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_5210.jpg",
        "year": "5210",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_5435.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_5435.jpg",
        "year": "5435",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_5673.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_5673.jpg",
        "year": "5673",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_6173.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_6173.jpg",
        "year": "6173",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_6329.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_6329.jpg",
        "year": "6329",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_6675.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_6675.jpg",
        "year": "6675",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Portraits/IMG_7875.jpg",
        "fullImage": "/art/fullsize/Portraits/IMG_7875.jpg",
        "year": "7875",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "sequential-comic-art",
    "name": "Sequential Comic Art",
    "images": [
      {
        "id": "frances-ivy-1",
        "title": "frances & ivy 1",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/frances & ivy 1_2021_Clip Studio Paint_unreleased.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/frances & ivy 1_2021_Clip Studio Paint_unreleased.jpg",
        "year": "2021",
        "medium": "Clip Studio Paint",
        "dimensions": "unreleased",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_0045.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_0045.jpg",
        "year": "0045",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_0332.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_0332.jpg",
        "year": "0332",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_0706.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_0706.jpg",
        "year": "0706",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_1551.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_1551.jpg",
        "year": "1551",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_1622.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_1622.jpg",
        "year": "1622",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2349.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2349.jpg",
        "year": "2349",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2374.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2374.jpg",
        "year": "2374",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2377.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2377.jpg",
        "year": "2377",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2401.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2401.jpg",
        "year": "2401",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2408 (1).jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2408 (1).jpg",
        "year": "2408 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2430.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2430.jpg",
        "year": "2430",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2438.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2438.jpg",
        "year": "2438",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2439.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2439.jpg",
        "year": "2439",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2470.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2470.jpg",
        "year": "2470",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2543.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2543.jpg",
        "year": "2543",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2545.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2545.jpg",
        "year": "2545",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2639.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2639.jpg",
        "year": "2639",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2662.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2662.jpg",
        "year": "2662",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2667.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2667.jpg",
        "year": "2667",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2736.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2736.jpg",
        "year": "2736",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2802.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2802.jpg",
        "year": "2802",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2841.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2841.jpg",
        "year": "2841",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2860.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2860.jpg",
        "year": "2860",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2861.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2861.jpg",
        "year": "2861",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_2935.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_2935.jpg",
        "year": "2935",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_3116 (1).jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_3116 (1).jpg",
        "year": "3116 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_3136.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_3136.jpg",
        "year": "3136",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_3165.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_3165.jpg",
        "year": "3165",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_3249.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_3249.jpg",
        "year": "3249",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_3458.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_3458.jpg",
        "year": "3458",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_3512.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_3512.jpg",
        "year": "3512",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_3513.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_3513.jpg",
        "year": "3513",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_3569.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_3569.jpg",
        "year": "3569",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_3604.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_3604.jpg",
        "year": "3604",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_3986.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_3986.jpg",
        "year": "3986",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4002.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4002.jpg",
        "year": "4002",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4021.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4021.jpg",
        "year": "4021",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4022.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4022.jpg",
        "year": "4022",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4208.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4208.jpg",
        "year": "4208",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4238.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4238.jpg",
        "year": "4238",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4304.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4304.jpg",
        "year": "4304",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4308.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4308.jpg",
        "year": "4308",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4367.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4367.jpg",
        "year": "4367",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4368 (1).jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4368 (1).jpg",
        "year": "4368 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4368.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4368.jpg",
        "year": "4368",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4382.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4382.jpg",
        "year": "4382",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4453.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4453.jpg",
        "year": "4453",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_4550.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_4550.jpg",
        "year": "4550",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_5153.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_5153.jpg",
        "year": "5153",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_6113.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_6113.jpg",
        "year": "6113",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_6114.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_6114.jpg",
        "year": "6114",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_6175 (1).jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_6175 (1).jpg",
        "year": "6175 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_6206.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_6206.jpg",
        "year": "6206",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_6789.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_6789.jpg",
        "year": "6789",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Sequential Comic Art/IMG_9304.jpg",
        "fullImage": "/art/fullsize/Sequential Comic Art/IMG_9304.jpg",
        "year": "9304",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "shitposting-art",
    "name": "Shitposting Art",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Shitposting Art/IMG_0214.jpg",
        "fullImage": "/art/fullsize/Shitposting Art/IMG_0214.jpg",
        "year": "0214",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Shitposting Art/IMG_0440.jpg",
        "fullImage": "/art/fullsize/Shitposting Art/IMG_0440.jpg",
        "year": "0440",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Shitposting Art/IMG_1858 (1).jpg",
        "fullImage": "/art/fullsize/Shitposting Art/IMG_1858 (1).jpg",
        "year": "1858 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Shitposting Art/IMG_2748.jpg",
        "fullImage": "/art/fullsize/Shitposting Art/IMG_2748.jpg",
        "year": "2748",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "storyboarding",
    "name": "Storyboarding",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Storyboarding/IMG_0682.jpg",
        "fullImage": "/art/fullsize/Storyboarding/IMG_0682.jpg",
        "year": "0682",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Storyboarding/IMG_0811.jpg",
        "fullImage": "/art/fullsize/Storyboarding/IMG_0811.jpg",
        "year": "0811",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Storyboarding/IMG_0812.jpg",
        "fullImage": "/art/fullsize/Storyboarding/IMG_0812.jpg",
        "year": "0812",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Storyboarding/IMG_0818.jpg",
        "fullImage": "/art/fullsize/Storyboarding/IMG_0818.jpg",
        "year": "0818",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Storyboarding/IMG_0819.jpg",
        "fullImage": "/art/fullsize/Storyboarding/IMG_0819.jpg",
        "year": "0819",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Storyboarding/IMG_0826.jpg",
        "fullImage": "/art/fullsize/Storyboarding/IMG_0826.jpg",
        "year": "0826",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "tattoo-illustration",
    "name": "Tattoo Illustration",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Tattoo Illustration/IMG_1293.jpg",
        "fullImage": "/art/fullsize/Tattoo Illustration/IMG_1293.jpg",
        "year": "1293",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Tattoo Illustration/IMG_1657.jpg",
        "fullImage": "/art/fullsize/Tattoo Illustration/IMG_1657.jpg",
        "year": "1657",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Tattoo Illustration/IMG_2196.jpg",
        "fullImage": "/art/fullsize/Tattoo Illustration/IMG_2196.jpg",
        "year": "2196",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Tattoo Illustration/IMG_3448.jpg",
        "fullImage": "/art/fullsize/Tattoo Illustration/IMG_3448.jpg",
        "year": "3448",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "tcg-illustration",
    "name": "TCG Illustration",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/TCG Illustration/IMG_0050.jpg",
        "fullImage": "/art/fullsize/TCG Illustration/IMG_0050.jpg",
        "year": "0050",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/TCG Illustration/IMG_0156.jpg",
        "fullImage": "/art/fullsize/TCG Illustration/IMG_0156.jpg",
        "year": "0156",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/TCG Illustration/IMG_0157.jpg",
        "fullImage": "/art/fullsize/TCG Illustration/IMG_0157.jpg",
        "year": "0157",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/TCG Illustration/IMG_0160.jpg",
        "fullImage": "/art/fullsize/TCG Illustration/IMG_0160.jpg",
        "year": "0160",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/TCG Illustration/IMG_0161.jpg",
        "fullImage": "/art/fullsize/TCG Illustration/IMG_0161.jpg",
        "year": "0161",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/TCG Illustration/IMG_0162.jpg",
        "fullImage": "/art/fullsize/TCG Illustration/IMG_0162.jpg",
        "year": "0162",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/TCG Illustration/IMG_0163.jpg",
        "fullImage": "/art/fullsize/TCG Illustration/IMG_0163.jpg",
        "year": "0163",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/TCG Illustration/IMG_0164 (1).jpg",
        "fullImage": "/art/fullsize/TCG Illustration/IMG_0164 (1).jpg",
        "year": "0164 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/TCG Illustration/IMG_0164.jpg",
        "fullImage": "/art/fullsize/TCG Illustration/IMG_0164.jpg",
        "year": "0164",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/TCG Illustration/IMG_0165.jpg",
        "fullImage": "/art/fullsize/TCG Illustration/IMG_0165.jpg",
        "year": "0165",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/TCG Illustration/IMG_0166 (1).jpg",
        "fullImage": "/art/fullsize/TCG Illustration/IMG_0166 (1).jpg",
        "year": "0166 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "thrashportraitz",
    "name": "THRASHPORTRAITZ",
    "images": [
      {
        "id": "british-girls",
        "title": "British Girls",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/British Girls_2017_Procreate_from instagram.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/British Girls_2017_Procreate_from instagram.jpg",
        "year": "2017",
        "medium": "Procreate",
        "dimensions": "from instagram",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_0512.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_0512.jpg",
        "year": "0512",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_0617 (1).jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_0617 (1).jpg",
        "year": "0617 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_1076.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_1076.jpg",
        "year": "1076",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_1077 (1).jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_1077 (1).jpg",
        "year": "1077 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_1363.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_1363.jpg",
        "year": "1363",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_1369.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_1369.jpg",
        "year": "1369",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_1380.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_1380.jpg",
        "year": "1380",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_1392.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_1392.jpg",
        "year": "1392",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_1394.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_1394.jpg",
        "year": "1394",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_1858.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_1858.jpg",
        "year": "1858",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_2704.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_2704.jpg",
        "year": "2704",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_3539.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_3539.jpg",
        "year": "3539",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_3701.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_3701.jpg",
        "year": "3701",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_6319.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_6319.jpg",
        "year": "6319",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_6651.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_6651.jpg",
        "year": "6651",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_6677.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_6677.jpg",
        "year": "6677",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_6894.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_6894.jpg",
        "year": "6894",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_7062 (1).jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_7062 (1).jpg",
        "year": "7062 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/THRASHPORTRAITZ/IMG_7062.jpg",
        "fullImage": "/art/fullsize/THRASHPORTRAITZ/IMG_7062.jpg",
        "year": "7062",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "toy-and-film-prop-design",
    "name": "Toy And Film Prop Design",
    "images": [
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Toy and Film Prop Design/IMG_0432.jpg",
        "fullImage": "/art/fullsize/Toy and Film Prop Design/IMG_0432.jpg",
        "year": "0432",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Toy and Film Prop Design/IMG_0484.jpg",
        "fullImage": "/art/fullsize/Toy and Film Prop Design/IMG_0484.jpg",
        "year": "0484",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Toy and Film Prop Design/IMG_0564 (1).jpg",
        "fullImage": "/art/fullsize/Toy and Film Prop Design/IMG_0564 (1).jpg",
        "year": "0564 (1)",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Toy and Film Prop Design/IMG_3261.jpg",
        "fullImage": "/art/fullsize/Toy and Film Prop Design/IMG_3261.jpg",
        "year": "3261",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Toy and Film Prop Design/IMG_4141.jpg",
        "fullImage": "/art/fullsize/Toy and Film Prop Design/IMG_4141.jpg",
        "year": "4141",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Toy and Film Prop Design/IMG_4227.jpg",
        "fullImage": "/art/fullsize/Toy and Film Prop Design/IMG_4227.jpg",
        "year": "4227",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Toy and Film Prop Design/IMG_4250.jpg",
        "fullImage": "/art/fullsize/Toy and Film Prop Design/IMG_4250.jpg",
        "year": "4250",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Toy and Film Prop Design/IMG_4257.jpg",
        "fullImage": "/art/fullsize/Toy and Film Prop Design/IMG_4257.jpg",
        "year": "4257",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Toy and Film Prop Design/IMG_6330.jpg",
        "fullImage": "/art/fullsize/Toy and Film Prop Design/IMG_6330.jpg",
        "year": "6330",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  },
  {
    "id": "videogame-art-and-ui",
    "name": "Videogame Art And UI",
    "images": [
      {
        "id": "animation54",
        "title": "Animation54",
        "thumbnail": "/art/thumbnails/Videogame Art and UI/Animation54.jpg",
        "fullImage": "/art/fullsize/Videogame Art and UI/Animation54.jpg",
        "year": "",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Videogame Art and UI/IMG_2275.jpg",
        "fullImage": "/art/fullsize/Videogame Art and UI/IMG_2275.jpg",
        "year": "2275",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Videogame Art and UI/IMG_2276.jpg",
        "fullImage": "/art/fullsize/Videogame Art and UI/IMG_2276.jpg",
        "year": "2276",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Videogame Art and UI/IMG_2278.jpg",
        "fullImage": "/art/fullsize/Videogame Art and UI/IMG_2278.jpg",
        "year": "2278",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Videogame Art and UI/IMG_4216.jpg",
        "fullImage": "/art/fullsize/Videogame Art and UI/IMG_4216.jpg",
        "year": "4216",
        "medium": "",
        "dimensions": "",
        "description": ""
      },
      {
        "id": "img",
        "title": "IMG",
        "thumbnail": "/art/thumbnails/Videogame Art and UI/IMG_4287.jpg",
        "fullImage": "/art/fullsize/Videogame Art and UI/IMG_4287.jpg",
        "year": "4287",
        "medium": "",
        "dimensions": "",
        "description": ""
      }
    ]
  }
];
