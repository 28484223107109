import React from 'react';

const Home: React.FC = () => (
  <div>
    <div className="kos-fieldset">
      <div className="kos-fieldset-legend">KoolSkull OS</div>
      <div style={{ padding: '0.5rem' }}>
        <h2 style={{ margin: '0 0 1rem' }}>Welcome</h2>
        <p>
          KOOL SKULL is an art project alter ego by Juan Larrazabal that started in 2007.
        </p>
        <p>
          Although many artists claim to have authored their work and concieved of their own creations, Juan Larrazabal credits the divine mind of GOD for all of his creative output.
        </p>
        <p>
         
        </p>
      </div>
    </div>
  </div>
);

export default Home; 