import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ArtInfoContextType {
  showArtInfo: boolean;
  toggleArtInfo: () => void;
}

const defaultContext: ArtInfoContextType = {
  showArtInfo: false, // Default to false (info hidden)
  toggleArtInfo: () => {},
};

export const ArtInfoContext = createContext<ArtInfoContextType>(defaultContext);

interface ArtInfoProviderProps {
  children: ReactNode;
}

export const ArtInfoProvider: React.FC<ArtInfoProviderProps> = ({ children }) => {
  const [showArtInfo, setShowArtInfo] = useState<boolean>(false);

  const toggleArtInfo = () => {
    setShowArtInfo(prev => !prev);
  };

  return (
    <ArtInfoContext.Provider value={{ showArtInfo, toggleArtInfo }}>
      {children}
    </ArtInfoContext.Provider>
  );
};

export const useArtInfo = () => useContext(ArtInfoContext); 