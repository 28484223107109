import React, { useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

interface TheatreJSProps {
  // Add any props if needed
}

const TheatreJS: React.FC<TheatreJSProps> = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modelLoaded, setModelLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const controlsRef = useRef<OrbitControls | null>(null);
  const animationFrameRef = useRef<number | null>(null);
  const modelRef = useRef<THREE.Object3D | null>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rotationSpeed, setRotationSpeed] = useState(0.01);
  const [autoRotate, setAutoRotate] = useState(true);
  const [wireframe, setWireframe] = useState(false);
  const [cameraReset, setCameraReset] = useState(false);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [showSequenceEditor, setShowSequenceEditor] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [totalFrames, setTotalFrames] = useState(100);
  const [keyframes, setKeyframes] = useState<number[]>([0, 25, 50, 75]);
  const [applyXRotation, setApplyXRotation] = useState(true);
  const xRotationValue = -0.21666; // The specific rotation value in radians
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const [scaleZ, setScaleZ] = useState(1);
  const [selectedObject, setSelectedObject] = useState<THREE.Object3D | null>(null);
  const [showOutliner, setShowOutliner] = useState(false);

  // Function to focus camera on the model
  const focusOnModel = () => {
    if (!modelRef.current || !cameraRef.current || !controlsRef.current) return;

    // Create a bounding box for the model
    const boundingBox = new THREE.Box3().setFromObject(modelRef.current);
    const center = new THREE.Vector3();
    boundingBox.getCenter(center);
    
    // Calculate the size of the bounding box
    const size = new THREE.Vector3();
    boundingBox.getSize(size);
    
    // Calculate the distance based on the size of the model
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = cameraRef.current.fov * (Math.PI / 180);
    let distance = maxDim / (2 * Math.tan(fov / 2));
    
    // Add some padding
    distance *= 1.5;
    
    // Position the camera
    const direction = new THREE.Vector3(0, 0, 1).normalize();
    const position = center.clone().add(direction.multiplyScalar(distance));
    
    cameraRef.current.position.copy(position);
    cameraRef.current.lookAt(center);
    
    // Update the orbit controls target
    controlsRef.current.target.copy(center);
    controlsRef.current.update();
    
    setCameraReset(false);
  };

  // Function to handle resize
  const handleResize = () => {
    if (!containerRef.current || !cameraRef.current || !rendererRef.current) return;
    
    const width = containerRef.current.clientWidth;
    const height = containerRef.current.clientHeight;
    
    // Update container size state
    setContainerSize({ width, height });
    
    // Update camera aspect ratio
    cameraRef.current.aspect = width / height;
    cameraRef.current.updateProjectionMatrix();
    
    // Update renderer size
    rendererRef.current.setSize(width, height);
  };

  // Function to toggle sequence editor visibility
  const toggleSequenceEditor = () => {
    setShowSequenceEditor(!showSequenceEditor);
    // Give the DOM time to update before handling resize
    setTimeout(handleResize, 50);
  };

  // Function to add a keyframe at the current position
  const addKeyframe = () => {
    if (!keyframes.includes(currentFrame)) {
      const newKeyframes = [...keyframes, currentFrame].sort((a, b) => a - b);
      setKeyframes(newKeyframes);
    }
  };

  // Function to remove a keyframe
  const removeKeyframe = (frame: number) => {
    const newKeyframes = keyframes.filter(kf => kf !== frame);
    setKeyframes(newKeyframes);
  };

  // Function to play/pause the animation
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      setAutoRotate(false); // Disable auto-rotation when playing
    }
  };

  // Function to toggle X-axis rotation
  const toggleXRotation = () => {
    setApplyXRotation(!applyXRotation);
    
    if (modelRef.current) {
      if (!applyXRotation) {
        // Apply the rotation
        modelRef.current.rotation.x = xRotationValue;
      } else {
        // Reset the rotation
        modelRef.current.rotation.x = 0;
      }
    }
  };

  // Function to toggle outliner visibility
  const toggleOutliner = () => {
    setShowOutliner(!showOutliner);
  };

  // Function to handle object selection
  const handleObjectSelection = (object: THREE.Object3D) => {
    setSelectedObject(object);
  };

  // Effect to handle animation playback
  useEffect(() => {
    if (!isPlaying) return;
    
    const interval = setInterval(() => {
      setCurrentFrame(prev => {
        if (prev >= totalFrames - 1) {
          return 0; // Loop back to start
        }
        return prev + 1;
      });
    }, 1000 / 30); // 30 fps
    
    return () => clearInterval(interval);
  }, [isPlaying, totalFrames]);

  // Effect to apply X rotation when the model is loaded or when applyXRotation changes
  useEffect(() => {
    if (!modelRef.current) return;
    
    if (applyXRotation) {
      modelRef.current.rotation.x = xRotationValue;
    } else {
      modelRef.current.rotation.x = 0;
    }
  }, [applyXRotation, modelLoaded]);

  // Effect to apply scale when the model is loaded or when scale changes
  useEffect(() => {
    if (!modelRef.current) return;
    modelRef.current.scale.set(scaleX, scaleY, scaleZ);
  }, [scaleX, scaleY, scaleZ, modelLoaded]);

  // Initialize Three.js scene
  useEffect(() => {
    if (!canvasRef.current || !containerRef.current) return;

    try {
      // Get initial container size
      const width = containerRef.current.clientWidth;
      const height = containerRef.current.clientHeight;
      setContainerSize({ width, height });

      // Create scene
      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0x000000);
      sceneRef.current = scene;

      // Create camera
      const aspect = width / height;
      const camera = new THREE.PerspectiveCamera(60, aspect, 0.1, 1000);
      // Initial camera position - will be adjusted when model loads
      camera.position.set(0, 1, 3);
      cameraRef.current = camera;

      // Create renderer
      const renderer = new THREE.WebGLRenderer({
        canvas: canvasRef.current,
        antialias: true,
      });
      renderer.setSize(width, height);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.shadowMap.enabled = true;
      rendererRef.current = renderer;

      // Add lights
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(5, 5, 5);
      directionalLight.castShadow = true;
      scene.add(directionalLight);

      // Add grid helper
      const gridHelper = new THREE.GridHelper(10, 10);
      scene.add(gridHelper);

      // Add orbit controls
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.autoRotate = autoRotate;
      controls.autoRotateSpeed = 1.0;
      controlsRef.current = controls;

      // Add fog
      scene.fog = new THREE.Fog(0x000000, 10, 20);

      // Load 3D model
      const loader = new GLTFLoader();
      
      // Try to load the model, but handle the case where it doesn't exist
      loader.load(
        '/glb/hc_head.glb',
        (gltf) => {
          const model = gltf.scene;
          
          // Center the model
          const box = new THREE.Box3().setFromObject(model);
          const center = new THREE.Vector3();
          box.getCenter(center);
          model.position.sub(center); // Center the model at origin
          
          // Apply the X-axis rotation if enabled
          if (applyXRotation) {
            model.rotation.x = xRotationValue;
          }
          
          scene.add(model);
          modelRef.current = model;
          setModelLoaded(true);
          setIsLoading(false);
          
          // Focus camera on the model after it's loaded
          setTimeout(() => {
            focusOnModel();
          }, 100);
        },
        (xhr) => {
          // Loading progress
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
        },
        (error) => {
          // Error loading model
          console.error('Error loading model:', error);
          
          // Create a fallback cube if model fails to load
          const geometry = new THREE.BoxGeometry(1, 1, 1);
          const material = new THREE.MeshStandardMaterial({ 
            color: 0x00ff00,
            wireframe: false
          });
          const cube = new THREE.Mesh(geometry, material);
          scene.add(cube);
          modelRef.current = cube;
          setModelLoaded(true);
          setIsLoading(false);
          
          // Position camera to view the cube
          camera.position.set(0, 1, 3);
          camera.lookAt(0, 0, 0);
          controls.target.set(0, 0, 0);
          controls.update();
        }
      );

      // Animation loop
      const animate = () => {
        animationFrameRef.current = requestAnimationFrame(animate);
        
        if (controlsRef.current) {
          controlsRef.current.autoRotate = autoRotate;
          controlsRef.current.update();
        }
        
        if (rendererRef.current && sceneRef.current && cameraRef.current) {
          rendererRef.current.render(sceneRef.current, cameraRef.current);
        }
      };
      
      animate();

      // Set up ResizeObserver to monitor container size changes
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === containerRef.current) {
            handleResize();
          }
        }
      });
      
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
        resizeObserverRef.current = resizeObserver;
      }
      
      // Handle window resize
      window.addEventListener('resize', handleResize);
      
      // Clean up
      return () => {
        window.removeEventListener('resize', handleResize);
        
        if (resizeObserverRef.current) {
          resizeObserverRef.current.disconnect();
        }
        
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
        }
        
        if (rendererRef.current) {
          rendererRef.current.dispose();
        }
        
        if (controlsRef.current) {
          controlsRef.current.dispose();
        }
        
        // Dispose of geometries and materials
        if (sceneRef.current) {
          sceneRef.current.traverse((object: any) => {
            if (object instanceof THREE.Mesh) {
              if (object.geometry) {
                object.geometry.dispose();
              }
              
              if (object.material) {
                if (Array.isArray(object.material)) {
                  object.material.forEach((material: any) => material.dispose());
                } else {
                  object.material.dispose();
                }
              }
            }
          });
        }
      };
    } catch (err) {
      console.error('Error initializing 3D scene:', err);
      setError('Failed to initialize 3D scene. Please try again.');
      setIsLoading(false);
    }
  }, [rotationSpeed, autoRotate, applyXRotation]);

  // Effect to update wireframe mode
  useEffect(() => {
    if (!modelRef.current) return;
    
    modelRef.current.traverse((object: any) => {
      if (object instanceof THREE.Mesh) {
        if (object.material) {
          if (Array.isArray(object.material)) {
            object.material.forEach((material: any) => {
              material.wireframe = wireframe;
            });
          } else {
            object.material.wireframe = wireframe;
          }
        }
      }
    });
  }, [wireframe, modelLoaded]);

  // Effect to reset camera when requested
  useEffect(() => {
    if (cameraReset && modelLoaded) {
      focusOnModel();
    }
  }, [cameraReset, modelLoaded]);

  // Effect to handle container size changes
  useEffect(() => {
    if (containerSize.width > 0 && containerSize.height > 0) {
      handleResize();
    }
  }, [containerSize]);

  // Effect to handle window resize from KoolSkullOS window system
  useEffect(() => {
    // This will trigger when the component is re-rendered due to parent window resize
    const checkSize = () => {
      if (containerRef.current) {
        const newWidth = containerRef.current.clientWidth;
        const newHeight = containerRef.current.clientHeight;
        
        if (newWidth !== containerSize.width || newHeight !== containerSize.height) {
          handleResize();
        }
      }
    };
    
    // Check size on each render
    checkSize();
    
    // Also set up a periodic check for size changes
    const intervalId = setInterval(checkSize, 500);
    
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Update effect to apply transforms to the selected object
  useEffect(() => {
    if (!selectedObject) return;
    selectedObject.scale.set(scaleX, scaleY, scaleZ);
    selectedObject.rotation.x = applyXRotation ? xRotationValue : 0;
  }, [selectedObject, scaleX, scaleY, scaleZ, applyXRotation]);

  // Render the timeline with keyframes
  const renderTimeline = () => {
    const timelineWidth = containerSize.width - 40; // Padding
    
    return (
      <div className="sequence-timeline">
        <div className="timeline-track">
          {Array.from({ length: totalFrames / 5 }).map((_, i) => (
            <div key={i} className="timeline-marker" style={{ left: `${(i * 5 * 100) / totalFrames}%` }}>
              <span className="marker-label">{i * 5}</span>
            </div>
          ))}
          
          {keyframes.map(frame => (
            <div 
              key={frame} 
              className="keyframe-marker"
              style={{ left: `${(frame * 100) / totalFrames}%` }}
              onClick={() => removeKeyframe(frame)}
              title={`Frame ${frame} (click to remove)`}
            />
          ))}
          
          <div 
            className="playhead"
            style={{ left: `${(currentFrame * 100) / totalFrames}%` }}
          />
        </div>
        
        <input 
          type="range"
          min="0"
          max={totalFrames - 1}
          value={currentFrame}
          onChange={(e) => setCurrentFrame(parseInt(e.target.value))}
          className="timeline-scrubber"
        />
      </div>
    );
  };

  return (
    <div className="theatre-container" ref={containerRef}>
      {error ? (
        <div className="theatre-error">
          <h3>Error</h3>
          <p>{error}</p>
          <div className="error-buttons">
            <button 
              className="kos-button kos-border"
              onClick={() => window.location.reload()}
            >
              Retry
            </button>
          </div>
        </div>
      ) : (
        <div className="theatre-content">
          <div className="theatre-main">
            <div className="theatre-toolbar">
              <div className="theatre-controls">
                <button 
                  className="kos-button kos-border"
                  onClick={() => setAutoRotate(!autoRotate)}
                >
                  {autoRotate ? 'Stop Rotation' : 'Start Rotation'}
                </button>
              </div>
              <div className="theatre-info">
                <span>Under Construction</span>
                {containerSize.width > 0 && (
                  <span style={{ marginLeft: '10px', fontSize: '10px' }}>
                    {containerSize.width}x{containerSize.height}
                  </span>
                )}
              </div>
            </div>
            <div className="theatre-canvas-container">
              {isLoading && (
                <div className="theatre-loading">
                  <div className="loading-spinner"></div>
                  <div className="loading-text">Loading 3D Model...</div>
                </div>
              )}
              <canvas ref={canvasRef} style={{ display: isLoading ? 'none' : 'block' }} />
            </div>
          </div>
          <div className="theatre-sidebar">
            {showOutliner && (
              <div className="outliner">
                <h5>Outliner</h5>
                <ul>
                  {sceneRef.current?.children.map((child, index) => (
                    <li key={index} onClick={() => handleObjectSelection(child)}>
                      {child.name || `Object ${index}`}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {showSequenceEditor && (
              <div className="sequence-editor">
                <div className="sequence-header">
                  <h4>Sequence Editor</h4>
                  <div className="sequence-controls">
                    <button 
                      className="kos-button kos-border"
                      onClick={togglePlay}
                    >
                      {isPlaying ? 'Pause' : 'Play'}
                    </button>
                    <button 
                      className="kos-button kos-border"
                      onClick={addKeyframe}
                      style={{ marginLeft: '5px' }}
                    >
                      Add Keyframe
                    </button>
                    <span className="frame-counter">
                      Frame: {currentFrame} / {totalFrames - 1}
                    </span>
                  </div>
                </div>
                {renderTimeline()}
                <div className="sequence-properties">
                  <div className="property-group">
                    <h5>Transform</h5>
                    <div className="property-row">
                      <label>Position X:</label>
                      <input type="range" min="-5" max="5" step="0.1" defaultValue="0" />
                      <span className="property-value">0.0</span>
                    </div>
                    <div className="property-row">
                      <label>Position Y:</label>
                      <input type="range" min="-5" max="5" step="0.1" defaultValue="0" />
                      <span className="property-value">0.0</span>
                    </div>
                    <div className="property-row">
                      <label>Position Z:</label>
                      <input type="range" min="-5" max="5" step="0.1" defaultValue="0" />
                      <span className="property-value">0.0</span>
                    </div>
                  </div>
                  <div className="property-group">
                    <h5>Rotation</h5>
                    <div className="property-row">
                      <label>Rotation X:</label>
                      <input 
                        type="range" 
                        min="-6.28" 
                        max="6.28" 
                        step="0.01" 
                        value={applyXRotation ? xRotationValue : 0}
                        readOnly
                      />
                      <span className="property-value">{applyXRotation ? xRotationValue.toFixed(2) : "0.00"}°</span>
                    </div>
                    <div className="property-row">
                      <label>Rotation Y:</label>
                      <input type="range" min="0" max="360" step="1" defaultValue="0" />
                      <span className="property-value">0°</span>
                    </div>
                    <div className="property-row">
                      <label>Rotation Z:</label>
                      <input type="range" min="0" max="360" step="1" defaultValue="0" />
                      <span className="property-value">0°</span>
                    </div>
                  </div>
                  <div className="property-group">
                    <h5>Scale</h5>
                    <div className="property-row">
                      <label>Scale X:</label>
                      <input 
                        type="range" 
                        min="0.1" 
                        max="5" 
                        step="0.1" 
                        value={scaleX}
                        onChange={(e) => setScaleX(parseFloat(e.target.value))}
                      />
                      <span className="property-value">{scaleX.toFixed(1)}</span>
                    </div>
                    <div className="property-row">
                      <label>Scale Y:</label>
                      <input 
                        type="range" 
                        min="0.1" 
                        max="5" 
                        step="0.1" 
                        value={scaleY}
                        onChange={(e) => setScaleY(parseFloat(e.target.value))}
                      />
                      <span className="property-value">{scaleY.toFixed(1)}</span>
                    </div>
                    <div className="property-row">
                      <label>Scale Z:</label>
                      <input 
                        type="range" 
                        min="0.1" 
                        max="5" 
                        step="0.1" 
                        value={scaleZ}
                        onChange={(e) => setScaleZ(parseFloat(e.target.value))}
                      />
                      <span className="property-value">{scaleZ.toFixed(1)}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="theatre-status-bar">
            <div className="status-item">
              <span className="status-label">Status:</span>
              {isLoading ? 'Loading...' : 'Ready'}
            </div>
            <div className="status-item">
              <span className="status-label">Mode:</span>
              {wireframe ? 'Wireframe' : 'Solid'}
            </div>
            <div className="status-item">
              <span className="status-label">X Rotation:</span>
              {applyXRotation ? `${xRotationValue.toFixed(2)} rad` : 'None'}
            </div>
            {!isLoading && (
              <div className="status-item">
                <span className="status-label">Controls:</span>
                <span>Left-click: Rotate | Right-click: Pan | Scroll: Zoom</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TheatreJS; 