import React from 'react';

const About: React.FC = () => (
  <div>
    <div className="kos-fieldset">
      <div className="kos-fieldset-legend">About KoolSkull</div>
      <div style={{ padding: '0.5rem' }}>
        <h2 style={{ margin: '0 0 1rem' }}>About Us</h2>
        <p>
          KoolSkull is a creative studio specializing in nostalgic digital experiences
          that blend retro aesthetics with modern web technologies.
        </p>
        <p>
          Our mission is to create unique digital experiences that evoke the feeling
          of using computers in the late 90s while providing modern functionality.
        </p>
        <p>
          Founded in 2025, we've been creating digital time capsules that transport
          users back to the golden era of personal computing.
        </p>
        <div style={{ marginTop: '1rem' }}>
          <button 
            className="kos-button kos-border"
            onClick={() => alert('History page coming soon!')}
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            Learn more about our history
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default About; 