import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';

const ADMIN_PASSWORD = "ButtholeFartm0nkeyyy";

const Admin: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  // Check if already authenticated in sessionStorage
  useEffect(() => {
    const authStatus = sessionStorage.getItem('adminAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);
  
  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (password === ADMIN_PASSWORD) {
      setIsAuthenticated(true);
      sessionStorage.setItem('adminAuthenticated', 'true');
      setErrorMessage('');
    } else {
      setErrorMessage('Incorrect password');
      // Clear password field
      setPassword('');
    }
  };
  
  if (isAuthenticated) {
    return <AdminPanel />;
  }
  
  return (
    <div className="admin-login kos-border-inset" style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      background: '#000000'
    }}>
      <div className="login-box kos-border" style={{
        padding: '20px',
        width: '320px',
        background: '#000000'
      }}>
        <div className="login-header kos-border" style={{
          padding: '6px 10px',
          marginBottom: '20px',
          background: '#000000',
          color: 'white',
          fontWeight: 'bold'
        }}>
          KOOLSKULL OS Admin Login
        </div>
        
        <form onSubmit={handleLogin}>
          <div style={{ marginBottom: '16px' }}>
            <label htmlFor="password" style={{ 
              display: 'block', 
              marginBottom: '8px',
              fontFamily: 'Courier New, monospace'
            }}>
              Enter Password:
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="kos-border-inset"
              style={{
                width: '100%',
                padding: '8px',
                fontFamily: 'Courier New, monospace',
                boxSizing: 'border-box'
              }}
              autoFocus
            />
          </div>
          
          {errorMessage && (
            <div style={{ 
              color: 'red', 
              marginBottom: '16px', 
              fontFamily: 'Courier New, monospace' 
            }}>
              {errorMessage}
            </div>
          )}
          
          <button 
            type="submit" 
            className="kos-button kos-border"
            style={{
              padding: '8px 16px',
              cursor: 'pointer',
              fontFamily: 'Courier New, monospace'
            }}
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Admin; 