import React from 'react';
import { MusicAlbum } from './MusicLibrary';

interface AlbumArtViewerProps {
  album: MusicAlbum | null;
  isMaximized?: boolean; // Add prop to detect when window is maximized
}

const AlbumArtViewer: React.FC<AlbumArtViewerProps> = ({ album, isMaximized = false }) => {
  if (!album) {
    return <div className="album-art-container empty">No album selected</div>;
  }

  // Determine which image to display based on maximized state
  // Use fullscreenUrl when maximized, fall back to largeUrl or coverUrl
  const imageUrl = isMaximized && album.fullscreenUrl 
    ? album.fullscreenUrl 
    : (album.largeUrl || album.coverUrl);
    
  if (!imageUrl) {
    return <div className="album-art-container empty">No album artwork available</div>;
  }

  // Add class if maximized to enable full-screen styling
  const contentClass = isMaximized ? "album-art-content maximized" : "album-art-content";

  return (
    <div className={contentClass}>
      <div className="album-art-container">
        <img 
          src={process.env.PUBLIC_URL + imageUrl} 
          alt={album.title} 
          className="album-art-image" 
          onError={(e) => {
            console.error(`Failed to load album art: ${imageUrl}`);
            const target = e.target as HTMLImageElement;
            target.style.display = 'none';
          }}
        />
        {!isMaximized && (
          <div className="album-info">
            <div className="album-title">{album.title}</div>
            <div className="album-artist">{album.artist}</div>
            <div className="album-year">{album.year}</div>
            <div className="album-tracks">{album.tracks.length} Tracks</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AlbumArtViewer; 