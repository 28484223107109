import React, { useState, useEffect } from 'react';

interface CryptoPrice {
  symbol: string;
  price: number;
  change24h: number;
}

const CryptoTicker: React.FC = () => {
  const [prices, setPrices] = useState<CryptoPrice[]>([
    { symbol: 'BTC', price: 0, change24h: 0 },
    { symbol: 'ETH', price: 0, change24h: 0 },
    { symbol: 'XMR', price: 0, change24h: 0 },
    { symbol: 'WOW', price: 0, change24h: 0 },
    { symbol: 'CULT', price: 0, change24h: 0 }
  ]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Add window resize listener
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        // Using CoinGecko API to fetch crypto prices
        const response = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,monero,wownero,milady-cult-coin&vs_currencies=usd&include_24hr_change=true'
        );
        
        if (!response.ok) {
          throw new Error('Failed to fetch crypto prices');
        }
        
        const data = await response.json();
        
        const updatedPrices: CryptoPrice[] = [
          { 
            symbol: 'BTC', 
            price: data.bitcoin?.usd || 0, 
            change24h: data.bitcoin?.usd_24h_change || 0 
          },
          { 
            symbol: 'ETH', 
            price: data.ethereum?.usd || 0, 
            change24h: data.ethereum?.usd_24h_change || 0 
          },
          { 
            symbol: 'XMR', 
            price: data.monero?.usd || 0, 
            change24h: data.monero?.usd_24h_change || 0 
          },
          { 
            symbol: 'WOW', 
            price: data.wownero?.usd || 0, 
            change24h: data.wownero?.usd_24h_change || 0 
          },
          { 
            symbol: 'CULT', 
            price: data['milady-cult-coin']?.usd || 0, 
            change24h: data['milady-cult-coin']?.usd_24h_change || 0 
          }
        ];
        
        setPrices(updatedPrices);
        setLoading(false);
        setError(null);
      } catch (err) {
        console.error('Error fetching crypto prices:', err);
        setError('API Error');
        setLoading(false);
        
        // Fallback to manual fetch for coins that might not be in CoinGecko
        try {
          // Try to fetch Wownero and CULT from alternative sources if needed
          const wowneroResponse = await fetch('https://api.coinpaprika.com/v1/tickers/wow-wownero');
          const cultResponse = await fetch('https://api.coinpaprika.com/v1/tickers/cult-milady-cult-coin');
          
          if (wowneroResponse.ok) {
            const wowneroData = await wowneroResponse.json();
            setPrices(prev => prev.map(coin => 
              coin.symbol === 'WOW' 
                ? { 
                    ...coin, 
                    price: wowneroData.quotes.USD.price, 
                    change24h: wowneroData.quotes.USD.percent_change_24h 
                  } 
                : coin
            ));
          }
          
          if (cultResponse.ok) {
            const cultData = await cultResponse.json();
            setPrices(prev => prev.map(coin => 
              coin.symbol === 'CULT' 
                ? { 
                    ...coin, 
                    price: cultData.quotes.USD.price, 
                    change24h: cultData.quotes.USD.percent_change_24h 
                  } 
                : coin
            ));
          }
          
          // If both fallbacks fail, try a direct fetch for CULT from CoinMarketCap data
          if (!cultResponse.ok) {
            // Based on the search results, CULT is around $0.0007771 with 8.96% change
            setPrices(prev => prev.map(coin => 
              coin.symbol === 'CULT' 
                ? { 
                    ...coin, 
                    price: 0.0007771, 
                    change24h: 8.96 
                  } 
                : coin
            ));
          }
        } catch (fallbackErr) {
          console.error('Error fetching from fallback sources:', fallbackErr);
        }
      }
    };

    // Fetch immediately on mount
    fetchPrices();
    
    // Then fetch every 60 seconds
    const intervalId = setInterval(fetchPrices, 60000);
    
    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  const formatPrice = (price: number): string => {
    return price.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: price < 10 ? 4 : 2
    });
  };

  const getChangeColor = (change: number): string => {
    return change >= 0 ? '#00FF00' : '#FF0000';
  };

  // Function to check if a coin has valid price data
  const hasValidData = (coin: CryptoPrice): boolean => {
    return coin.price > 0;
  };

  // Get visible coins based on window width
  const getVisibleCoins = () => {
    const validCoins = prices.filter(hasValidData);
    
    // Always return all valid coins regardless of window width
    // The CSS will handle dynamic display based on available space
    return validCoins;
  };

  return (
    <div className="crypto-ticker">
      {loading ? (
        <span>Loading...</span>
      ) : error ? (
        <span>{error}</span>
      ) : (
        <div className="crypto-prices">
          {getVisibleCoins().map((crypto) => (
            <div key={crypto.symbol} className="crypto-item">
              <span className="crypto-symbol">{crypto.symbol}:</span>
              <span className="crypto-price">{formatPrice(crypto.price)}</span>
              <span 
                className="crypto-change"
                style={{ color: getChangeColor(crypto.change24h) }}
              >
                {crypto.change24h > 0 ? '▲' : '▼'} 
                {Math.abs(crypto.change24h).toFixed(2)}%
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CryptoTicker; 