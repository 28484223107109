import React from 'react';

const Contact: React.FC = () => {
  return (
    <div>
      <div className="kos-fieldset">
        <div className="kos-fieldset-legend">say hello</div>
        <div style={{ padding: '1rem' }}>
          <p>I am certainly able to help you with any creative needs you may encounter. Feel free to contact me and discuss your needs so I can see what I can do!</p>
          <div className="contact-section" style={{ marginBottom: '1rem' }}>
            <div className="kos-border-inset" style={{ padding: '1rem', backgroundColor: 'var(--window-background)' }}>
              <h2>Juan Manuel Larrazabal</h2>
              <p style={{ marginBottom: '0.5rem' }}><strong>Email:</strong> juan@koolskull.com</p>
              <p style={{ marginBottom: '0.5rem' }}><strong>X:</strong> @Juanlarrazabal</p>
              <p style={{ marginBottom: '0.5rem' }}><strong>Instagram:</strong> @koolskull.archive</p>
              <p style={{ marginBottom: '0.5rem' }}><strong>Phone Number:</strong>323.673.5995</p>
            </div>
          </div>

          <div className="contact-section">
            <h3 style={{ marginBottom: '0.5rem' }}>Rates</h3>
            <div className="kos-border-inset" style={{ padding: '1rem', backgroundColor: 'var(--window-background)' }}>
              <p>Consultation: $4000/hr</p>
              <p>Live Musical Performance: $1200 plus travel expenses</p>
              <p>Live Portraits: $300/hr</p>
              <p>Corporate Projects: $300/hr w/ $3000 minimum</p>
              <p>Limited Budget/Friends/Personal: Negotiable</p>
            </div>
          </div>

          <div className="contact-section">
            <h3 style={{ marginBottom: '0.5rem' }}>Business Hours</h3>
            <div className="kos-border-inset" style={{ padding: '1rem', backgroundColor: 'var(--window-background)' }}>
              <p>Monday - Friday: 9:00 AM - 5:00 PM (PST)</p>
              <p>Weekend responses may be delayed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact; 