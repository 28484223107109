import React, { useState, useRef, useEffect } from 'react';
import './KoolSkullOS.css';
import WebampPlayer from './components/WebampPlayer';
import { MusicAlbum, MusicTrack } from './components/MusicLibrary';
import AlbumArtViewer from './components/AlbumArtViewer';
import LAClock from './components/LAClock';
import CryptoTicker from './components/CryptoTicker';
import TheatreJS from './components/TheatreJS';
import MusicLibrary from './components/MusicLibrary';
import ArtPortfolio from './components/ArtPortfolio';
import ArtViewer from './components/ArtViewer';
import CombinedArtViewer from './components/CombinedArtViewer';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Admin from './components/Admin';
import { ArtImage, ArtCategory } from './components/ArtPortfolio';
import { artPortfolioData } from './data/artPortfolioData';
import { ArtInfoProvider, useArtInfo } from './contexts/ArtInfoContext';

// Import logo
import logoImage from './icons/logo.png';

// Import icons
import homeIcon from './icons/home.png';
import aboutIcon from './icons/about.png';
import projectsIcon from './icons/projects.png';
import contactIcon from './icons/contact.png';
import musicIcon from './icons/music.png';
import discographyIcon from './icons/discography.png';
import studio3dIcon from './icons/3d-studio.png';
import clockIcon from './icons/clock.png';
import cryptoIcon from './icons/crypto.png';
import portfolioIcon from './icons/portfolio.png';

// Define our desktop icons
const desktopIcons = [
  { name: 'Home', path: '/', icon: homeIcon },
  { name: 'Contact', path: '/contact', icon: contactIcon },
  { name: 'Music', path: '/music', icon: musicIcon },
  { name: 'Discography', path: '/discography', icon: discographyIcon },
  { name: '3D Studio', path: '/3dstudio', icon: studio3dIcon },
  { name: 'Art Gallery', path: '/artgallery', icon: portfolioIcon }
];

// Window state interface
interface WindowState {
  id: string;
  position: { x: number; y: number };
  size: { width: number; height: number };
  isMinimized: boolean;
  isMaximized: boolean;
  defaultSize: { width: number; height: number };
  defaultPosition: { x: number; y: number };
  isMaximizing: boolean;
  isResizing: boolean;
  isOpen: boolean;
  zIndex: number;
}

// Add this at the top of the file, before any imports
declare global {
  interface Window {
    // Remove segaControlsWindow
    // segaControlsWindow: Window | null;
  }
}

// Add getNextZIndex function
const getNextZIndex = () => {
  return Date.now();
};

// Define a component for the wrapped ArtViewer
const ArtViewerWithContext: React.FC<{
  selectedImage: ArtImage | null;
  isMaximized?: boolean;
  allCategories?: ArtCategory[];
  onNavigate?: (image: ArtImage) => void;
}> = (props) => {
  return (
    <ArtInfoProvider>
      <ArtViewer {...props} />
    </ArtInfoProvider>
  );
};

// Define a component for the wrapped CombinedArtViewer
const CombinedArtViewerWithContext: React.FC<{ isMaximized?: boolean }> = ({ isMaximized }) => {
  return (
    <ArtInfoProvider>
      <CombinedArtViewer isMaximized={isMaximized} />
    </ArtInfoProvider>
  );
};

// Add a toggle button component for the taskbar
const ArtInfoToggle: React.FC = () => {
  const { showArtInfo, toggleArtInfo } = useArtInfo();
  
  return (
    <button 
      className="kos-button kos-border taskbar-button" 
      onClick={toggleArtInfo}
      title={showArtInfo ? "Hide art piece information" : "Show art piece information"}
      style={{ marginLeft: '5px' }}
    >
      {showArtInfo ? "Hide Art Info" : "Show Art Info"}
    </button>
  );
};

const KoolSkullOS: React.FC = () => {
  const [openWindows, setOpenWindows] = useState<string[]>([]);
  const [windowStates, setWindowStates] = useState<Record<string, WindowState>>({});
  const [startMenuOpen, setStartMenuOpen] = useState(false);
  const [activeWindow, setActiveWindow] = useState<string | null>(null);
  const [dragging, setDragging] = useState<string | null>(null);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [minimizedWindows, setMinimizedWindows] = useState<string[]>([]);
  const [resizing, setResizing] = useState<string | null>(null);
  const [initialSize, setInitialSize] = useState({ width: 0, height: 0 });
  const [initialMousePos, setInitialMousePos] = useState({ x: 0, y: 0 });
  const desktopRef = useRef<HTMLDivElement>(null);
  const [showWebamp, setShowWebamp] = useState(false);
  const [currentPlaylist, setCurrentPlaylist] = useState<MusicTrack[]>([]);
  const [currentAlbum, setCurrentAlbum] = useState<MusicAlbum | null>(null);
  const [selectedArtwork, setSelectedArtwork] = useState<ArtImage | null>(null);
  const [isAdminRoute, setIsAdminRoute] = useState<boolean>(false);
  
  // Check if we're on the admin route - moved before any early returns
  useEffect(() => {
    const isAdmin = window.location.pathname === '/admin';
    setIsAdminRoute(isAdmin);
  }, []);
  
  // Add touch event handling with passive: false
  useEffect(() => {
    const desktopElement = desktopRef.current;
    if (!desktopElement) return;

    // Function to handle touch move with passive: false
    const handleTouchMoveWithOptions = (e: TouchEvent) => {
      // Only prevent default if we're dragging or resizing
      if (dragging || resizing) {
        e.preventDefault();
      }
    };

    // Add event listener with passive: false
    desktopElement.addEventListener('touchmove', handleTouchMoveWithOptions, { 
      passive: false 
    });

    // Clean up
    return () => {
      desktopElement.removeEventListener('touchmove', handleTouchMoveWithOptions);
    };
  }, [dragging, resizing]);
  
  // Initialize window state when a new window is opened
  useEffect(() => {
    openWindows.forEach(windowName => {
      if (!windowStates[windowName]) {
        const index = openWindows.indexOf(windowName);
        const defaultPosition = { x: 50 + (index * 20), y: 50 + (index * 20) };
        const defaultSize = { width: 500, height: 400 };
        
        setWindowStates(prev => ({
          ...prev,
          [windowName]: {
            id: windowName,
            position: defaultPosition,
            size: defaultSize,
            isMinimized: false,
            isMaximized: false,
            defaultSize,
            defaultPosition,
            isMaximizing: false,
            isResizing: false,
            isOpen: false,
            zIndex: getNextZIndex()
          }
        }));
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openWindows]);
  
  // If we're on the admin route, render the Admin component
  if (isAdminRoute) {
    return <Admin />;
  }

  // Initialize window state when a new window is opened
  const openWindow = (title: string, componentName: string, width: number = 640, height: number = 480) => {
    setOpenWindows(prev => {
      // If the window is already open, don't add it again
      if (prev.includes(title)) {
        return prev;
      }
      return [...prev, title];
    });
    
    setWindowStates(prev => ({
      ...prev,
      [title]: {
        id: title,
        position: { x: 50 + (openWindows.length * 20), y: 50 + (openWindows.length * 20) },
        size: { width, height },
        isMinimized: false,
        isMaximized: false,
        defaultSize: { width, height },
        defaultPosition: { x: 50 + (openWindows.length * 20), y: 50 + (openWindows.length * 20) },
        isMaximizing: false,
        isResizing: false,
        isOpen: false,
        zIndex: getNextZIndex()
      }
    }));
    
    setActiveWindow(title);
  };

  // Function to handle opening the artwork viewer
  const openArtworkViewer = (image: ArtImage) => {
    setSelectedArtwork(image);
    const defaultSize = { width: 800, height: 600 };
    const defaultPosition = { x: 50, y: 50 };
    
    // Add to openWindows if not already there
    setOpenWindows(prev => {
      if (!prev.includes('Artwork Viewer')) {
        return [...prev, 'Artwork Viewer'];
      }
      return prev;
    });
    
    setWindowStates(prev => ({
      ...prev,
      'Artwork Viewer': {
        id: 'Artwork Viewer',
        position: defaultPosition,
        size: defaultSize,
        defaultSize: defaultSize,
        defaultPosition: defaultPosition,
        isMinimized: false,
        isMaximized: false,
        isMaximizing: false,
        isResizing: false,
        isOpen: true,
        zIndex: getNextZIndex()
      }
    }));
    
    // Set as active window
    setActiveWindow('Artwork Viewer');
  };

  const handleCloseWindow = (windowName: string) => {
    setWindowStates(prev => ({
      ...prev,
      [windowName]: {
        ...prev[windowName],
        isOpen: false
      }
    }));
    
    // Clear selected artwork when closing the viewer
    if (windowName === 'Artwork Viewer') {
      setSelectedArtwork(null);
    }
  };

  // Handle selecting an album from the discography
  const handleSelectAlbum = (album: MusicAlbum) => {
    // First update the current album and playlist
    setCurrentAlbum(album);
    setCurrentPlaylist(album.tracks);
    
    // If Webamp is already showing, we need to close and reopen it
    // to ensure it reinitializes with the new tracks
    if (showWebamp) {
      // Use a small timeout to ensure state updates properly
      setShowWebamp(false);
      setTimeout(() => {
        setShowWebamp(true);
      }, 50);
    } else {
      // Just open Webamp with the selected playlist
      setShowWebamp(true);
    }

    // Create a new window for album art
    const albumArtWindowName = 'AlbumArtViewer';
    
    // Close the existing album art window if it's open
    if (openWindows.includes(albumArtWindowName)) {
      // Remove from open windows list
      setOpenWindows(prev => prev.filter(name => name !== albumArtWindowName));
      // Also remove from minimized windows if it was minimized
      setMinimizedWindows(prev => prev.filter(name => name !== albumArtWindowName));
    }
    
    // Only show album art window if the album has art
    if (album.largeUrl || album.coverUrl || album.thumbUrl) {
      // Short delay to ensure the old window is fully removed before creating a new one
      setTimeout(() => {
        // Add to open windows
        setOpenWindows(prev => [...prev, albumArtWindowName]);
        
        // Position the window to the right of center (where Webamp typically is)
        const initialPosition = { 
          x: Math.max(50, Math.floor(window.innerWidth / 2) + 100),
          y: Math.max(50, Math.floor(window.innerHeight / 2) - 150) 
        };
        
        // Set window state
        setWindowStates(prev => ({
          ...prev,
          [albumArtWindowName]: {
            id: albumArtWindowName,
            position: initialPosition,
            size: { width: 550, height: 450 },
            isMinimized: false,
            isMaximized: false,
            defaultSize: { width: 550, height: 450 },
            defaultPosition: initialPosition,
            isMaximizing: false,
            isResizing: false,
            isOpen: false,
            zIndex: getNextZIndex()
          }
        }));
      }, 50);
    }
  };

  // Dynamically render the MusicLibrary component with the handleSelectAlbum prop
  const renderMusicLibrary = () => {
    return <MusicLibrary onAlbumClick={handleSelectAlbum} />;
  };

  // Define the componentsMap after the state and functions are available
  const componentsMap: { [key: string]: React.ReactNode } = {
    'Home': <Home />,
    'About': <About />,
    'Projects': <Projects />,
    'Contact': <Contact />,
    'Music': <WebampPlayer />,
    'Discography': <MusicLibrary onAlbumClick={handleSelectAlbum} />,
    '3D Studio': <TheatreJS />,
    'Art Gallery': <CombinedArtViewerWithContext isMaximized={windowStates['Art Gallery']?.isMaximized} />,
    'Artwork Viewer': windowStates['Artwork Viewer']?.isOpen && selectedArtwork ? (
      <ArtViewerWithContext
        selectedImage={selectedArtwork}
        isMaximized={windowStates['Artwork Viewer']?.isMaximized}
        allCategories={artPortfolioData}
        onNavigate={openArtworkViewer}
      />
    ) : null,
    'Clock': <LAClock />,
    'Crypto': <CryptoTicker />,
    'Admin': <Admin />
  };

  // Update the getComponentForWindow function
  const getComponentForWindow = (windowName: string) => {
    switch (windowName) {
      case 'Home':
        return <Home />;
      case 'About':
        return <About />;
      case 'Projects':
        return <Projects />;
      case 'Contact':
        return <Contact />;
      case 'Music':
        return <WebampPlayer />;
      case 'Discography':
        return renderMusicLibrary();
      case '3D Studio':
        return <TheatreJS />;
      case 'AlbumArtViewer':
        return currentAlbum ? (
          <AlbumArtViewer 
            album={currentAlbum} 
            isMaximized={windowStates[windowName]?.isMaximized} 
          />
        ) : (
          <div>No album selected</div>
        );
      case 'Art Gallery':
        return <CombinedArtViewerWithContext isMaximized={windowStates[windowName]?.isMaximized} />;
      case 'Artwork Viewer':
        return <ArtViewerWithContext 
          selectedImage={selectedArtwork} 
          isMaximized={windowStates[windowName]?.isMaximized}
          allCategories={artPortfolioData}
          onNavigate={openArtworkViewer}
        />;
      case 'Clock':
        return <LAClock />;
      case 'Crypto':
        return <CryptoTicker />;
      case 'Admin':
        return <Admin />;
      default:
        return <div>Window content not found</div>;
    }
  };

  const toggleWindow = (iconName: string) => {
    if (iconName === 'Music') {
      if (showWebamp) {
        setShowWebamp(false);
      } else {
        // If we have a current album, use its tracks, otherwise use default
        setShowWebamp(true);
      }
      return;
    }

    if (iconName === 'Discography') {
      if (!openWindows.includes(iconName)) {
        // Open the window if it's not already open
        setOpenWindows([...openWindows, iconName]);
        setActiveWindow(iconName);
      } else if (minimizedWindows.includes(iconName)) {
        // If window is minimized, restore it
        setMinimizedWindows(minimizedWindows.filter(name => name !== iconName));
        setActiveWindow(iconName);
      } else {
        // Close the window if it's already open and not minimized
        setOpenWindows(openWindows.filter(name => name !== iconName));
        
        // Update active window if needed
        if (activeWindow === iconName) {
          const otherWindows = openWindows.filter(
            name => name !== iconName && !minimizedWindows.includes(name)
          );
          setActiveWindow(otherWindows.length > 0 ? otherWindows[0] : null);
        }
      }
      return;
    }

    if (openWindows.includes(iconName)) {
      setOpenWindows(openWindows.filter(name => name !== iconName));
      setMinimizedWindows(minimizedWindows.filter(name => name !== iconName));
      
      if (activeWindow === iconName) {
        setActiveWindow(openWindows.length > 1 ? openWindows[0] : null);
      }
    } else {
      setOpenWindows([...openWindows, iconName]);
      setActiveWindow(iconName);
    }
  };

  const toggleStartMenu = () => {
    setStartMenuOpen(!startMenuOpen);
  };

  const setWindowActive = (windowName: string) => {
    setActiveWindow(windowName);
  };

  const handleMouseDown = (e: React.MouseEvent, windowName: string) => {
    // Only start dragging if it's the window header and not a button
    if ((e.target as HTMLElement).closest('.window-button')) {
      return;
    }
    
    setActiveWindow(windowName);
    setDragging(windowName);
    
    const windowElement = (e.target as HTMLElement).closest('.window') as HTMLElement;
    if (windowElement) {
      const rect = windowElement.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });
    }
  };

  // New handler for touch events
  const handleTouchStart = (e: React.TouchEvent, windowName: string) => {
    // Only start dragging if it's the window header and not a button
    if ((e.target as HTMLElement).closest('.window-button')) {
      return;
    }
    
    setActiveWindow(windowName);
    setDragging(windowName);
    
    const windowElement = (e.target as HTMLElement).closest('.window') as HTMLElement;
    if (windowElement) {
      const rect = windowElement.getBoundingClientRect();
      const touch = e.touches[0];
      setDragOffset({
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top
      });
    }
  };

  const handleResizeStart = (e: React.MouseEvent, windowName: string) => {
    e.stopPropagation();
    e.preventDefault();
    
    if (windowStates[windowName]?.isMaximized) return;
    
    setActiveWindow(windowName);
    setResizing(windowName);
    
    // Store initial window size and mouse position
    setInitialSize({
      width: windowStates[windowName].size.width,
      height: windowStates[windowName].size.height
    });
    setInitialMousePos({
      x: e.clientX,
      y: e.clientY
    });
    
    // Set resizing flag in window state
    setWindowStates(prev => ({
      ...prev,
      [windowName]: {
        ...prev[windowName],
        isResizing: true
      }
    }));
  };

  // New handler for touch resize
  const handleResizeTouchStart = (e: React.TouchEvent, windowName: string) => {
    e.stopPropagation();
    
    if (windowStates[windowName]?.isMaximized) return;
    
    setActiveWindow(windowName);
    setResizing(windowName);
    
    // Store initial window size and touch position
    setInitialSize({
      width: windowStates[windowName].size.width,
      height: windowStates[windowName].size.height
    });
    
    const touch = e.touches[0];
    setInitialMousePos({
      x: touch.clientX,
      y: touch.clientY
    });
    
    // Set resizing flag in window state
    setWindowStates(prev => ({
      ...prev,
      [windowName]: {
        ...prev[windowName],
        isResizing: true
      }
    }));
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    // Handle dragging
    if (dragging && !windowStates[dragging]?.isMaximized) {
      const desktop = desktopRef.current;
      if (desktop) {
        const desktopRect = desktop.getBoundingClientRect();
        const taskbarHeight = 28; // Height of the taskbar
        
        // Calculate new position
        let newX = e.clientX - dragOffset.x;
        let newY = e.clientY - dragOffset.y;
        
        // Constrain to desktop bounds
        newX = Math.max(0, Math.min(newX, desktopRect.width - windowStates[dragging].size.width));
        newY = Math.max(0, Math.min(newY, desktopRect.height - windowStates[dragging].size.height - taskbarHeight));
        
        setWindowStates(prev => ({
          ...prev,
          [dragging]: {
            ...prev[dragging],
            position: { x: newX, y: newY },
            isMaximizing: false // Ensure no transition during drag
          }
        }));
      }
    }
    
    // Handle resizing
    if (resizing && !windowStates[resizing]?.isMaximized) {
      const desktop = desktopRef.current;
      if (desktop) {
        const desktopRect = desktop.getBoundingClientRect();
        const taskbarHeight = 28; // Height of the taskbar
        
        // Calculate new size based on mouse movement
        const deltaX = e.clientX - initialMousePos.x;
        const deltaY = e.clientY - initialMousePos.y;
        
        let newWidth = Math.max(200, initialSize.width + deltaX); // Minimum width of 200px
        let newHeight = Math.max(150, initialSize.height + deltaY); // Minimum height of 150px
        
        // Constrain to desktop bounds
        const maxWidth = desktopRect.width - windowStates[resizing].position.x;
        const maxHeight = desktopRect.height - windowStates[resizing].position.y - taskbarHeight;
        
        newWidth = Math.min(newWidth, maxWidth);
        newHeight = Math.min(newHeight, maxHeight);
        
        setWindowStates(prev => ({
          ...prev,
          [resizing]: {
            ...prev[resizing],
            size: { width: newWidth, height: newHeight }
          }
        }));
      }
    }
  };

  // Update the handleTouchMove to not call preventDefault (will be handled by the non-passive listener)
  const handleTouchMove = (e: React.TouchEvent) => {
    // Handle dragging
    if (dragging && !windowStates[dragging]?.isMaximized) {
      const desktop = desktopRef.current;
      if (desktop) {
        const desktopRect = desktop.getBoundingClientRect();
        const taskbarHeight = 28; // Height of the taskbar
        const touch = e.touches[0];
        
        // Calculate new position
        let newX = touch.clientX - dragOffset.x;
        let newY = touch.clientY - dragOffset.y;
        
        // Constrain to desktop bounds
        newX = Math.max(0, Math.min(newX, desktopRect.width - windowStates[dragging].size.width));
        newY = Math.max(0, Math.min(newY, desktopRect.height - windowStates[dragging].size.height - taskbarHeight));
        
        setWindowStates(prev => ({
          ...prev,
          [dragging]: {
            ...prev[dragging],
            position: { x: newX, y: newY },
            isMaximizing: false // Ensure no transition during drag
          }
        }));
      }
    }
    
    // Handle resizing
    if (resizing && !windowStates[resizing]?.isMaximized) {
      const desktop = desktopRef.current;
      if (desktop) {
        const desktopRect = desktop.getBoundingClientRect();
        const taskbarHeight = 28; // Height of the taskbar
        const touch = e.touches[0];
        
        // Calculate new size based on touch movement
        const deltaX = touch.clientX - initialMousePos.x;
        const deltaY = touch.clientY - initialMousePos.y;
        
        let newWidth = Math.max(200, initialSize.width + deltaX); // Minimum width of 200px
        let newHeight = Math.max(150, initialSize.height + deltaY); // Minimum height of 150px
        
        // Constrain to desktop bounds
        const maxWidth = desktopRect.width - windowStates[resizing].position.x;
        const maxHeight = desktopRect.height - windowStates[resizing].position.y - taskbarHeight;
        
        newWidth = Math.min(newWidth, maxWidth);
        newHeight = Math.min(newHeight, maxHeight);
        
        setWindowStates(prev => ({
          ...prev,
          [resizing]: {
            ...prev[resizing],
            size: { width: newWidth, height: newHeight }
          }
        }));
      }
    }
  };

  const handleMouseUp = () => {
    // Clear dragging state
    setDragging(null);
    
    // Clear resizing state
    if (resizing) {
      setWindowStates(prev => ({
        ...prev,
        [resizing]: {
          ...prev[resizing],
          isResizing: false
        }
      }));
      setResizing(null);
    }
  };

  // New handler for touch end
  const handleTouchEnd = () => {
    // Clear dragging state
    setDragging(null);
    
    // Clear resizing state
    if (resizing) {
      setWindowStates(prev => ({
        ...prev,
        [resizing]: {
          ...prev[resizing],
          isResizing: false
        }
      }));
      setResizing(null);
    }
  };

  const minimizeWindow = (windowName: string) => {
    setMinimizedWindows([...minimizedWindows, windowName]);
    
    // Set next window as active if this was the active one
    if (activeWindow === windowName) {
      const otherWindows = openWindows.filter(
        name => name !== windowName && !minimizedWindows.includes(name)
      );
      setActiveWindow(otherWindows.length > 0 ? otherWindows[0] : null);
    }
  };

  const maximizeWindow = (windowName: string) => {
    // Set isMaximizing to true to apply transition
    setWindowStates(prev => ({
      ...prev,
      [windowName]: {
        ...prev[windowName],
        isMaximizing: true
      }
    }));
    
    // Small delay to ensure the class is applied before changing dimensions
    setTimeout(() => {
      setWindowStates(prev => ({
        ...prev,
        [windowName]: {
          ...prev[windowName],
          isMaximized: !prev[windowName].isMaximized,
          // If we're un-maximizing, restore to default position and size
          position: prev[windowName].isMaximized ? prev[windowName].defaultPosition : { x: 0, y: 0 },
          size: prev[windowName].isMaximized ? prev[windowName].defaultSize : {
            width: desktopRef.current?.clientWidth || window.innerWidth,
            height: (desktopRef.current?.clientHeight || window.innerHeight) - 28 // Subtract taskbar height
          }
        }
      }));
      
      // Remove the transition class after animation completes
      setTimeout(() => {
        setWindowStates(prev => ({
          ...prev,
          [windowName]: {
            ...prev[windowName],
            isMaximizing: false
          }
        }));
      }, 200); // Slightly longer than the CSS transition duration
    }, 10);
  };

  // Add a handler for when Webamp is closed
  const handleWebampClose = () => {
    console.log('Webamp closed from KoolSkullOS');
    
    setShowWebamp(false);
  };

  return (
    <div 
      className="desktop" 
      ref={desktopRef}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="desktop-content">
        {/* Logo in top left */}
        <div className="desktop-logo">
          <img 
            src={logoImage} 
            alt="KoolSkull Logo" 
            className="logo-image"
          />
        </div>

        {/* Desktop Icons */}
        <div className="desktop-icons">
          {desktopIcons.map((icon) => (
            <div 
              key={icon.name}
              className={`desktop-icon ${
                icon.name === 'Music' 
                  ? (showWebamp ? 'active' : '') 
                  : (openWindows.includes(icon.name) && !minimizedWindows.includes(icon.name) ? 'active' : '')
              }`}
              onClick={() => toggleWindow(icon.name)}
              title={icon.name}
            >
              <div className="icon-image">
                <img 
                  src={icon.icon} 
                  alt={icon.name} 
                  width="64" 
                  height="64" 
                  style={{ display: 'block' }}
                />
              </div>
              <span className="icon-text">{icon.name}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Webamp Player - Render directly in the desktop when showWebamp is true */}
      {showWebamp && (
        <WebampPlayer 
          key={currentAlbum ? `webamp-${currentAlbum.id}` : 'webamp-default'} 
          onClose={handleWebampClose} 
          tracks={currentPlaylist.length > 0 ? currentPlaylist : undefined} 
        />
      )}

      {/* Windows */}
      {openWindows.map((windowName) => {
        if (windowName === 'Music') return null;
        
        const windowState = windowStates[windowName];
        
        // Skip rendering minimized windows
        if (minimizedWindows.includes(windowName)) {
          return null;
        }
        
        if (!windowState) {
          return null; // Skip if window state is not initialized yet
        }
        
        return (
          <div
            key={windowName}
            className={`window kos-border ${windowState.isMaximizing ? 'maximizing' : ''} ${windowState.isResizing ? 'resizing' : ''}`}
            style={{
              top: windowState.position.y,
              left: windowState.position.x,
              width: windowState.size.width,
              height: windowState.size.height,
              zIndex: windowName === activeWindow ? 10 : 5
            }}
            onClick={() => setWindowActive(windowName)}
          >
            <div 
              className={`window-header ${windowName === activeWindow ? 'active' : 'inactive'}`}
              onMouseDown={(e) => handleMouseDown(e, windowName)}
              onTouchStart={(e) => handleTouchStart(e, windowName)}
              onDoubleClick={() => maximizeWindow(windowName)}
              onTouchEnd={(e) => {
                // Handle double tap with a 300ms window
                const now = new Date().getTime();
                const timeSince = now - (window as any).lastTap || 0;
                
                if (timeSince < 300 && timeSince > 0) {
                  e.preventDefault();
                  maximizeWindow(windowName);
                }
                
                (window as any).lastTap = now;
              }}
            >
              <div className="window-title">
                {windowName === 'AlbumArtViewer' && currentAlbum 
                  ? `Album Art: ${currentAlbum.title}` 
                  : windowName}
              </div>
              <div className="window-controls">
                <div 
                  className="window-button kos-border" 
                  title="Minimize"
                  onClick={(e) => {
                    e.stopPropagation();
                    minimizeWindow(windowName);
                  }}
                >
                  _
                </div>
                <div 
                  className="window-button kos-border" 
                  title="Maximize"
                  onClick={(e) => {
                    e.stopPropagation();
                    maximizeWindow(windowName);
                  }}
                >
                  {windowState.isMaximized ? '❐' : '□'}
                </div>
                <div 
                  className="window-button kos-border" 
                  title="Close"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleWindow(windowName);
                  }}
                >
                  X
                </div>
              </div>
            </div>
            <div className="window-content">
              {getComponentForWindow(windowName)}
            </div>
            {!windowState.isMaximized && (
              <div 
                className="resize-handle"
                onMouseDown={(e) => handleResizeStart(e, windowName)}
                onTouchStart={(e) => handleResizeTouchStart(e, windowName)}
              />
            )}
          </div>
        );
      })}

      {/* Taskbar */}
      <div className="taskbar kos-border">
        <div className="taskbar-start">
          <button className="start-button kos-button kos-border" onClick={toggleStartMenu}>
            <span>Start</span>
          </button>
        </div>
        
        {startMenuOpen && (
          <div className="start-menu kos-border">
            {desktopIcons.map((icon) => (
              <div 
                key={icon.name} 
                className="start-menu-item"
                onClick={() => {
                  toggleWindow(icon.name);
                  toggleStartMenu();
                }}
              >
                {icon.name}
              </div>
            ))}
            <div className="start-menu-divider"></div>
            <div className="start-menu-item">Shut Down...</div>
          </div>
        )}
        
        <div className="taskbar-buttons">
          {/* Add ArtInfoToggle when Art Gallery is open */}
          {(openWindows.includes('Art Gallery') || openWindows.includes('Artwork Viewer')) && (
            <ArtInfoProvider>
              <ArtInfoToggle />
            </ArtInfoProvider>
          )}
          
          {showWebamp && (
            <div 
              className={`taskbar-button kos-border active`}
              onClick={() => setShowWebamp(!showWebamp)}
            >
              {currentAlbum ? `Music - ${currentAlbum.title}` : 'Music'}
            </div>
          )}
          {openWindows.map((windowName) => {
            if (windowName === 'Music') return null;
            
            return (
              <div 
                key={windowName} 
                className={`taskbar-button kos-border ${windowName === activeWindow && !minimizedWindows.includes(windowName) ? 'active' : ''}`}
                onClick={() => {
                  if (minimizedWindows.includes(windowName)) {
                    setMinimizedWindows(minimizedWindows.filter(name => name !== windowName));
                  }
                  setWindowActive(windowName);
                }}
              >
                {windowName}
              </div>
            );
          })}
        </div>
        
        <div className="taskbar-right">
          {/* Crypto Ticker */}
          <CryptoTicker />
          
          {/* Los Angeles Clock */}
          <LAClock />
        </div>
      </div>
    </div>
  );
};

export default KoolSkullOS; 