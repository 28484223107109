import React, { useEffect, useRef } from 'react';
import Webamp from 'webamp';
import { MusicTrack } from './MusicLibrary';

// Default tracks if no playlist is provided
const DEFAULT_TRACKS = [
  {
    url: 'https://cdn.jsdelivr.net/gh/captbaritone/webamp@43434d82/demo/track.mp3',
    duration: 5.322286,
    metaData: {
      artist: 'DJ Mike Llama',
      title: 'Llama Whippin\' Intro',
    },
  }
];

interface WebampPlayerProps {
  className?: string;
  onClose?: () => void;
  tracks?: MusicTrack[];
}

const WebampPlayer: React.FC<WebampPlayerProps> = ({ className, onClose, tracks = DEFAULT_TRACKS }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const webampRef = useRef<any>(null);
  const tracksRef = useRef(tracks);

  // Clean up function to properly dispose of Webamp
  const cleanupWebamp = () => {
    if (webampRef.current) {
      try {
        webampRef.current.dispose();
        webampRef.current = null;
      } catch (e) {
        console.error('Error disposing Webamp:', e);
      }
    }
  };

  // Initialize Webamp with the given tracks
  const initializeWebamp = () => {
    // Check if browser supports Webamp
    if (!Webamp.browserIsSupported()) {
      alert('Your browser does not support Webamp. Please try a different browser.');
      return;
    }

    // Clean up any existing instance first
    cleanupWebamp();

    // Initialize Webamp with options
    const webamp = new Webamp({
      initialTracks: tracksRef.current,
      // @ts-ignore - Using any type to bypass TypeScript checking for undocumented properties
      zIndex: 1000, // Ensure Webamp is above other elements
      initialSkin: {
        url: process.env.PUBLIC_URL + '/webampskins/BlackSimple.wsz'
      },
      availableSkins: [
        { url: process.env.PUBLIC_URL + '/webampskins/BlackSimple.wsz', name: 'Black Simple' },
        { url: process.env.PUBLIC_URL + '/webampskins/Chemical_Reaction.wsz', name: 'Chemical Reaction' },
        { url: 'https://cdn.jsdelivr.net/gh/captbaritone/webamp@43434d82/demo/skins/base-2.91.wsz', name: 'Base' },
        { url: 'https://cdn.jsdelivr.net/gh/captbaritone/webamp@43434d82/demo/skins/Green-Dimension-V2.wsz', name: 'Green Dimension V2' },
        { url: 'https://cdn.jsdelivr.net/gh/captbaritone/webamp@43434d82/demo/skins/Skinner_Atlas.wsz', name: 'Skinner Atlas' },
        { url: 'https://cdn.jsdelivr.net/gh/captbaritone/webamp@43434d82/demo/skins/TopazAmp1-2.wsz', name: 'TopazAmp' },
        { url: 'https://cdn.jsdelivr.net/gh/captbaritone/webamp@43434d82/demo/skins/Vizor1000.wsz', name: 'Vizor' },
        { url: 'https://cdn.jsdelivr.net/gh/captbaritone/webamp@43434d82/demo/skins/XMMS-Turquoise.wsz', name: 'XMMS Turquoise' },
        { url: 'https://cdn.jsdelivr.net/gh/captbaritone/webamp@43434d82/demo/skins/ZaxonRemake1-0.wsz', name: 'Zaxon Remake' },
      ]
    });

    // Store the webamp instance
    webampRef.current = webamp;

    // Handle close event - toggle the Music button when Webamp is closed
    webamp.onClose(() => {
      // Call the onClose callback if provided
      if (onClose) {
        onClose();
      }
      console.log('Webamp closed');
      
      // Clean up the Webamp instance
      cleanupWebamp();
      
      return true; // Return true to allow closing
    });

    // Render Webamp
    if (containerRef.current) {
      try {
        webamp.renderWhenReady(containerRef.current).then(() => {
          // Center the player on the screen
          try {
            // Use any type to access undocumented properties
            const webampAny = webamp as any;
            const mainWindow = webampAny.store.getState().windows.mainWindow;
            if (mainWindow) {
              const screenWidth = window.innerWidth;
              const screenHeight = window.innerHeight;
              const playerWidth = 275; // Default Winamp width
              const playerHeight = 116; // Default Winamp height
              
              webampAny.store.dispatch({
                type: 'UPDATE_WINDOW_POSITION',
                windowId: 'mainWindow',
                position: {
                  x: Math.max(0, (screenWidth - playerWidth) / 2),
                  y: Math.max(0, (screenHeight - playerHeight) / 3)
                }
              });
            }
          } catch (e) {
            console.error('Error positioning Webamp:', e);
          }
        }).catch(e => {
          console.error('Error rendering Webamp:', e);
        });
      } catch (e) {
        console.error('Error initializing Webamp:', e);
      }
    }
  };

  // Effect to update tracksRef when tracks prop changes
  useEffect(() => {
    tracksRef.current = tracks;
  }, [tracks]);

  // Effect to initialize Webamp when the component mounts
  useEffect(() => {
    initializeWebamp();
    
    // Cleanup function
    return () => {
      cleanupWebamp();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array means this runs once on mount

  // Effect to reinitialize Webamp when tracks change
  useEffect(() => {
    // Skip the initial render
    if (webampRef.current) {
      // Reinitialize with new tracks
      initializeWebamp();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracks]); // Reinitialize when tracks change

  return <div ref={containerRef} className={`webamp-container ${className || ''}`} />;
};

export default WebampPlayer; 