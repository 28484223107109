import React, { useState } from 'react';
import { discography } from '../data/discography';

// Define the structure for a music album/playlist
export interface MusicAlbum {
  id: string;
  title: string;
  artist: string;
  year: string;
  coverUrl?: string;
  thumbUrl?: string; // URL for thumbnail image (96x96)
  largeUrl?: string; // URL for large image (512x512)
  fullscreenUrl?: string; // URL for fullscreen optimized image
  tracks: MusicTrack[];
}

// Define the structure for a music track
export interface MusicTrack {
  url: string;
  duration: number;
  metaData: {
    artist: string;
    title: string;
    album?: string;
  };
}

// Using the generated discography data
export { discography };

// Functional Component for the Discography view
interface MusicLibraryProps {
  onAlbumClick: (album: MusicAlbum) => void;
}

const MusicLibrary: React.FC<MusicLibraryProps> = ({ onAlbumClick }) => {
  // Update to add 'square' view - make it the default view
  const [viewMode, setViewMode] = useState<'square' | 'grid' | 'list'>('square');
  const [selectedAlbum, setSelectedAlbum] = useState<MusicAlbum | null>(null);
  
  const handleAlbumClick = (album: MusicAlbum) => {
    setSelectedAlbum(album);
    onAlbumClick(album);
  };
  
  const handleAlbumDoubleClick = (album: MusicAlbum) => {
    onAlbumClick(album);
  };
  
  const toggleViewMode = () => {
    // Cycle through the view modes: square -> grid -> list -> square
    setViewMode(prev => {
      if (prev === 'square') return 'grid';
      if (prev === 'grid') return 'list';
      return 'square';
    });
  };
  
  // Get the appropriate button text based on current view mode
  const getViewButtonText = () => {
    if (viewMode === 'square') return 'Grid View';
    if (viewMode === 'grid') return 'List View';
    return 'Square View';
  };
  
  return (
    <div className="discography">
      <div className="folder-header">
        <div className="folder-actions">
          <button 
            onClick={toggleViewMode}
            className="kos-button kos-border"
          >
            {getViewButtonText()}
          </button>
        </div>
      </div>
      
      <div className="folder-content">
        <div className={`file-list ${viewMode}-view`}>
          {discography.map((album) => (
            <div 
              key={album.id} 
              className={`file-item ${selectedAlbum?.id === album.id ? 'selected' : ''}`}
              onClick={() => handleAlbumClick(album)}
              onDoubleClick={() => handleAlbumDoubleClick(album)}
              title={`${album.title} (${album.artist}, ${album.year})`}
            >
              {/* Square view only shows album art */}
              {viewMode === 'square' ? (
                <div className="square-album">
                  {(album.thumbUrl || album.coverUrl) ? (
                    <img 
                      src={process.env.PUBLIC_URL + (album.thumbUrl || album.coverUrl)} 
                      alt={album.title} 
                      className="square-album-cover" 
                      onError={(e) => {
                        console.error(`Failed to load image: ${album.thumbUrl || album.coverUrl}`);
                        const target = e.target as HTMLImageElement;
                        target.style.display = 'none';
                        const parent = target.parentElement;
                        if (parent) {
                          const fallback = document.createElement('div');
                          fallback.className = 'square-album-fallback';
                          fallback.textContent = '♫';
                          parent.appendChild(fallback);
                        }
                      }}
                    />
                  ) : (
                    <div className="square-album-fallback">♫</div>
                  )}
                  <div className="square-album-info">
                    <div className="square-album-title">{album.title}</div>
                    <div className="square-album-tracks">{album.tracks.length} Tracks</div>
                  </div>
                </div>
              ) : (
                // Grid and List views show icon + details
                <>
                  <div className="file-icon">
                    {(album.thumbUrl || album.coverUrl) ? (
                      <img 
                        src={process.env.PUBLIC_URL + (album.thumbUrl || album.coverUrl)} 
                        alt={album.title} 
                        className="album-cover" 
                        onError={(e) => {
                          console.error(`Failed to load image: ${album.thumbUrl || album.coverUrl}`);
                          const target = e.target as HTMLImageElement;
                          target.style.display = 'none';
                          const parent = target.parentElement;
                          if (parent) {
                            const fallback = document.createElement('div');
                            fallback.className = 'file-icon-text';
                            fallback.textContent = '♫';
                            parent.appendChild(fallback);
                          }
                        }}
                      />
                    ) : (
                      <div className="file-icon-text">♫</div>
                    )}
                  </div>
                  <div className="file-details">
                    <div className="file-name">{album.title}</div>
                    <div className="file-meta">
                      {album.artist} • {album.year} • {album.tracks.length} Tracks
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      
      <div className="folder-status-bar">
        {discography.length} album{discography.length !== 1 ? 's' : ''} • {
          discography.reduce((sum, album) => sum + album.tracks.length, 0)
        } total tracks
      </div>
    </div>
  );
};

export default MusicLibrary; 