import React from 'react';
import KoolSkullOS from './KoolSkullOS';

function App() {
  return (
    <div className="App">
      <KoolSkullOS />
    </div>
  );
}

export default App;
